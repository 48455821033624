import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import StyledPage from '../components/StyledPage';
import LoadingPageIndicator from '../components/LoadingPageIndicator';
import RedirectWithPrevState from '../components/RedirectWithPrevState';
import withUser from '../HOCs/WithUser';
import { useQuery } from '@apollo/client';
import PageLoadError from '../components/PageLoadError';
import HeaderArea from '../components/HeaderArea';
import WebPlayerWrapper from '../components/WebPlayerWrapper';
import { SimulationOverview } from '../apps/core/src/components/SimulationOverview';
import {
  Card,
  CardTitle,
} from '../apps/core/src/components/SimulationOverview/SimulationOverview.styled';
import UnitCard from '../components/UnitCard';
import { useRouterQuery } from '../apps/core/src/helpers/useRouterQuery';
import { useLocation, useParams } from 'react-router-dom';
import useStateUrl from '../apps/core/src/helpers/useStateUrl';
import { useDeepLink } from '../apps/core/src/components/DeepLink/hooks/useDeepLink';
import {
  READ_STUDENT_SIMULATION,
  READ_UNIT_MODULE,
} from '../queries/LearnerQueries';
import { useCurrentOrganisation } from '../apps/core/src/helpers/useCurrentOrganisation';
import { getResizeImage } from '../apps/core/src/helpers/imageResizing/getResizeImage';

const StyledPageContentInner = styled.div`
  padding: 1.5rem var(--content-side-padding);
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: stretch;
`;

const OverviewContainer = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
`;

const StyledHeaderContent = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, minmax(70px, 1fr));
  > div {
    display: flex;
    flex-direction: column;
  }
`;

const StyledDataHeader = styled.div`
  color: #c3c3c3;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 500;
  margin-bottom: 0.7rem;
`;

const StyledDataItem = styled.div`
  font-weight: 500;
`;

const UnitsNav = styled.div`
  flex: 1;
  margin-left: var(--content-side-padding);
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledUnitCard = styled(UnitCard)`
  margin: 0;
`;

const MyCourseUnitSimulationPage = (props) => {
  const [showOverview, setShowOverview] = useState(true);
  const { courseID, simID } = useParams();
  const { pathname } = useLocation();

  const { value: unitID } = useStateUrl({ key: 'unitID' });

  const { loading, error, data } = useQuery(READ_STUDENT_SIMULATION, {
    fetchPolicy: 'cache-and-network',
    variables: {
      ID: simID,
    },
  });

  useCurrentOrganisation(data?.readOneSimulation?.OrganisationID);

  const thumbnailUrl = useMemo(() => {
    const simulation = data?.readOneSimulation;
    if (!simulation) {
      return null;
    }
    if (simulation.ImageMedia?.TusID) {
      return getResizeImage(simulation.ImageMedia.TusID);
    }
    if (simulation.Avatar?.ImageMedia?.TusID) {
      return getResizeImage(simulation.Avatar.ImageMedia.TusID);
    }
    return (
      simulation.ThumbnailURL ||
      simulation.ImageMedia?.URL ||
      simulation.FirstVideo?.nodes?.[0]?.PosterURL
    );
  }, [data]);

  const query = useMemo(() => {
    const search = new URLSearchParams({
      unitID,
      simID,
      courseID,
      content: 'true',
    });

    return search.toString();
  }, [simID, courseID, unitID]);

  useDeepLink(`${pathname}?${query}`);

  const {
    data: dataUnit,
    loading: loadingUnit,
    error: errorUnit,
  } = useQuery(READ_UNIT_MODULE, {
    skip: !unitID,
    variables: { ID: unitID },
  });

  const completedOverview = useCallback(
    () => setShowOverview(false),
    [setShowOverview]
  );

  const videosLength = useMemo(() => {
    if (data?.readOneSimulation?.TotalLength > 0) {
      return Math.ceil(data.readOneSimulation.TotalLength / 60);
    } else {
      return '0';
    }
  }, [data?.readOneSimulation]);

  if (!props.userID) {
    return <RedirectWithPrevState to="/login" />;
  }

  if (loading || loadingUnit) {
    return <LoadingPageIndicator />;
  }
  if (error || errorUnit) {
    return <PageLoadError graphQLErrorObj={error || errorUnit} />;
  }
  if (!data?.readOneSimulation) {
    return <PageLoadError message="Error retrieving data" />;
  }

  const units = _.get(dataUnit, 'readOneUnit.Module.Units.edges', []).map(
    ({ node }) => node
  );

  const unitIndex = units.findIndex(({ ID }) => String(ID) === String(unitID));

  const prevUnit = units[unitIndex - 1];
  const nextUnit = units[unitIndex + 1];

  return (
    <StyledPage
      stretchFullHeight={true}
      pageKey={'my-course-unit-page'}
      hasSaveBar={false}
      goBackToLinkText="my courses"
      goBackToLink={`/my-courses/${courseID}`}
      renderBackBar={true}
    >
      <HeaderArea
        imageSrc={thumbnailUrl}
        title={data.readOneSimulation.Title}
        type="simulation"
      >
        <StyledHeaderContent>
          <div>
            <StyledDataHeader>Length</StyledDataHeader>
            <StyledDataItem>
              {videosLength}{' '}
              <span style={{ fontSize: '0.7rem', color: '#c3c3c3' }}>mins</span>
            </StyledDataItem>
          </div>
          <div>
            <StyledDataHeader>Views</StyledDataHeader>
            <StyledDataItem>{data.readOneSimulation.Views}</StyledDataItem>
          </div>
          <div>
            <StyledDataHeader>Likes</StyledDataHeader>
            <StyledDataItem>
              {data.readOneSimulation.Likes ? data.readOneSimulation.Likes : 0}
            </StyledDataItem>
          </div>
        </StyledHeaderContent>
      </HeaderArea>
      <StyledPageContentInner>
        <OverviewContainer showOverview={showOverview ? '1' : undefined}>
          {showOverview ? (
            <SimulationOverview
              simulation={data.readOneSimulation}
              onComplete={completedOverview}
            />
          ) : (
            <WebPlayerWrapper simulationID={simID} unitID={unitID} />
          )}
        </OverviewContainer>
        {showOverview && (prevUnit || nextUnit) && (
          <UnitsNav>
            {prevUnit && (
              <>
                <CardTitle>Previous Content</CardTitle>
                <StyledCard>
                  <StyledUnitCard unit={prevUnit} courseID={courseID} />
                </StyledCard>
              </>
            )}
            {nextUnit && (
              <>
                <CardTitle>Next Content</CardTitle>
                <StyledCard>
                  <StyledUnitCard
                    locked={dataUnit.readOneUnit.isCompleted !== true}
                    unit={nextUnit}
                    courseID={courseID}
                  />
                </StyledCard>
              </>
            )}
          </UnitsNav>
        )}
      </StyledPageContentInner>
    </StyledPage>
  );
};

MyCourseUnitSimulationPage.propTypes = {
  userID: PropTypes.number,
  match: PropTypes.object.isRequired,
};

export default withUser(MyCourseUnitSimulationPage);
