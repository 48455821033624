import { useMutation, useQuery, gql } from '@apollo/client';
import { useCallback, useState, useMemo } from 'react';
import {
  DELETE_MODULES,
  READ_MODULES,
  SORT_MODULES,
  SORT_UNITS,
} from './moduleList.query';
import CreateModuleForm from '../CreateModuleForm/CreateModuleForm';
import LoadingModule from '../Loading/LoadingModule';
import Module from '../Module/Module';
import getUnitOrder from './helpers/getUnitOrder';
import { ListContainer } from './ModuleList.styled';
import { produce } from 'immer';
import useFlashMessage from '../../../FlashMessage';
import { EmptyListBox } from '../Module/Module.styled';

export const ModuleList = ({ courseId }) => {
  const [sortUnits] = useMutation(SORT_UNITS);
  const [sortModules] = useMutation(SORT_MODULES);
  const [deleteModules] = useMutation(DELETE_MODULES);

  const { addFlashMessage } = useFlashMessage();
  const [draggingModule, setDraggingModule] = useState(false);

  const variables = useMemo(
    () => ({
      ID: courseId,
    }),
    [courseId]
  );

  const { data, loading } = useQuery(READ_MODULES, {
    variables,
  });

  const modules = useMemo(() => {
    const modules = data?.readOneCourse?.Modules.nodes;
    return !modules || loading
      ? [...Array(3)]
      : data.readOneCourse.Modules.nodes;
  }, [data, loading]);

  const handleSortUnits = useCallback(
    async (dragUnit, dropUnit) => {
      const newUnitIds = dropUnit.units
        .map(({ ID }) => ID)
        .filter((ID) => ID !== dragUnit.data.ID);

      newUnitIds.splice(dropUnit.index, 0, dragUnit.data.ID);

      const sortOrderModuleUnits = {
        ID: dropUnit.moduleId,
        __typename: 'Module',
        Course: {
          ID: courseId,
          __typename: 'Course',
          Modules: {
            nodes: modules.map(({ ID, Units, __typename }) => ({
              ID,
              __typename,
              Units: {
                edges: getUnitOrder(Units.edges, ID, dragUnit, dropUnit),
                __typename: 'UnitsConnection',
              },
            })),
          },
        },
      };

      await sortUnits({
        variables: {
          UnitIDs: newUnitIds.map(Number),
          ModuleID: Number(dropUnit.moduleId),
        },
        optimisticResponse: {
          sortOrderModuleUnits,
        },
      });
    },
    [sortUnits, modules, courseId]
  );

  const handleSortModules = useCallback(
    async (dragModule, dropModule) => {
      const newModuleIds = modules
        .map(({ ID }) => ID)
        .filter((ID) => ID !== dragModule.data.ID);

      newModuleIds.splice(dropModule.index, 0, dragModule.data.ID);

      const sortOrderCourseModules = {
        ID: courseId,
        __typename: 'Course',
        Modules: {
          nodes: newModuleIds.map((ID, index) => ({
            ID,
            SortOrder: index + 1,
            __typename: 'Module',
          })),
        },
      };

      await sortModules({
        variables: {
          ModuleIDs: newModuleIds.map(Number),
          CourseID: courseId,
        },
        optimisticResponse: {
          sortOrderCourseModules,
        },
      });
    },
    [courseId, modules, sortModules]
  );

  const handleDeleteModule = useCallback(
    async (moduleId, title) => {
      await deleteModules({
        variables: {
          ids: [moduleId],
        },

        update: (cache, result) => {
          const data = cache.readQuery({
            query: READ_MODULES,
            variables,
          });
          const newData = produce(data, (state) => {
            state.readOneCourse.Modules.nodes =
              state.readOneCourse?.Modules?.nodes.filter(
                ({ ID }) => ID !== moduleId
              );
          });
          cache.writeQuery({
            query: READ_MODULES,
            variables,
            data: newData,
          });
        },
      });

      addFlashMessage(`Deleted module '${title}'.`);
    },
    [deleteModules, variables, addFlashMessage]
  );

  return (
    <>
      <CreateModuleForm courseData={data?.readOneCourse} loading={loading} />
      <ListContainer data-intercom-target="course-modules-list">
        {modules.map((module, index) =>
          !module || loading ? (
            <LoadingModule key={index} />
          ) : (
            <Module
              key={loading ? index : module.ID}
              data={module}
              index={index}
              loading={loading}
              onSortUnits={handleSortUnits}
              onSortModules={handleSortModules}
              onDelete={handleDeleteModule}
              draggingModule={draggingModule}
              setDraggingModule={setDraggingModule}
            />
          )
        )}
        {!modules.length && (
          <EmptyListBox>Create your first module for this course!</EmptyListBox>
        )}
      </ListContainer>
    </>
  );
};
