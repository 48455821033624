import { gql } from '@apollo/client';

export const REFRESH_TOKEN = gql`
  mutation refreshToken {
    refreshVirtiToken {
      Token
      PDToken
      Member {
        ID
        Email
        Name
        FirstName
        Surname
        UserType
        DeleteRequestID
        AvatarMedia {
          ID
          TusID
          URL
        }
        OnboardProgress
        IntercomHash
        SignupMethod
        Organisations(limit: 1000, sort: { Name: ASC, ID: ASC }) {
          nodes {
            ID
            Name
            Trial
            TrialExpiryDate
            LogoMedia {
              ID
              TusID
            }
            AccountID
            DisableLeaderboard
            VirtualHumanAPI
            CustomerStage
            Partner
            Archived
            Medical
          }
        }
      }
    }
  }
`;
