import { Tooltip } from '@virtidev/toolbox';
import styled from 'styled-components';

export const VHTypeIcon = styled.div``;

export const VHTypeTooltip = styled(Tooltip)`
  border-radius: 50%;
  background: rgba(220, 220, 220, 0.4);
  width: 3rem;
  height: 3rem;
  :hover {
    background: rgba(220, 220, 220, 0.8);
  }
`;
