import { useMemo } from 'react';

export const supportedLanguages = [
  {
    label: 'English',
    value: 'en-US',
  },
  {
    label: 'Chinese (Taiwan)',
    value: 'zh-TW',
  },
  {
    label: 'Chinese (China)',
    value: 'zh-CN',
  },
  {
    label: 'Czech',
    value: 'cs-CZ',
  },
  {
    label: 'Dutch',
    value: 'nl-NL',
  },
  {
    label: 'Spanish',
    value: 'es-ES',
  },
  {
    label: 'French',
    value: 'fr-FR',
  },
  {
    label: 'German',
    value: 'de-DE',
  },
  {
    label: 'Italian',
    value: 'it-IT',
  },
  {
    label: 'Japanese',
    value: 'ja-JP',
  },
  {
    label: 'Korean',
    value: 'ko-KR',
  },
  {
    label: 'Portuguese',
    value: 'pt-PT',
  },
  {
    label: 'Russian',
    value: 'ru-RU',
  },
  {
    label: 'Danish',
    value: 'da-DK',
  },
  {
    label: 'Finnish',
    value: 'fi-FI',
  },
  {
    label: 'Norwegian Bokmål',
    value: 'nb-NO',
  },
  {
    label: 'Polish',
    value: 'pl-PL',
  },
  {
    label: 'Swedish',
    value: 'sv-SE',
  },
  {
    label: 'Turkish',
    value: 'tr-TR',
  },
];

/** @type {Array<{ label: string, value: string }>} */
const featureLockedLanguages = [];

export const useLocaleOptions = function () {
  const newVHLanguagesFeature = true; // replace with a useFeature call again if we want to test new languages
  const options = useMemo(() => {
    return [
      ...supportedLanguages,
      ...(newVHLanguagesFeature ? featureLockedLanguages : []),
    ];
  }, [newVHLanguagesFeature]);
  return options;
};
