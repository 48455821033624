import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation, Query } from '@apollo/client/react/components';
import _ from 'lodash';
import Select from 'react-select';
import {
  ADD_MEMBER_TO_GROUP,
  READ_MEMBERS_SELECT,
} from '../queries/UserQueries';
import withFlashMessaging from '../HOCs/WithFlashMessaging';
import StyledLabel from '../styled-components/StyledLabel';
import LoadingIndicator from '../components/LoadingIndicator';
import withUser from '../HOCs/WithUser';

class AddUserToGroupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userSearch: '',
      addingAndRefreshing: false,
      userSearchDB: '',
    };
    this._updateDBSearch = _.debounce(this._updateDBSearch.bind(this), 500);
  }

  _updateDBSearch() {
    this.setState({ userSearchDB: this.state.userSearch });
  }

  render() {
    return (
      <div className={this.props.className}>
        <StyledLabel>Add User</StyledLabel>
        <Query
          query={READ_MEMBERS_SELECT}
          variables={{
            filter:
              this.state.userSearchDB !== ''
                ? this.state.userSearchDB
                : '****************',
            organisationID: this.props.userOrganisationID,
          }}
          fetchPolicy="network-only"
        >
          {({ loading, error, data }) => {
            return (
              <Mutation
                onCompleted={async () => {
                  await this.props.refetchGroupAndUsers();
                  this.props.addFlashMessage('Added user to group', 'success');
                  this.setState({ addingAndRefreshing: false });
                }}
                onError={() => {
                  this.props.addFlashMessage(
                    'Failed to add user to group',
                    'error'
                  );
                  this.setState({ addingAndRefreshing: false });
                }}
                mutation={ADD_MEMBER_TO_GROUP}
              >
                {(mutateAddMemberToGroup, { mutationData }) => {
                  if (this.state.addingAndRefreshing)
                    return <LoadingIndicator />;
                  let options =
                    data && data.readMembers
                      ? data.readMembers.edges.map((userEdge) => {
                          return {
                            value: userEdge.node.ID,
                            label: userEdge.node.Name,
                          };
                        })
                      : [];
                  // remove any member IDs that should be excluded
                  // options = options.filter(option => !this.props.excludeMemberIDs.includes(option.value))
                  return (
                    <Select
                      placeholder="Search by first name or last name..."
                      value={this.state.userSearch}
                      onInputChange={(userSearch) => {
                        this.setState({ userSearch });
                        this._updateDBSearch();
                      }}
                      onChange={(newMember) => {
                        this.setState({ addingAndRefreshing: true });
                        mutateAddMemberToGroup({
                          variables: {
                            GroupID: parseInt(this.props.groupID, 10),
                            MemberID: newMember.value,
                          },
                        });
                      }}
                      options={options}
                      noOptionsMessage={() =>
                        loading ? 'Loading...' : 'No users found'
                      }
                    />
                  );
                }}
              </Mutation>
            );
          }}
        </Query>
      </div>
    );
  }
}

AddUserToGroupForm.propTypes = {
  groupID: PropTypes.string.isRequired,
  refetchGroupAndUsers: PropTypes.func.isRequired,
};

export default withUser(withFlashMessaging(AddUserToGroupForm));
