import React, { useCallback, useState, ComponentType } from 'react';
import { setConfirmModalActiveVar } from '../ApolloReactiveVars';
import ConfirmationModal from '../components/modals/ConfirmationModal';

/**
 * @typedef {import('./WithConfirmationBox.types').ConfirmCallback} ConfirmCallback
 * @typedef {import('./WithConfirmationBox.types').ConfirmState} ConfirmState
 */

/**
 * @type {(WrappedComponent: ComponentType<any>) => ComponentType<any>}
 */
const WithConfirmationBox = (WrappedComponent) => {
  const ConfirmationBox = React.forwardRef((props, ref) => {
    const [state, setState] = useState(
      /** @type {ConfirmState} */ ({
        showingConfirm: false,
        confirmCallback: () => {},
        confirmTitle: null,
        confirmMessage: '',
        loading: false,
        buttonType: null,
        keepOpenOnConfirm: false,
      })
    );

    /**
     * @type {ConfirmCallback}
     */
    const showConfirm = useCallback(
      (
        confirmCallback,
        confirmTitle = null,
        confirmMessage = '',
        buttonType = null,
        verificationTextRequired = null,
        keepOpenOnConfirm
      ) => {
        setConfirmModalActiveVar(true);
        setState({
          showingConfirm: true,
          loading: false,
          confirmCallback,
          confirmTitle,
          confirmMessage,
          buttonType,
          verificationTextRequired,
          keepOpenOnConfirm,
        });
      },
      [setState]
    );

    const hideConfirm = useCallback(() => {
      setConfirmModalActiveVar(false);
      setState((state) => ({
        ...state,
        showingConfirm: false,
        loading: false,
      }));
    }, [setState]);

    const setLoading = useCallback(
      (loading) => {
        setState((state) => {
          return { ...state, loading };
        });
      },
      [setState]
    );

    const confirmAction = useCallback(() => {
      if (!state.keepOpenOnConfirm) {
        hideConfirm();
      }
      state.confirmCallback();
    }, [hideConfirm, state]);

    return (
      <>
        {(state.showingConfirm || state.loading) && (
          <ConfirmationModal
            customTitle={state.confirmTitle}
            loading={state.loading}
            onCancel={hideConfirm}
            onConfirm={confirmAction}
            verificationTextRequired={state.verificationTextRequired}
          >
            <div
              dangerouslySetInnerHTML={{ __html: state.confirmMessage || '' }}
            />
          </ConfirmationModal>
        )}
        <WrappedComponent
          {...props}
          ref={ref}
          closeConfirm={hideConfirm}
          setConfirmAsLoading={setLoading} // sets confirm button to show loading state
          confirm={showConfirm}
        />
      </>
    );
  });

  return ConfirmationBox;
};

export default WithConfirmationBox;
