import { useMutation } from '@apollo/client';
import { TextInput } from '@virtidev/toolbox';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import WithOnboardingHandler from '../../../../../../../HOCs/WithOnboardingHandler';
import useFlashMessage from '../../../FlashMessage';
import Button from '../../../form/Button';
import { FieldError } from '../../../form/FieldError';
import Label from '../../../form/Label';
import { CREATE_MODULE } from './createModuleForm.query';
import { ModuleForm } from './CreateModuleForm.styled';
import tracker from '../../../../helpers/tracker';

const CreateModuleForm = ({ courseData, loading, updateProgress }) => {
  const formMethods = useForm({
    defaultValues: {
      Title: '',
    },
  });
  const { addFlashMessage } = useFlashMessage();
  const { register, reset, handleSubmit: submit } = formMethods;

  const [createModule, { loading: creating }] = useMutation(CREATE_MODULE);

  const handleSubmit = useMemo(
    () =>
      submit(async (Input) => {
        reset({ Title: '' });

        const ID = `new-module-${(Math.random() * 100).toFixed(0)}`;
        const SortOrder =
          Math.max(
            ...courseData.Modules.nodes.map(({ SortOrder }) => SortOrder),
            courseData.Modules.nodes.length
          ) + 1;

        const optimisticResponse = {
          createModule: {
            Description: '',
            ...Input,
            ID,
            SortOrder,
            Units: {
              edges: [],
              __typename: 'UnitsConnection',
            },
            Course: {
              ...courseData,
              Modules: {
                nodes: [
                  ...courseData.Modules.nodes,
                  {
                    ID,
                    __typename: 'Module',
                  },
                ],
              },
              __typename: 'Course',
            },
            __typename: 'Module',
          },
        };

        const result = await createModule({
          variables: {
            Input: {
              ...Input,
              SortOrder,
              CourseID: courseData.ID,
            },
          },
          optimisticResponse,
        });

        addFlashMessage(`Created module '${result.data.createModule.Title}'.`);
        updateProgress('add_course_module');
        tracker.track('module_created', {
          course_id: courseData.ID,
          module_id: result.data.createModule.ID,
        });
      }),
    [submit, reset, createModule, addFlashMessage, courseData, updateProgress]
  );

  const disabled = useMemo(() => loading || !courseData, [loading, courseData]);

  return (
    <FormProvider {...formMethods}>
      <ModuleForm
        onSubmit={handleSubmit}
        data-intercom-target="course-add-module-wrapper"
      >
        <Label>Create new module:</Label>
        <TextInput
          {...register('Title', { required: true })}
          disabled={disabled}
          placeholder="New module name..."
          suffix={
            <Button
              data-intercom-target="course-add-module-button"
              color="turquoise"
              type="submit"
              size="small"
              disabled={disabled || creating}
              loading={creating}
            >
              Save
            </Button>
          }
        />
        <FieldError name="Title" />
      </ModuleForm>
    </FormProvider>
  );
};

export default WithOnboardingHandler(CreateModuleForm);
