import { useQuery } from '@apollo/client';
import React, { useEffect, useMemo } from 'react';
import useStateUrl from '../../helpers/useStateUrl';
import useUser from '../../helpers/useUser';
import {
  Content,
  ContentBody,
  SimulationsContainer,
  EmptySimulationsCard,
  PagesContainer,
} from './SimulationsList.styled';
import PaginationControl from '../PaginationControl';
import { usePaginationUrl } from '../PaginationControl/helpers/usePaginationUrl';
import ActionList from './components/ActionList/ActionList';
import SimulationDataCard from './components/SimulationDataCard/SimulationDataCard';
import { READ_SIMULATIONS } from './SimulationsList.query';
import LoadingCourseCard from '../DataListCard/Loading/LoadingCourseCard';
import { usePageScroll } from '../Page';
import { HelpContent, HelpLabel, HelpTooltip } from '../Tooltip/HelpTooltip';
import { ActionBar } from '../form/ActionBar/ActionBar.styled';
import useSortUrl from '../../helpers/useSortUrl';
import { createdTitle } from '../../helpers/sortCollections';
import PageLoadError from '../../../../../components/PageLoadError';

export const SimulationsList = (props) => {
  const { OrganisationID } = useUser();
  const { scrollTop } = usePageScroll();

  const { UserType, ID } = useUser();

  const { page, pageSize, controlProps } = usePaginationUrl({
    onChange: scrollTop,
    pageSize: props.pageSize || 12,
  });
  const { value: title } = useStateUrl({});
  const { value: group } = useStateUrl({
    key: 'group',
  });

  const { value: status } = useStateUrl({
    key: 'status',
  });

  const { value: assignment } = useStateUrl({
    key: 'assignment',
  });

  const { value: tags } = useStateUrl({
    key: 'tag',
  });

  const { sortField, sortDirection } = useSortUrl(createdTitle);

  const variables = useMemo(() => {
    const base = {
      limit: pageSize,
      offset: (page - 1) * pageSize,
      sort: {
        [sortField]: sortDirection,
      },
    };

    /** @type {object} */
    let fieldFilters = {
      Organisation: { ID: { eq: OrganisationID } },
    };

    if (tags) {
      fieldFilters = {
        ...fieldFilters,
        Tags: {
          ID: { in: tags.split(',').map((id) => (id === '0' ? null : id)) },
        },
      };
    }

    if (UserType === 'contentcreator') {
      if (assignment === 'assigned') {
        return {
          ...base,
          filters: { ...fieldFilters, Editors: { ID: { eq: ID } } },
        };
      }
      if (assignment === 'created') {
        return {
          ...base,
          filters: { ...fieldFilters, Author: { ID: { eq: ID } } },
        };
      }
      return {
        ...base,
        filters: { ...fieldFilters, EditorsOrAuthorID: { eq: ID } },
      };
    }

    return {
      ...base,
      filters: {
        ...fieldFilters,
        Status: status ? { eq: status } : undefined,
        OrganisationGroups: group
          ? { ID: { eq: group === '0' ? null : group } }
          : undefined,
        TitleOrAdminTitle: title ? { contains: title } : undefined,
      },
    };
  }, [
    page,
    pageSize,
    title,
    OrganisationID,
    group,
    status,
    UserType,
    ID,
    assignment,
    sortField,
    sortDirection,
    tags,
  ]);

  const { data, error, loading } = useQuery(READ_SIMULATIONS, {
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const simulations = useMemo(() => {
    let simData = data?.readSimulations?.edges;

    if (!simData) {
      return [...Array(pageSize)];
    }
    return simData.map(({ node }) => node);
  }, [data, pageSize]);

  const filterExists = useMemo(
    () => Boolean(title || group || status || tags),
    [title, group, status, tags]
  );

  return (
    <Content>
      {!props.hideActions && (
        <>
          <ActionBar>
            <ActionList loading={loading} />
            <HelpTooltip placement="bottom-end">
              <HelpContent>
                Some tips about the data shown for each Interactive Video.
              </HelpContent>
              <HelpLabel>Users</HelpLabel>
              <HelpContent>
                Number of learners who have completed a session.
              </HelpContent>
              <HelpLabel>Attempts</HelpLabel>
              <HelpContent>
                Number of times a session was started, includes incomplete
                sessions.
              </HelpContent>
              <HelpLabel>Completed</HelpLabel>
              <HelpContent>
                Number of times a session was completed, includes multiple
                attempts from the same learner.
              </HelpContent>
              <HelpLabel>Avg. Score</HelpLabel>
              <HelpContent>
                The average score across all completed sessions, includes
                multiple attempts from the same learner.
              </HelpContent>
            </HelpTooltip>
          </ActionBar>
        </>
      )}
      {!loading && !error && !simulations.filter(Boolean).length && (
        <EmptySimulationsCard>
          {filterExists
            ? `No simulations found – try adjusting your search!`
            : 'Create your first simulation!'}
        </EmptySimulationsCard>
      )}
      {error && <PageLoadError graphQLErrorObj={error} />}
      <ContentBody>
        <SimulationsContainer>
          {simulations
            .filter(Boolean)
            .map((simulation, index) =>
              loading ? (
                <LoadingCourseCard key={index + 1} />
              ) : (
                <SimulationDataCard
                  key={simulation.ID}
                  simulation={simulation}
                  editTags
                />
              )
            )}
        </SimulationsContainer>
      </ContentBody>
      <PagesContainer>
        <PaginationControl
          {...controlProps}
          total={data?.readSimulations?.pageInfo?.totalCount || 0}
        />
      </PagesContainer>
    </Content>
  );
};

export default SimulationsList;
