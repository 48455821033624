import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  FC,
} from 'react';
import { useMutation, useQuery, NetworkStatus } from '@apollo/client';
import styled, { css } from 'styled-components';
import { withApollo } from '@apollo/client/react/hoc';

import {
  READ_VIDEOS_WITH_ASSOCIATIONS,
  ADD_VIDEO_FROM_URL,
} from '@base/queries/AssetQueries';
import withUser from '@base/HOCs/WithUser';
import RedirectWithPrevState from '@base/components/RedirectWithPrevState';
import PageLoadError from '@base/components/PageLoadError';
import UploadTUSHandler from '@base/components/upload/UploadTUSHandler';
import FilesListDetailed from '@base/components/FilesListDetailed';
import MediaTabNav from '@base/components/MediaTabNav';
import VideoTypeSelector from '@base/components/render-props/VideoTypeSelector';
import WithOnboardingHandler from '@base/HOCs/WithOnboardingHandler';
import getConfig from '@base/lib/config';
import {
  ActionBar,
  FieldWrapper,
} from '@core/components/form/ActionBar/ActionBar.styled';
import { TextInput } from '@virtidev/toolbox';
import useSortUrl from '@core/helpers/useSortUrl';
import { StateUrlSelect } from '@core/components/form/Select';
import { createdTitle as sortOptions } from '@core/helpers/sortCollections';
import { usePaginationUrl } from '@core/components/PaginationControl/helpers/usePaginationUrl';
import PaginationControl from '@core/components/PaginationControl';
import { useDebouncedSave } from '@core/components/form/hooks/useDebouncedSave';
import useStateUrl from '@core/helpers/useStateUrl';
import { withMediaAccess } from '@core/helpers/permissions';
import _ from 'lodash';
import tracker from '@core/helpers/tracker';
import useFlashMessage from '@core/components/FlashMessage';
import { ContentWrapper } from '@core/templates/components/StandardPage/Page.styled';
import { Titlebar } from '@core/components/Page';
import { withPageTemplate } from '@core/templates';
import useFeature from '@core/components/LaunchDarkly';
import { StateUrlSelectTag } from '@core/components/form/Select/components/SelectTag/SelectTag';

/**
 * @typedef {import('@core/models/video-media.types').VideoMedia} VideoMedia
 */

const PageContent = styled.div`
  ${({ theme: { spacing } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${spacing.md};
    margin: ${spacing.lg} 0;
  `}
`;

const StyledUploadTUSHandler = styled(UploadTUSHandler)`
  flex: initial;
  > div {
    flex: initial;
  }
`;

/**
 * @param {VideoMedia[]} videos
 */
const _hasTranscodingVideos = (videos) => {
  return videos.find((video) => video.TranscodingStatus === 'Transcoding');
};

const StyledUploaderWrapper = styled.div``;

const pageSize = 20;

/**
 * @type {FC<{} & {
 *    userOrganisationID: string,
 *    userID: string,
 *    handleTourStart: (id: string, name: string) => void,
 *    updateProgress: (name: string) => void,
 * }>}
 */
const MediaPage = ({
  userOrganisationID,
  userID,
  handleTourStart,
  updateProgress,
}) => {
  const screenCaptureFeature = useFeature('screen-capture-tool');
  const { pageQuery, controlProps, resetPage } = usePaginationUrl({
    pageSize,
    onChange: () =>
      contentRef.current?.scrollIntoView?.({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      }),
  });
  const { addFlashMessage } = useFlashMessage();
  const [uploading, setUploading] = useState(false);
  const [content360, setContent360] = useState(true);
  const { sortField, sortDirection } = useSortUrl(sortOptions);
  const { value: filter, updateValue: setFilter } = useStateUrl({
    initialValue: '',
  });

  const { value: tags } = useStateUrl({
    key: 'tag',
  });

  const handleFilterChange = useCallback(
    (value) => {
      resetPage();
      setFilter(value || '');
    },
    [setFilter, resetPage]
  );

  const debouncedProps = useDebouncedSave(filter, {
    onUpdate: handleFilterChange,
  });

  const contentRef = useRef(/** @type {HTMLDivElement | null} */ (null));

  const videoQueryVariables = useMemo(() => {
    /**
     * @type {any}
     */
    const vars = {
      ...pageQuery,
      sort: {
        [sortField]: sortDirection,
      },
      filter: {
        ...(filter ? { Title: { contains: filter || '' } } : {}),
        Archived: { eq: false },
        Organisation: { ID: { eq: userOrganisationID } },
        ...(tags
          ? {
              Tags: {
                ID: {
                  in: tags.split(',').map((id) => (id === '0' ? null : id)),
                },
              },
            }
          : {}),
      },
    };

    return vars;
  }, [filter, pageQuery, userOrganisationID, sortDirection, sortField, tags]);

  const [mutateAddVideo, { loading: addingVideo }] = useMutation(
    ADD_VIDEO_FROM_URL,
    {
      refetchQueries: ['readMediaPage'],
    }
  );

  const { loading, error, data, networkStatus, startPolling, stopPolling } =
    useQuery(READ_VIDEOS_WITH_ASSOCIATIONS, {
      variables: videoQueryVariables,
      notifyOnNetworkStatusChange: true,
    });

  const videos = useMemo(() => data?.readVideoMedias.nodes || [], [data]);

  useEffect(() => {
    const hasTranscodingVideos = _hasTranscodingVideos(videos);
    if (
      hasTranscodingVideos &&
      networkStatus !== NetworkStatus.poll &&
      networkStatus !== NetworkStatus.refetch
    ) {
      startPolling(10000);
    } else if (!hasTranscodingVideos) {
      stopPolling();
    }
  }, [videos, networkStatus, startPolling, stopPolling]);

  useEffect(() => {
    handleTourStart(
      getConfig('REACT_APP_INTERCOM_MEDIA_TOUR'),

      'upload_video'
    );
  }, [handleTourStart]);

  if (!userID) {
    return <RedirectWithPrevState to="/login" />;
  }
  return (
    <ContentWrapper>
      <Titlebar>Media</Titlebar>
      {screenCaptureFeature && <MediaTabNav />}
      <VideoTypeSelector
        disabled={addingVideo || uploading}
        data-intercom-target="video-type-selector"
        selected={content360}
        onSelected={setContent360}
      />
      <StyledUploaderWrapper data-intercom-target="video-uploader">
        <StyledUploadTUSHandler
          card
          height={230}
          multiple={true}
          dragHereText={`Drag & Drop ${content360 ? '360' : '2D'} Videos Here`}
          name="asset-uploader"
          uploadIconWidth="4rem"
          uploadIconHeight="4rem"
          loaderSize="small"
          mediaType="video"
          onCancel={() => {
            setUploading(false);
          }}
          onSending={() => {
            setUploading(true);
          }}
          onSuccess={async (upload, fileID) => {
            const result = await mutateAddVideo({
              variables: {
                URL: upload.url,
                Filename: upload.file.name,
                TusID: fileID,
                OrganisationID: userOrganisationID,
                Content360: content360,
              },
            });
            const videoID = result?.data?.createVideoFromURL?.ID;
            if (videoID) {
              tracker.track('video_uploaded', {
                video_id: videoID,
              });
            }
          }}
          onComplete={async () => {
            updateProgress('upload_video');
            addFlashMessage('Upload successful', 'success');
            setUploading(false);
          }}
          needsOwnNavigationPrompt={true}
        />
      </StyledUploaderWrapper>
      {error && <PageLoadError graphQLErrorObj={error} />}
      <PageContent ref={contentRef}>
        <ActionBar>
          <FieldWrapper>
            <TextInput
              {...debouncedProps}
              filterStyle
              placeholder="Search..."
              id="filter"
            />
          </FieldWrapper>
          <FieldWrapper>
            <StateUrlSelectTag
              param="tag"
              multi
              onChange={resetPage}
              placeholder="Filter by tag..."
              options={[{ value: '0', label: '(No tags)' }]}
            />
          </FieldWrapper>
          <FieldWrapper>
            <StateUrlSelect
              param="sort"
              options={sortOptions}
              placeholder="Sort..."
              inlineLabel="Sort:"
              initialValue={sortOptions[0].value}
              isSearchable={false}
            />
          </FieldWrapper>
        </ActionBar>
        <FilesListDetailed
          files={videos}
          loading={loading && networkStatus !== NetworkStatus.poll}
        />
        {data?.readVideoMedias && (
          <PaginationControl
            {...controlProps}
            total={data?.readVideoMedias?.pageInfo?.totalCount}
          />
        )}
      </PageContent>
    </ContentWrapper>
  );
};

export default _.flowRight(
  withPageTemplate(),
  WithOnboardingHandler,
  withMediaAccess,
  withApollo,
  withUser
)(MediaPage);
