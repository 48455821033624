import styled, { css } from 'styled-components';
import Button from '../components/buttons/Button';

export const StyledAssets = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 23.5%);
  grid-column-gap: 2%;
  grid-row-gap: 1rem;
`;

export const StyledAssetSelectBtn = styled.button`
  background: none;
  border: 6px solid rgba(0, 0, 0, 0);
  cursor: pointer;
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.3;
    `}
  ${(props) =>
    props.selected &&
    css`
      border-color: var(--primary-color);
    `}
`;

export const StyledThumb = styled.img`
  width: 100%;
  border-radius: 6px;
  display: block;
  margin-bottom: 0.5rem;
  max-height: 126px;
  min-height: 126px;
  object-fit: contain;
  background-color: #eff1f3;
`;

export const StyledButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  height: 4rem;
  width: 100%;
  text-align: right;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  background: #00000021;
`;
export const StyledConfirmAssetButton = styled(Button)`
  margin-right: 1rem;
`;

export const StyledThumbWrapper = styled.div`
  > div {
    position: relative;
    &:hover button {
      visibility: visible;
      opacity: 1;
    }
  }
`;
export const StyledSelectButton = styled(Button)`
  display: block;
  position: absolute;
  bottom: 50%;
  left: 50%;
  top: 50%;
  width: 100%;
  border-radius: 6px;
  transform: translate(-50%, -50%);
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s all;
  z-index: 2;
`;
export const StyledTitle = styled.div`
  font-weight: 500;
  text-align: center;
  margin-bottom: 0.25rem;
  width: 100%;
  overflow: hidden;
`;

export const StyledSubTitle = styled.div`
  font-weight: normal;
  font-size: 0.7rem;
  text-align: center;
  margin-bottom: 0.25rem;
  color: grey;
`;
