import { ChatSidebarTabs } from '../../../slices/chat/ChatSlice';
import { fetchBranchingGreeting } from '../../../slices/chat/ChatThunks';
import VHDefaultConfig from './VHDefaultConfig';
import VHTypeNavigation from './VHTypeNavigation';
import VHTypes from './VHTypes';

const VHBranchingConfig = {
  ...VHDefaultConfig,
  navigationLocations: [
    VHTypeNavigation.DETAILS,
    VHTypeNavigation.OBJECTIVES,
    VHTypeNavigation.BRANCHING_BUILDER,
    VHTypeNavigation.PREVIEW,
    VHTypeNavigation.COMPLETIONS,
    VHTypeNavigation.ANALYTICS,
    VHTypeNavigation.OBJECTIVES_ANALYTICS,
    VHTypeNavigation.SESSIONS,
  ],
  type: VHTypes.BRANCHING,
  getGreetingThunk: () => fetchBranchingGreeting,
  chatSidebarTabs: {
    CHAT: ChatSidebarTabs.CHAT,
  },
  showHints: false,
};

export default VHBranchingConfig;
