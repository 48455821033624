import React from 'react';
import * as Styled from './LegacyNeutralIcon.styled';

export const MinusIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.689"
      height="2.286"
      viewBox="0 0 19.689 2.286"
    >
      <path
        fill="#FFF"
        d="M303.243,378.513h-17.4a1.143,1.143,0,1,1,0-2.286h17.4a1.143,1.143,0,1,1,0,2.286Z"
        transform="translate(-284.697 -376.227)"
      />
    </svg>
  );
};

const NeutralIcon = (props) => {
  return (
    <Styled.NeutralIcon disabled={props.disabled}>
      <MinusIcon />
    </Styled.NeutralIcon>
  );
};

export default NeutralIcon;
