import React from 'react';
import styled from 'styled-components';
import { Mutation, Query } from '@apollo/client/react/components';
import PropTypes from 'prop-types';

import AddUserToGroupForm from './AddUserToGroupForm';
import { REMOVE_MEMBER_FROM_GROUP } from '../queries/UserQueries';
import { READ_GROUP_WITH_PAGINATED_USERS } from '../queries/GroupQueries';
import withFlashMessaging from '../HOCs/WithFlashMessaging';
import Pagination from '../components/Pagination';
import LoadingIndicator from '../components/LoadingIndicator';
import WithConfirmationBox from '../HOCs/WithConfirmationBox';
import GroupUsersList from '../components/GroupUsersList';
import PageLoadError from '../components/PageLoadError';
import { TextInput } from '@virtidev/toolbox';
import FieldContainer from '../apps/core/src/components/form/FieldContainer';
import Label from '../apps/core/src/components/form/Label';

const StyledUsersWrapper = styled.div`
  padding: 1rem;
  border-radius: var(--card-border-radius);
  background-color: var(--card-bg-color);
  box-shadow: var(--card-box-shadow);
  margin-bottom: 1rem;
`;

const StyledAddUserToGroupForm = styled(AddUserToGroupForm)`
  margin-bottom: 1rem;
`;

const StyledGroupUsersList = styled(GroupUsersList)`
  margin-bottom: 1rem;
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin-top: 3rem;
`;

const usersPerPage = 15;

class UsersInGroupListSubForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      nameFilter: '',
      deletingAndRefetching: false,
      ...this._getPageState(1),
    };
  }

  _updatePage = (newPage) => {
    this.setState({ ...this._getPageState(newPage) });
  };

  _getPageState = (pageNum) => {
    return {
      page: pageNum,
      limit: usersPerPage,
    };
  };
  _removeUser = (userNode, mutateRemoveMemberFromGroup) => {
    this.props.confirm(
      () => {
        mutateRemoveMemberFromGroup({
          variables: {
            GroupID: this.props.groupID,
            MemberID: userNode.ID,
          },
        });
        this.setState({ deletingAndRefetching: true });
      },
      '',
      'Delete ' + userNode.Name + ' from this group?',
      'delete'
    );
  };
  _updateNameFilter = (e) => {
    this.setState({ nameFilter: e.target.value });
  };

  render() {
    return (
      <Mutation
        onCompleted={async () => {
          await this.refetchGroupAndUsers();
          this.props.addFlashMessage('Removed user from group', 'success');
          this.setState({ deletingAndRefetching: false });
        }}
        onError={() => {
          this.props.addFlashMessage(
            'Failed to remove user from group',
            'error'
          );
          this.setState({ deletingAndRefetching: false });
        }}
        mutation={REMOVE_MEMBER_FROM_GROUP}
      >
        {(mutateRemoveMemberFromGroup, { mutationData }) => {
          return (
            <Query
              query={READ_GROUP_WITH_PAGINATED_USERS}
              fetchPolicy="network-only"
              variables={{
                ID: this.props.data.readOneVirtiGroup.ID,
                nameFilter: this.state.nameFilter,
                limit: usersPerPage,
                offset: usersPerPage * this.state.page - usersPerPage,
              }}
            >
              {({
                data: paginatedMembersData,
                loading,
                error,
                refetch: refetchGroupAndUsers,
              }) => {
                this.refetchGroupAndUsers = refetchGroupAndUsers;
                const showLoading = this.state.deletingAndRefetching || loading;

                const pageInfo =
                  paginatedMembersData?.readOneVirtiGroup?.Users?.pageInfo;
                const userEdges =
                  paginatedMembersData?.readOneVirtiGroup?.Users?.edges;
                return (
                  <>
                    <StyledUsersWrapper>
                      <StyledAddUserToGroupForm
                        refetchGroupAndUsers={refetchGroupAndUsers}
                        groupID={this.props.groupID}
                      />
                    </StyledUsersWrapper>
                    <FieldContainer horizontal style={{ marginBottom: 0 }}>
                      <Label horizontal>Search users: </Label>
                      <TextInput
                        value={this.state.nameFilter}
                        onChange={this._updateNameFilter}
                      />
                    </FieldContainer>
                    {showLoading && <StyledLoadingIndicator />}
                    {!!error && <PageLoadError graphQLErrorObj={error} />}
                    {!showLoading && !error && (
                      <>
                        <StyledGroupUsersList
                          userEdges={userEdges}
                          removeUser={(userNode) =>
                            this._removeUser(
                              userNode,
                              mutateRemoveMemberFromGroup
                            )
                          }
                        />
                        <Pagination
                          changePageCallback={this._updatePage}
                          perPage={this.state.limit}
                          page={this.state.page}
                          totalCount={pageInfo?.totalCount}
                          pageInfo={{
                            hasNextPage: pageInfo?.hasNextPage,
                            hasPreviousPage: pageInfo?.hasPreviousPage,
                          }}
                          showPrevNext={false}
                        />
                      </>
                    )}
                  </>
                );
              }}
            </Query>
          );
        }}
      </Mutation>
    );
  }
}

UsersInGroupListSubForm.propTypes = {
  groupID: PropTypes.string.isRequired,
  addFlashMessage: PropTypes.func.isRequired,
  userOrganisationID: PropTypes.number.isRequired,
  refetchGroup: PropTypes.func.isRequired, // should replace with cache update at some point
  confirm: PropTypes.func.isRequired,
};

export default WithConfirmationBox(withFlashMessaging(UsersInGroupListSubForm));
