import _ from 'lodash';
import { sidebarContractedVar, userVar } from '../ApolloReactiveVars';

export const UserTypes = {
  USER: 0,
  CREATOR: 1,
  ADMIN: 2,
  SUPER: 3,
  CUSTOMER_ADMIN: 4,
};

export const UserTypeMeta = [
  {
    dbValue: 'user',
    userType: UserTypes.USER,
  },
  {
    dbValue: 'contentcreator',
    userType: UserTypes.CREATOR,
  },
  {
    dbValue: 'admin',
    userType: UserTypes.ADMIN,
  },
  {
    dbValue: 'super',
    userType: UserTypes.SUPER,
  },
  {
    dbValue: 'customeradmin',
    userType: UserTypes.CUSTOMER_ADMIN,
  },
];

const GetUserTypeFromStorage = () => {
  // return UserTypes.CREATOR;
  const storageUserType = userVar()?.UserType || null;
  return GetUserType(storageUserType);
};

const isTrial = () => {
  const organisation = userVar()?.Organisation || null;

  return organisation?.Trial;
};

export const GetUserType = (dbUserType) => {
  const meta = UserTypeMeta.find((meta) => meta.dbValue === dbUserType);
  if (meta) return meta.userType;
  return UserTypes.USER;
};
export const IsContentCreator = () => {
  return GetUserTypeFromStorage() === UserTypes.CREATOR;
};
export const ShowAdminOnboarding = () => {
  const userType = GetUserTypeFromStorage();
  return [UserTypes.ADMIN, UserTypes.SUPER, UserTypes.CUSTOMER_ADMIN].includes(
    userType
  );
};
export const CanViewMedia = () => {
  const userType = GetUserTypeFromStorage();
  return [
    UserTypes.ADMIN,
    UserTypes.SUPER,
    UserTypes.CUSTOMER_ADMIN,
    UserTypes.CREATOR,
  ].includes(userType);
};
export const CanViewUsersList = () => {
  const userType = GetUserTypeFromStorage();
  return [UserTypes.ADMIN, UserTypes.SUPER, UserTypes.CUSTOMER_ADMIN].includes(
    userType
  );
};
export const CanViewAddUsersButton = () => {
  const userType = GetUserTypeFromStorage();
  return [UserTypes.ADMIN, UserTypes.SUPER, UserTypes.CUSTOMER_ADMIN].includes(
    userType
  );
};
export const CanViewGroupsList = () => {
  const userType = GetUserTypeFromStorage();
  return [UserTypes.ADMIN, UserTypes.SUPER, UserTypes.CUSTOMER_ADMIN].includes(
    userType
  );
};
export const CanViewAnalyticsPage = () => {
  const userType = GetUserTypeFromStorage();
  return [UserTypes.ADMIN, UserTypes.SUPER, UserTypes.CUSTOMER_ADMIN].includes(
    userType
  );
};
export const CanViewSimulationsList = () => {
  const userType = GetUserTypeFromStorage();
  return [
    UserTypes.ADMIN,
    UserTypes.SUPER,
    UserTypes.CUSTOMER_ADMIN,
    UserTypes.CREATOR,
  ].includes(userType);
};
export const CanViewFormsList = () => {
  const userType = GetUserTypeFromStorage();
  return [
    UserTypes.ADMIN,
    UserTypes.SUPER,
    UserTypes.CUSTOMER_ADMIN,
    UserTypes.CREATOR,
  ].includes(userType);
};
export const CanViewCoursesList = () => {
  // return false;
  const userType = GetUserTypeFromStorage();
  return [UserTypes.ADMIN, UserTypes.SUPER, UserTypes.CUSTOMER_ADMIN].includes(
    userType
  );
};
export const CanPublishSimulations = () => {
  const userType = GetUserTypeFromStorage();
  return [UserTypes.ADMIN, UserTypes.SUPER, UserTypes.CUSTOMER_ADMIN].includes(
    userType
  );
};
export const CanReadyForPublishSimulations = () => {
  const userType = GetUserTypeFromStorage();
  return [
    UserTypes.ADMIN,
    UserTypes.SUPER,
    UserTypes.CUSTOMER_ADMIN,
    UserTypes.CREATOR,
  ].includes(userType);
};
export const CanPublishCourses = () => {
  const userType = GetUserTypeFromStorage();
  return [UserTypes.ADMIN, UserTypes.SUPER, UserTypes.CUSTOMER_ADMIN].includes(
    userType
  );
};
export const CanReadyForPublishCourses = () => {
  const userType = GetUserTypeFromStorage();
  return [
    UserTypes.ADMIN,
    UserTypes.SUPER,
    UserTypes.CUSTOMER_ADMIN,
    UserTypes.CREATOR,
  ].includes(userType);
};
export const CanViewDashboard = () => {
  const userType = GetUserTypeFromStorage();
  return [UserTypes.ADMIN, UserTypes.SUPER, UserTypes.CUSTOMER_ADMIN].includes(
    userType
  );
};
export const CanEditSimulation = (simulation, userID) => {
  const userType = GetUserTypeFromStorage();
  if (
    [UserTypes.ADMIN, UserTypes.SUPER, UserTypes.CUSTOMER_ADMIN].includes(
      userType
    )
  )
    return true;
  if (
    userType === UserTypes.CREATOR &&
    parseInt(simulation.Author.ID) === parseInt(userID)
  )
    return true;
  const editorIDs = simulation.Editors.edges.map(({ node }) => {
    return parseInt(node.ID);
  });
  if (userType === UserTypes.CREATOR && editorIDs.includes(parseInt(userID)))
    return true;
  return false;
};

export const CanEditCourse = (course, userID) => {
  const userType = GetUserTypeFromStorage();
  if (
    [UserTypes.ADMIN, UserTypes.SUPER, UserTypes.CUSTOMER_ADMIN].includes(
      userType
    )
  )
    return true;
  // if (userType === UserTypes.CREATOR && parseInt(course.Author.ID) === parseInt(userID)) return true;
  return false;
};
export const CanEditOrganisation = () => {
  const userType = GetUserTypeFromStorage();
  return [UserTypes.SUPER, UserTypes.CUSTOMER_ADMIN].includes(userType);
};
export const CanEditSimulationGroups = () => {
  const userType = GetUserTypeFromStorage();
  return [UserTypes.ADMIN, UserTypes.SUPER, UserTypes.CUSTOMER_ADMIN].includes(
    userType
  );
};
export const CanEditSimulationFeatured = () => {
  const userType = GetUserTypeFromStorage();
  return [UserTypes.ADMIN, UserTypes.SUPER, UserTypes.CUSTOMER_ADMIN].includes(
    userType
  );
};
export const CanEditSimulationHiddenInList = () => {
  const userType = GetUserTypeFromStorage();
  return [UserTypes.ADMIN, UserTypes.SUPER, UserTypes.CUSTOMER_ADMIN].includes(
    userType
  );
};
export const CanSeeUserTypeInput = (userBeingEditedUserType) => {
  if (
    userBeingEditedUserType === 'customeradmin' ||
    userBeingEditedUserType === 'super'
  )
    return false;
  const userType = GetUserTypeFromStorage();
  return [UserTypes.ADMIN, UserTypes.SUPER, UserTypes.CUSTOMER_ADMIN].includes(
    userType
  );
};
export const CanEditTypeInput = () => {
  const userType = GetUserTypeFromStorage();
  return [UserTypes.ADMIN, UserTypes.SUPER, UserTypes.CUSTOMER_ADMIN].includes(
    userType
  );
};
export const CanEditContentCreatorStatus = () => {
  const userType = GetUserTypeFromStorage();
  return [UserTypes.ADMIN, UserTypes.SUPER, UserTypes.CUSTOMER_ADMIN].includes(
    userType
  );
};
export const CanEditAdminStatus = () => {
  const userType = GetUserTypeFromStorage();
  return [UserTypes.SUPER, UserTypes.CUSTOMER_ADMIN].includes(userType);
};
export const CanEditSuperAdminStatus = () => {
  return false;
};

export const CanViewOnboarding = () => {
  const userType = GetUserTypeFromStorage();
  return [UserTypes.ADMIN, UserTypes.SUPER, UserTypes.CUSTOMER_ADMIN].includes(
    userType
  );
};

export const CanSeeCXDashboard = () => {
  const userType = GetUserTypeFromStorage();
  return [UserTypes.CUSTOMER_ADMIN].includes(userType);
};

export const CanEditVirtualHumanGroups = () => {
  const userType = GetUserTypeFromStorage();
  return [UserTypes.ADMIN, UserTypes.SUPER, UserTypes.CUSTOMER_ADMIN].includes(
    userType
  );
};
export const CanEditVirtualHumanFeatured = () => {
  const userType = GetUserTypeFromStorage();
  return [UserTypes.ADMIN, UserTypes.SUPER, UserTypes.CUSTOMER_ADMIN].includes(
    userType
  );
};
export const CanEditVirtualHumanHiddenInList = () => {
  const userType = GetUserTypeFromStorage();
  return [UserTypes.ADMIN, UserTypes.SUPER, UserTypes.CUSTOMER_ADMIN].includes(
    userType
  );
};
export const CanPublishVirtualHumans = () => {
  const userType = GetUserTypeFromStorage();
  return [UserTypes.ADMIN, UserTypes.SUPER, UserTypes.CUSTOMER_ADMIN].includes(
    userType
  );
};
export const CanReadyForPublishVirtualHumans = () => {
  const userType = GetUserTypeFromStorage();
  return [
    UserTypes.ADMIN,
    UserTypes.SUPER,
    UserTypes.CUSTOMER_ADMIN,
    UserTypes.CREATOR,
  ].includes(userType);
};

export const CanSeeUserAdminSwitch = () => {
  const userType = GetUserTypeFromStorage();
  return [
    UserTypes.ADMIN,
    UserTypes.SUPER,
    UserTypes.CUSTOMER_ADMIN,
    UserTypes.CREATOR,
  ].includes(userType);
};
export const CanSeeOnboardingVideo = () => {
  const userType = GetUserTypeFromStorage();
  return [
    UserTypes.ADMIN,
    UserTypes.SUPER,
    UserTypes.CUSTOMER_ADMIN,
    UserTypes.CREATOR,
  ].includes(userType);
};
