import styled, { css } from 'styled-components';

export const ReportWrapper = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export const ReportHeader = styled.h4`
  ${({ theme: { color, fontSize, fontWeight } }) => css`
    color: ${color.midnight5};
    font-size: ${fontSize.md};
    font-weight: ${fontWeight.bold};
  `};
`;
export const ReportBody = styled.div`
  ${({ theme: { color, fontSize } }) => css`
    color: ${color.midnight10};
    font-size: ${fontSize.md};
  `};
`;
export const ReportSection = styled.div``;
export const ReportAIPill = styled.div`
  ${({ theme: { color, fontSize, borderRadius } }) => css`
    margin-left: auto;
    border-radius: ${borderRadius.sm};
    background-color: ${color.turquoise20};
    border: 1px solid ${color.turquoise};
    font-size: ${fontSize.md};
    color: ${color.midnight};
    padding: 0.1rem 0.5rem;
  `};
`;
