import { Icon } from '@virtidev/toolbox';
import React, { useState } from 'react';
import {
  StyledCopyAlert,
  StyledLabel,
  StyledLink,
  StyledLinkGenerator,
  StyledLinkWrapper,
} from './LinkGenerator.styled';
import getConfig from '../../../../../../lib/config';


export default function LinkGenerator({ hash, formId }) {
  const [copyAlert, setCopyAlert] = useState(false);

  const link = `${getConfig("REACT_APP_FORM_TARGET_URI")}forms/${hash}/${formId}`;

  return (
    <StyledLinkGenerator data-intercom-target="forms-link-generator">
      <StyledLabel>Link</StyledLabel>
      <StyledLinkWrapper>
        <StyledLink target="_blank" rel="noopener noreferrer" href={link}>
          {link}
        </StyledLink>
        <StyledCopyAlert
          clicked={copyAlert}
          onClick={() => {
            setCopyAlert(true);
            navigator.clipboard.writeText(link);
            setTimeout(() => setCopyAlert(false), 2000);
          }}
        >
          {copyAlert ? 'Copied!' : <Icon icon="link" />}
        </StyledCopyAlert>
      </StyledLinkWrapper>
    </StyledLinkGenerator>
  );
}
