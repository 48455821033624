import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledAssets,
  StyledThumb,
  StyledThumbWrapper,
  StyledTitle,
  StyledSelectButton,
  StyledSubTitle,
} from '../../../styled-components/ModalSelectorComponents';
import NoSearchResults from '../../NoSearchResults';
import StyledContent360Indicator from '../../../styled-components/StyledContent360Indicator';
import { Datetime } from '@virtidev/toolbox';

const VideoAssetSelector = (props) => {
  return (
    <StyledAssets itemsPerColumn="4">
      {props.videoEdges.length === 0 && <NoSearchResults />}
      {props.videoEdges.map((videoEdge) => (
        <StyledThumbWrapper key={videoEdge.node.ID}>
          <div>
            <StyledThumb src={videoEdge.node.PosterURL} />
            <StyledSelectButton
              onClick={() => {
                props.onConfirm(videoEdge);
              }}
              loading={props.loading}
              disabled={
                props.loading ||
                props.disabledVideoIDs.includes(videoEdge.node.ID)
              }
            >
              {props.confirmText || 'Add Video'}
            </StyledSelectButton>
            <StyledContent360Indicator content360={videoEdge.node.Content360} />
          </div>
          <StyledTitle>{videoEdge.node.Title}</StyledTitle>
          <StyledSubTitle>
            <Datetime
              datetime={videoEdge.node.Created}
              long
              noTimeDisplay
              emptyDisplay="-"
            />
          </StyledSubTitle>
        </StyledThumbWrapper>
      ))}
    </StyledAssets>
  );
};

VideoAssetSelector.defaultProps = {
  disabledVideoIDs: [],
};

VideoAssetSelector.propTypes = {
  videoEdges: PropTypes.array.isRequired,
  onConfirm: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  confirmText: PropTypes.string,
  disabledVideoIDs: PropTypes.array,
};

export default VideoAssetSelector;
