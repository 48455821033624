import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';
import styled from 'styled-components';
import { ADD_SIMULATION_FROM_VIDEO_URL } from '../queries/SimulationQueries';
import WithFlashMessaging from '../HOCs/WithFlashMessaging';
import RedirectWithPrevState from '../components/RedirectWithPrevState';
import Button, { ButtonLink } from '../components/buttons/Button';
import UploadTUSHandler from '../components/upload/UploadTUSHandler';
import CreateSimulationFromVideoButton from '../components/buttons/CreateSimulationFromVideoButton';
import VideoTypeSelector from '../components/render-props/VideoTypeSelector';
import WithOnboardingHandler from '../HOCs/WithOnboardingHandler';
import getConfig from '../lib/config';
import tracker from '../apps/core/src/helpers/tracker';

const StyledForm = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const StyledButtonsWrapper = styled.div`
  margin-bottom: 1rem;
  > :first-child {
    margin-right: 1rem;
  }
`;

const StyledUploadArea = styled.div`
  padding: 2rem;
  box-shadow: var(--card-box-shadow);
  background-color: var(--card-bg-color);
  border-radius: var(--card-border-radius);
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledExistingVideoLink = styled(ButtonLink)`
  height: 100%;
`;

class SimulationNewForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createdSimulationID: null,
      uploaded: null,
      assetSelectorModalVisible: false,
      // selectedAssetID: null,
      uploading: false,
      isCreatingSim: false,
      content360: true,
    };
    // this.dropzoneRef = React.createRef();
    this.uploaderRef = React.createRef();
  }

  componentDidMount = () => {
    this.props.handleTourStart(
      getConfig('REACT_APP_INTERCOM_CREATE_SIMULATION_TOUR'),

      'create_simulation'
    );
  };

  _onCompletedCreation = (data) => {
    this.setState({
      createdSimulationID: data.createSimulationFromURL.ID,
    });
    this.props.updateProgress(
      'create_simulation',
      data.createSimulationFromURL.ID
    );
    tracker.track('simulation_created', {
      simulation_id: data.createSimulationFromURL.ID,
    });
  };

  _uploadBtnClick = () => {
    // this.dropzoneRef && this.dropzoneRef.current.dropzoneRef.clickableElements[0].click()

    if (
      this.uploaderRef &&
      this.uploaderRef.current &&
      this.uploaderRef.current._openFilePicker
    ) {
      this.uploaderRef.current._openFilePicker();
    }
  };

  _createSim = (mutateCreateSimulation, URL, Filename, TusID, Content360) => {
    this.setState({
      isCreatingSim: true,
    });
    mutateCreateSimulation({
      variables: {
        URL,
        Filename,
        TusID,
        organisationID: this.props.userOrganisationID,
        Content360,
      },
    });
  };

  render() {
    if (this.state.createdSimulationID) {
      return (
        <RedirectWithPrevState
          to={`/simulations/${this.state.createdSimulationID}`}
        />
      );
    }
    return (
      <Mutation
        mutation={ADD_SIMULATION_FROM_VIDEO_URL}
        update={(cache, data) => {
          cache.evict({ fieldName: 'readSimulations' });
          cache.evict({ fieldName: 'readVideoMedias' });
        }}
        onCompleted={(data) => {
          this._onCompletedCreation(data);
        }}
        onError={() => {
          this.props.addFlashMessage(
            'There was an error creating the simulation. Please wait and try again.',
            'error'
          );
          this.setState({ isCreatingSim: false });
        }}
      >
        {(mutateCreateSimulation, { loading: creatingSim }) => {
          this.mutateCreateSimulation = mutateCreateSimulation;
          return (
            <StyledForm onSubmit={(event) => event.preventDefault()}>
              <StyledButtonsWrapper>
                <Button
                  iconResizeHeight={false}
                  iconType="redesign"
                  icon="upload-cloud"
                  iconSize="small"
                  onClick={this._uploadBtnClick}
                  type="material-white"
                >
                  {this.state.content360
                    ? 'Upload new 360 Video'
                    : 'Upload new 2D Video'}
                </Button>
                <CreateSimulationFromVideoButton
                  className="new-simulation-from-video"
                  userOrganisationID={this.props.userOrganisationID}
                />
              </StyledButtonsWrapper>
              <StyledUploadArea data-intercom-target="new-simulation-upload-area">
                <VideoTypeSelector
                  data-intercom-target="new-simulation-2d-360-selector"
                  disabled={creatingSim || this.state.uploading}
                  selected={this.state.content360}
                  onSelected={(content360) => this.setState({ content360 })}
                >
                  <UploadTUSHandler
                    ref={this.uploaderRef}
                    mediaType="video"
                    multiple={false}
                    dragHereText={
                      this.state.content360
                        ? 'Upload new 360 Video'
                        : 'Upload new 2D Video'
                    }
                    name="new-simulation"
                    onCancel={() => this.setState({ uploading: false })}
                    onSending={() => this.setState({ uploading: true })}
                    onComplete={() => this.setState({ uploading: false })}
                    onSuccess={(upload, tusID) => {
                      this._createSim(
                        mutateCreateSimulation,
                        upload.url,
                        upload.options.metadata.filename,
                        tusID,
                        this.state.content360
                      );
                    }}
                    uploadIconWidth="8rem"
                    uploadIconHeight="8rem"
                    needsOwnNavigationPrompt={true}
                    data-intercom-target="new-simulation-uploader"
                  />
                </VideoTypeSelector>
              </StyledUploadArea>
            </StyledForm>
          );
        }}
      </Mutation>
    );
  }
}

SimulationNewForm.propTypes = {
  userOrganisationID: PropTypes.number.isRequired,
};

export default WithOnboardingHandler(WithFlashMessaging(SimulationNewForm));
