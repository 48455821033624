import styled, { css } from 'styled-components';

export const ScoreOverviewWrapper = styled.div``;
export const SummaryText = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 2.375rem;
`;
export const MainSummary = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  ${({ $vhType, $disableDiagnosis }) =>
    ($vhType !== 'medical' || $disableDiagnosis) &&
    css`
      grid-template-columns: 1fr 2fr;
    `}
  ${({ $pointsPossible }) =>
    !$pointsPossible &&
    css`
      grid-template-columns: 1fr;
    `}
  column-gap: 1rem;
  row-gap: 0.5rem;
  margin-bottom: 1rem;
  max-height: 90%;
  align-items: flex-start;
`;
export const TimeResult = styled.div``;
export const BoxResultIconText = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: left;
  align-items: center;
  column-gap: 0.75rem;
`;
export const BoxResult = styled.div`
  background-color: var(--pd-negative-color-lighter);
  color: var(--pd-negative-color);
  ${({ $success }) =>
    $success &&
    css`
      background-color: var(--pd-positive-color-lighter);
      color: var(--pd-positive-color);
    `}
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding: 1.25rem;
  row-gap: 0.7rem;
  border-radius: 16px;
  height: 100%;
  font-weight: bold;
  /* border: 1px solid var(--pd-secondary-color-light); */
`;

export const PillDataSection = styled.div`
  display: flex;
  column-gap: 0.5rem;
  justify-content: center;
  align-items: center;
`;

export const PointsText = styled.div`
  display: flex;
  align-items: center;
  padding-right: 4rem;
`;

export const FinishButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
