import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Modal from '../Modal';
import { usePrevious } from '../../utility/CustomHooks';
import { useLazyQuery } from '@apollo/client';
import { READ_VIDEO_MEDIAS, READ_IMAGES } from '../../queries/AssetQueries';
import PageLoadError from '../../components/PageLoadError';
import VideoAssetSelector from './modal-components/VideoAssetSelector';
import ImageAssetSelector from './modal-components/ImageAssetSelector';
import Button from '../buttons/Button';
import LoadingIndicator from '../LoadingIndicator';
import { PaginationBottom } from '../Pagination';
import { useDebounce } from 'use-debounce';
import StyledTextInput from '../../styled-components/StyledTextInput';
import { IsContentCreator } from '../../utility/Permissions';
import withUser from '../../HOCs/WithUser';

const StyledSearchWrapper = styled.div`
  margin-bottom: 3rem;
`;

const itemsPerPage = 20;

const SelectFromAssetsModal = (props) => {
  // const [currentMediaType, setMediaType] = useState(props.mediaTypes[0]);
  const [pageNum, setPageNum] = useState(1);
  const [currentSearch, setCurrentSearch] = useState('');
  const [currentSearchDB] = useDebounce(currentSearch, 1000);

  const variables = useMemo(() => {
    const filter = {
      Title: { contains: currentSearchDB },
    };

    // search video
    if (props.mediaType.startsWith('video')) {
      filter.Organisation = { ID: { eq: props.organisationID } };

      if (props.mediaType === 'video2D' || props.mediaType === 'video3D') {
        filter.Content360 = { eq: props.mediaType === 'video3D' };
      }
    } else {
      // search other types (images)
      filter.OrganisationID = { eq: props.organisationID };
      if (IsContentCreator()) {
        filter.AuthorID = { eq: props.userID };
      }
    }

    const options = {
      offset: (pageNum - 1) * itemsPerPage,
      limit: itemsPerPage,
      filter,
    };
    return options;
  }, [
    currentSearchDB,
    props.mediaType,
    props.organisationID,
    props.userID,
    pageNum,
  ]);

  const [
    getVideos,
    { data: videosData, loading: videosLoading, error: videosError },
  ] = useLazyQuery(READ_VIDEO_MEDIAS, {
    variables,
  });
  const [
    getImages,
    { data: imagesData, loading: imagesLoading, error: imagesError },
  ] = useLazyQuery(READ_IMAGES, {
    fetchPolicy: 'cache-and-network',
    variables,
  });
  const prevVisible = usePrevious(props.visible);
  const prevMutating = usePrevious(props.loading);
  const prevPage = usePrevious(pageNum);

  useEffect(() => {
    if (
      (prevVisible !== props.visible && props.visible) ||
      prevPage !== pageNum
    ) {
      if (props.mediaType.indexOf('video') !== -1) {
        // getVideos for all video mediaTypes
        getVideos();
      } else {
        getImages();
      }
    }
    if (props.closeWhenMutationComplete && prevMutating && !props.loading) {
      // close if mutation now complete
      props.hideModal();
    }
  });

  return (
    <Modal
      title="Select Asset"
      visible={props.visible}
      hideModal={props.hideModal}
      minWidth="80%"
    >
      <StyledSearchWrapper>
        <StyledTextInput
          name="filter"
          placeholder="Search"
          value={currentSearch}
          id="filter"
          onChange={(e) => {
            setCurrentSearch(e.target.value);
          }}
        />
      </StyledSearchWrapper>
      {props.mediaType.indexOf('video') !== -1 && (
        <>
          {videosLoading && <LoadingIndicator />}
          {!videosLoading && videosError && (
            <PageLoadError graphQLErrorObj={videosError} />
          )}
          {!videosLoading &&
            !videosError &&
            videosData?.readVideoMedias?.edges && (
              <>
                <VideoAssetSelector
                  videoEdges={videosData.readVideoMedias.edges}
                  disabledVideoIDs={props.disabledVideoIDs}
                  onConfirm={props.onConfirm}
                  loading={props.loading}
                  confirmText={props.confirmText}
                />
                <PaginationBottom
                  changePageCallback={setPageNum}
                  perPage={itemsPerPage}
                  page={pageNum}
                  totalCount={videosData.readVideoMedias.pageInfo.totalCount}
                  pageInfo={videosData.readVideoMedias.pageInfo}
                  showPrevNext={false}
                />
              </>
            )}
        </>
      )}
      {props.mediaType === 'image' && (
        <>
          {imagesLoading && <LoadingIndicator />}
          {!imagesLoading && imagesError && (
            <PageLoadError graphQLErrorObj={imagesError} />
          )}
          {!imagesLoading && !imagesError && imagesData?.readMedias?.edges && (
            <>
              <ImageAssetSelector
                imageEdges={imagesData.readMedias.edges}
                disabledImageIDs={props.disabledImageIDs}
                onConfirm={props.onConfirm}
                loading={props.loading}
                confirmText={props.confirmText}
              />
              <PaginationBottom
                changePageCallback={setPageNum}
                perPage={itemsPerPage}
                page={pageNum}
                totalCount={imagesData.readMedias.pageInfo.totalCount}
                pageInfo={imagesData.readMedias.pageInfo}
                showPrevNext={false}
              />
            </>
          )}
        </>
      )}
    </Modal>
  );
};

SelectFromAssetsModal.defaultProps = {
  loading: false,
  mediaType: 'video', // video/video2D/video3D/image/audio
  closeWhenMutationComplete: false,
};

SelectFromAssetsModal.propTypes = {
  visible: PropTypes.bool,
  selectedAssetID: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  confirmText: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  mediaType: PropTypes.string,
  organisationID: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  closeWhenMutationComplete: PropTypes.bool,
  userID: PropTypes.number.isRequired,
};

export default withUser(SelectFromAssetsModal);
