import React, { useEffect, useState, FC } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StyledRadio } from '../../styled-components/StyledCheckboxes';

const StyledRadioWrapper = styled.div`
  margin-bottom: 1rem;
`;
const StyledStyledRadio = styled(StyledRadio)`
  margin: 0;

  > div {
    display: flex;

    > label {
      margin-top: 0;
      margin-right: 1rem;
    }
  }
`;

/**
 * @type {FC<{
 *    disabled?: boolean,
 *    selected?: boolean,
 *    onSelected?: (selected: boolean) => void,
 *    multiple?: boolean,
 * }>}
 */
const VideoTypeSelector = ({
  disabled,
  selected,
  onSelected,
  multiple,
  ...props
}) => {
  const [content360, setContent360] = useState('true');

  useEffect(() => {
    setContent360(selected ? 'true' : 'false');
  }, [selected]);

  return (
    <>
      <StyledRadioWrapper {...props}>
        <StyledStyledRadio
          name="video-type"
          // title={`Video Type`}
          disabled={disabled}
          value={content360}
          onChange={(newVal) => {
            setContent360(newVal);
            onSelected?.(newVal === 'true');
          }}
          data={[
            {
              label: multiple ? 'Upload 360 Videos' : 'Upload 360 Video',
              htmlID: 'video-type-360',
              ID: 'video-type-360',
              value: 'true',
            },
            {
              label: multiple ? 'Upload 2D Videos' : 'Upload 2D Video',
              htmlID: 'video-type-2D',
              ID: 'video-type-2D',
              value: 'false',
            },
          ]}
        />
      </StyledRadioWrapper>
      {typeof props.children === 'function'
        ? props.children(content360 === 'true')
        : props.children}
    </>
  );
};

VideoTypeSelector.defaultProps = {
  selected: true,
};

VideoTypeSelector.propTypes = {
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  onSelected: PropTypes.func,
};

export default VideoTypeSelector;
