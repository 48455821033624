import { Icon } from '@virtidev/toolbox';
import styled from 'styled-components';

export const ListIconWrapper = styled.div`
  position: relative;
  border-radius: 60px;
  width: 32px;
  height: 32px;
  background-color: var(--pd-primary-color-lighter);
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ListIconCircleWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  height: 100%;
`;

export const ListIcon = styled.div`
  color: #fff;
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 0.8));
  width: 22px;
  height: 20px;
  border: 2px solid;
  border-radius: 3px;
  ::after,
  ::before {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 2px;
    height: 2px;
    background: currentColor;
    top: 3px;
    left: 3px;
    box-shadow: 0 4px 0, 0 8px 0;
  }
  ::after {
    border-radius: 3px;
    width: 8px;
    left: 7px;
  }
  color: var(--pd-positive-color);
  background-color: var(--pd-positive-color-lighter);
`;
