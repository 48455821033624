import { makeVar } from '@apollo/client';

/**
 * @template Item
 * @typedef {import('@apollo/client').ReactiveVar<Item>} ReactiveVar
 */

/**
 * @typedef {import("./apps/core/src/models/user.types").User} User
 * @typedef {import("./apps/core/src/components/FlashMessage/FlashMessage.types").FlashMessage} FlashMessage
 */

/**
 * @param {string} name
 * @param {any} fallback
 * @returns {any}
 */
export const getStorageItem = (name, fallback = null) => {
  try {
    const item = localStorage.getItem(name);
    if (!item) {
      return fallback;
    }
    return JSON.parse(item);
  } catch (e) {
    return fallback;
  }
};

/** @type {string} */
const user = getStorageItem('user');
export const userVar = makeVar(user);

/** @type {ReactiveVar<boolean | null>} */
export const sidebarContractedVar = makeVar(
  getStorageItem('sidebarContracted')
);

/** @type {ReactiveVar<boolean>} */
export const showCourseModalVar = makeVar(
  getStorageItem('showCourseModal', false)
);

/** @type {ReactiveVar<boolean>} */
export const canViewUserSideVirtualHumansVar = makeVar(
  getStorageItem('canViewUserSideVirtualHumans', false)
);

/** @type {ReactiveVar<boolean>} */
export const vhMicTooltipDismissedForeverVar = makeVar(
  getStorageItem('vhMicTooltipDismissedForever', false)
);

const type = localStorage.getItem('userMode') || user?.UserType || 'user';
/** @type {ReactiveVar<"admin" | "user">} */
export const userModeVar = makeVar(type !== 'user' ? 'admin' : 'user');

/** @type {ReactiveVar<string | null>} */
export const secondarySidebarContentVar = makeVar(
  getStorageItem('secondarySidebarContent')
);

/** @type {ReactiveVar<boolean>} */
export const showOnboardDialogueVar = makeVar(
  getStorageItem('onboardDialogue', false)
);

/** @type {ReactiveVar<object | boolean>} */
export const showOnboardProgressVar = makeVar(
  getStorageItem('onboardProgress')
);

/** @type {ReactiveVar<object | boolean>} */
export const lastCompletedOnboardingTaskVar = makeVar(
  getStorageItem('lastCompletedOnboardingTask')
);

/** @type {ReactiveVar<string | null>} */
export const intercomToursSeenVar = makeVar(
  getStorageItem('intercomToursSeen')
);

/** @type {ReactiveVar<boolean>} */
export const confirmModalActiveVar = makeVar(
  getStorageItem('confirmModalActive', false)
);

/** @type {ReactiveVar<boolean>} */
export const successfulUserSignupVar = makeVar(
  getStorageItem('successfulUserSignup', false)
);

export const flashMessagesVar = makeVar(/** @type {FlashMessage[]} */ ([]));

/** @type {ReactiveVar<boolean>} */
export const videoBarVisibleVar = makeVar(
  getStorageItem('videoBarVisible', true)
);

/**
 * @param {boolean} sidebarContracted
 */
export const setSidebarContractedVar = (sidebarContracted) => {
  localStorage.setItem('sidebarContracted', JSON.stringify(sidebarContracted));

  return sidebarContractedVar(sidebarContracted);
};

/**
 * @param {boolean} onboardDialogue
 */
export const setShowOnboardDialogueVar = (onboardDialogue) => {
  localStorage.setItem('showOnboardDialogue', JSON.stringify(onboardDialogue));

  return showOnboardDialogueVar(onboardDialogue);
};

/**
 * @param {boolean} status
 */
export const setSuccessfulUserSignup = (status) => {
  localStorage.setItem('successfulUserSignup', JSON.stringify(status));

  return successfulUserSignupVar(status);
};

/**
 * @param {object | boolean} onboardProgress
 */
export const setShowOnboardProgressVar = (onboardProgress) => {
  localStorage.setItem('showOnboardProgress', JSON.stringify(onboardProgress));

  return showOnboardProgressVar(onboardProgress);
};

/**
 * @param {"admin" | "user"} userMode
 */
export const setUserModeVar = (userMode) => {
  localStorage.setItem('userMode', userMode);

  return userModeVar(userMode);
};

/**
 * @param {boolean} confirmModalActive
 */
export const setConfirmModalActiveVar = (confirmModalActive) => {
  localStorage.setItem(
    'confirmModalActive',
    JSON.stringify(confirmModalActive)
  );

  return confirmModalActiveVar(confirmModalActive);
};

/**
 * @param {boolean} videoBarVisible
 */
export const setVideoBarVisibilityVar = (videoBarVisible) => {
  localStorage.setItem('videoBarVisible', JSON.stringify(videoBarVisible));

  return videoBarVisibleVar(videoBarVisible);
};

/**
 * @param {Omit<FlashMessage, "ID">} message
 */
export const addFlashMessage = (message) => {
  const { text, messageType, showOnNextPage, activatedAtLocation } = message;
  const flashMessages = flashMessagesVar();
  const highestID = flashMessages.reduce(
    (carry, message) => Math.max(carry, message.ID),
    1
  );
  const newFlashMessage = {
    ID: highestID + 1,
    text,
    messageType,
    showOnNextPage,
    activatedAtLocation,
  };
  return flashMessagesVar([...flashMessages, newFlashMessage]);
};

/**
 * @param {number} ID
 */
export const removeFlashMessage = (ID) => {
  const flashMessages = flashMessagesVar();
  const newMessages = flashMessages.filter(
    (flashMessage) => flashMessage.ID !== ID
  );
  return flashMessagesVar(newMessages);
};

export const removeFlashMessages = () => {
  return flashMessagesVar([]);
};

export const removeCurrentFlashMessages = () => {
  const flashMessages = flashMessagesVar();
  const nextMessages = flashMessages.filter(
    (flashMessage) => flashMessage.showOnNextPage === true
  );
  return flashMessagesVar(nextMessages);
};

export const setFlashMessagesAsCurrent = () => {
  const flashMessages = flashMessagesVar();
  const nextMessages = flashMessages.map((flashMessage) => {
    const newFlashMessage = { ...flashMessage };
    newFlashMessage.showOnNextPage = false;
    return newFlashMessage;
  });
  return flashMessagesVar(nextMessages);
};

/**
 * @param {User} userData
 */
export const setUserVar = (userData) => {
  localStorage.setItem('user', JSON.stringify(userData));
  return userVar(userData);
};

/**
 * @param {string | null} content
 */
export const setSecondarySidebarContentVar = (content) => {
  localStorage.setItem('secondarySidebarContent', JSON.stringify(content));
  return secondarySidebarContentVar(content);
};

/**
 * @param {object | boolean} task
 */
export const setLastCompletedOnboardingTaskVar = (task) => {
  localStorage.setItem('lastCompletedOnboardingTask', JSON.stringify(task));
  return lastCompletedOnboardingTaskVar(task);
};

/**
 * @param {string | null} toursObject
 */
export const setIntercomToursSeenVar = (toursObject) => {
  localStorage.setItem('intercomToursSeen', JSON.stringify(toursObject));
  return intercomToursSeenVar(toursObject);
};

/**
 * @param {boolean} visible
 */
export const setCanViewUserSideVirtualHumans = (visible) => {
  localStorage.setItem('canViewUserSideVirtualHumans', JSON.stringify(visible));
  return canViewUserSideVirtualHumansVar(visible);
};

/**
 * @param {boolean} vhMicTooltipShown
 */
export const setVhMicTooltipDismissedForever = (vhMicTooltipShown) => {
  localStorage.setItem(
    'vhMicTooltipDismissedForever',
    JSON.stringify(vhMicTooltipShown)
  );
  return vhMicTooltipDismissedForeverVar(vhMicTooltipShown);
};

/** @type {ReactiveVar<string[]>} */
export const previewFeaturesVar = makeVar(
  getStorageItem('preview-features-mode', [])
);

/**
 * @param {string[]} previewFeatures
 */
export const setPreviewFeaturesVar = (previewFeatures) => {
  localStorage.setItem(
    'preview-features-mode',
    JSON.stringify(previewFeatures)
  );
  return previewFeaturesVar(previewFeatures);
};
