import { forwardRef, useCallback, useMemo } from 'react';
import { useIntercom } from 'react-use-intercom';
import { getUser } from './useUser';

export const userTypeMap = {
  user: 'user',
  contentcreator: 'content_creator',
  admin: 'admin',
  super: 'admin',
  customeradmin: 'admin',
};

export const useIntercomHelpers = () => {
  const intercom = useIntercom();
  const { update, boot } = intercom;

  const bootIntercom = useCallback(() => {
    boot({
      hideDefaultLauncher: true,
      alignment: 'left',
    });
  }, [boot]);

  const clearUserData = useCallback(() => {
    update({
      hideDefaultLauncher: true,
      userId: null,
      name: null,
      email: null,
      avatar: null,
      company: null,
      companies: null,
    });
  }, [update]);

  const setUserData = useCallback(
    (data) => {
      let user = data;

      // in case the user data isn't provided
      if (!user) {
        user = getUser();
      }

      if (!user || !user.ID) {
        return;
      }

      const {
        ID,
        Name,
        Email,
        AvatarMedia,
        Organisation,
        Organisations,
        IntercomHash,
        UserType,
      } = user;

      const { URL: AvatarURL } = AvatarMedia || {};

      const user_type = userTypeMap[UserType] || 'user';

      if (!IntercomHash) {
        clearUserData();
        return;
      }

      const intercomUserData = {
        hideDefaultLauncher: true,
        userId: ID,
        userHash: IntercomHash,
        name: Name,
        email: Email,
        avatar: {
          type: 'avatar',
          imageUrl: AvatarURL,
        },
        company: {
          companyId: Organisation.ID,
          name: Organisation.Name,
          customAttributes: {
            trial: Organisation.Trial,
            customer_stage: Organisation.CustomerStage,
            partner: Organisation.Partner,
          },
        },
        companies: Organisations.map((company) => ({
          companyId: company.ID,
          name: company.Name,
          customAttributes: {
            trial: company.Trial,
            customer_stage: company.CustomerStage,
            partner: company.Partner,
          },
        })),
        customAttributes: {
          user_type,
        },
      };
      update(intercomUserData);
    },
    [update, clearUserData]
  );

  const helpers = useMemo(
    () => ({
      ...intercom,
      setUserData,
      clearUserData,
      bootIntercom,
    }),
    [intercom, setUserData, clearUserData, bootIntercom]
  );

  return helpers;
};

export const withIntercomHelpers = (WrappedComponent) => {
  const IntercomProvider = forwardRef((props, ref) => {
    const intercomHelpers = useIntercomHelpers();

    return (
      <WrappedComponent
        {...props}
        ref={ref}
        intercomHelpers={intercomHelpers}
      />
    );
  });

  return IntercomProvider;
};

export default useIntercomHelpers;
