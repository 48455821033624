import React from 'react';
import * as Styled from './FailIcon.styled';

export const CrossIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.102"
    height="12.113"
    viewBox="0 0 12.102 12.113"
  >
    <path
      d="M317.082,971.431a.876.876,0,0,1,0,1.221.85.85,0,0,1-1.221,0l-4.582-4.583-4.582,4.583a.85.85,0,0,1-1.221,0,.876.876,0,0,1,0-1.221l4.582-4.582-4.582-4.582a.863.863,0,1,1,1.221-1.221l4.582,4.582,4.582-4.582a.863.863,0,1,1,1.221,1.221l-4.582,4.582Z"
      transform="translate(-305.227 -960.797)"
    />
  </svg>
);

const FailIcon = (props) => {
  return (
    <Styled.FailIcon disabled={props.disabled}>
      <CrossIcon />
    </Styled.FailIcon>
  );
};

export default FailIcon;
