import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';
import { withApollo } from '@apollo/client/react/hoc';
import styled from 'styled-components';

import StyledLabel from '../styled-components/StyledLabel';
import StyledTextInput from '../styled-components/StyledTextInput';
import { EDIT_PROFILE } from '../queries/UserQueries';
import { MinLength, IsEmail } from '../utility/FormValidations';
import ValidationMessages from '../components/ValidationMessages';
import withFlashMessaging from '../HOCs/WithFlashMessaging';
import {
  StyledInputWrapper,
  StyledSections,
  StyledSection,
  StyledSectionInner,
} from '../styled-components/StyledFormStructure';
import Button from '../components/buttons/Button';
import SaveBar from '../components/SaveBar';
import WithFormHandler from '../HOCs/WithFormHandler';
import { UpdateUserData } from '../utility/LoginUtility';
import WithOnboardingHandler from '../HOCs/WithOnboardingHandler';
import MediaUploader from '../apps/core/src/components/MediaUploaders/MediaUploader';
import _ from 'lodash';
import WithConfirmationBox from '../HOCs/WithConfirmationBox';
import getConfig from '../lib/config';
import tracker from '../apps/core/src/helpers/tracker';
import DeleteMyAccountButton from '../apps/core/src/components/Account/DeleteMyAccountButton';

const StyledForm = styled.form`
  border-bottom: var(--primary-border);
  padding-bottom: 1rem;
  margin-bottom: 2rem;
`;

const StyledTextInputWrapper = styled(StyledInputWrapper)`
  grid-area: ${(props) => props.gridArea};
`;

const StyledSectionInnerModified = styled(StyledSectionInner)`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 2rem;
`;

const StyledAvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const StyledSideInputsWrapper = styled.div`
  display: grid;
  grid-template-areas:
    'FirstName Surname'
    'Email Email';
  grid-column-gap: 1rem;
`;

const formConfig = {
  inputs: [
    {
      dbName: 'FirstName',
      label: 'First Name',
      // tourStepToComplete: 'firstNameUpdated',
      type: 'text',
      isCustomRender: false,
      customInputKey: null, // key that stores an object of different state values associated with the input e.g. 'dob'
      customInputFunc: null, // func that takes the customInputsState and generates a value that the db value should be (for when something requires multiple state values)
      customInputInitialFunc: null, // func that takes the object data as an argument and generates the initial state value
    },
    {
      dbName: 'Surname',
      label: 'Surname',
      // tourStepToComplete: 'surnameUpdated',
      type: 'text',
      isCustomRender: false,
      customInputKey: null,
      customInputFunc: null,
    },
    {
      dbName: 'Email',
      label: 'Email',
      type: 'text',
      disabled: true,
      isCustomRender: false,
      customInputKey: null,
      customInputFunc: null,
    },
    // {
    //   dbName: 'AvatarURL',
    //   type: 'text',
    //   isCustomRender: true,
    // },

    {
      dbName: 'AvatarMediaID',
      isCustomRender: true,
      customInputKey: 1,
    },
  ],
  validations: [
    IsEmail('Email', 'Email', true),
    // MinLength('FirstName', 'First Name', true, 1),
    // MinLength('Surname', 'Surname', true, 1),
  ],
};

class UserProfileForm extends Component {
  componentDidMount() {
    this.props.setInitialData(this.props.dataObject, () => {
      // this.props.setInputImgState(
      //   'AvatarID',
      //   'AvatarURL',
      //   this.props.dataObject,
      //   this.props.dataObject.AvatarID,
      //   null
      // );
      // this.props.setInputImgState(
      //   'AvatarMediaID',
      //   'AvatarURL',
      //   this.props.dataObject,
      //   this.props.dataObject.AvatarMediaID,
      //   null
      // );
    });
    this.props.handleTourStart(
      getConfig('REACT_APP_INTERCOM_EDIT_PROFILE_TOUR'),

      'upload_profile'
    );
  }

  _submitForm = (e, mutate) => {
    e.preventDefault();
    this.props.triggerRevalidation((valid) => {
      if (!valid) return;
      this.props.setSubmittingForm(true);
      const input = this.props.currentFormData;
      input.ID = this.props.dataObject?.ID;
      let variables = { Input: input };
      mutate({
        variables,
        // refetchQueries: refetches,
      });
    });
    this.props.updateProgress('upload_profile');
    tracker.track('user_profile_edited');
  };

  _onTextInputChange = (e, stateKey) => {
    this.props.setInputState(stateKey, e.target.value, false, true);
  };

  render() {
    return (
      <Mutation
        mutation={EDIT_PROFILE}
        onCompleted={(data) => {
          this.props.setSubmittingForm(false);
          this.props.resetPrevFormData();
          this.props.addFlashMessage('Your profile has been updated');
          UpdateUserData(data.updateMember, this.props.client);
        }}
        onError={() => {
          this.props.setSubmittingForm(false);
          this.props.addFlashMessage(
            'There was an error updating your profile',
            'error'
          );
        }}
      >
        {(mutateMember, { mutationData }) => (
          <StyledForm
            onSubmit={(event) => this._submitForm(event, mutateMember)}
          >
            <StyledSections>
              <StyledSection>
                <StyledSectionInnerModified>
                  <StyledAvatarWrapper data-intercom-target="avatar-wrapper">
                    <StyledInputWrapper>
                      <StyledLabel centered={true}>Avatar</StyledLabel>
                      <MediaUploader
                        mediaType="image"
                        mediaTusID={this.props.dataObject?.AvatarMedia?.TusID}
                        parentState={this.props.imgInputsData}
                        src={this.props.dataObject?.AvatarMedia?.URL}
                        onSuccess={({ ID }) => {
                          this.props.setInputState('AvatarMediaID', ID);
                        }}
                        round
                        onRemove={() => {
                          this.props.setInputState('AvatarMediaID', '0');
                        }}
                      />
                    </StyledInputWrapper>
                  </StyledAvatarWrapper>
                  <div>
                    <StyledSideInputsWrapper data-intercom-target="user-profile-details">
                      {formConfig.inputs
                        .filter((input) => !input.isCustomRender)
                        .map((input, index) => (
                          <StyledTextInputWrapper
                            gridArea={input.dbName}
                            key={input.dbName}
                          >
                            <StyledTextInput
                              data-intercom-target={`text-input-${input.dbName}`}
                              disabled={input.disabled}
                              id={input.dbName}
                              name={input.dbName}
                              label={input.label}
                              onChange={(e) =>
                                this._onTextInputChange(e, input.dbName)
                              }
                              value={this.props.currentFormData[input.dbName]}
                              type={input.type}
                              invalid={this.props.invalidFields.includes(
                                input.dbName
                              )}
                            />
                          </StyledTextInputWrapper>
                        ))}
                      <DeleteMyAccountButton />
                      <SaveBar>
                        <Button
                          data-intercom-target="profile-save-button"
                          className="user-profile-save"
                          disabled={!this.props.isDirtyForm}
                          dataTestId="save-bar-btn"
                          loading={this.props.submittingForm}
                        >
                          Save
                        </Button>
                      </SaveBar>
                    </StyledSideInputsWrapper>
                  </div>
                </StyledSectionInnerModified>
                <ValidationMessages errors={this.props.validationErrors} />
              </StyledSection>
            </StyledSections>
          </StyledForm>
        )}
      </Mutation>
    );
  }
}

UserProfileForm.propTypes = {
  dataObject: PropTypes.object,
  setInitialData: PropTypes.func.isRequired,
  currentFormData: PropTypes.object.isRequired,
  setSubmittingForm: PropTypes.func.isRequired,
  submittingForm: PropTypes.bool.isRequired,
  setInputState: PropTypes.func.isRequired,
  invalidFields: PropTypes.array.isRequired,
  validationErrors: PropTypes.array.isRequired,
  triggerRevalidation: PropTypes.func.isRequired,
  setInputImgState: PropTypes.func.isRequired,
  imgInputsData: PropTypes.object.isRequired,
  isDirtyForm: PropTypes.bool.isRequired,
};

export default WithFormHandler(
  _.flowRight(
    withApollo,
    WithOnboardingHandler,
    withFlashMessaging,
    WithConfirmationBox
  )(UserProfileForm),
  formConfig
);
