import _ from 'lodash';
import {
  Body,
  Container,
  SavebarHolder,
  SimulationsContainer,
} from './CourseForm.styled';
import { CardTitle, Box } from './components/Card';
import { ModuleList } from './components/ModuleList/ModuleList';
import DetailsForm from './components/DetailsForm/DetailsForm';
import { PagePartHolder } from '../Page/PagePartHolder';
import { useEffect, useState } from 'react';
import WithOnboardingHandler from '../../../../../HOCs/WithOnboardingHandler';
import PreviewList from './components/PreviewList/PreviewList';
import getConfig from '../../../../../lib/config';

export const EditCourseForm = ({ courseId, handleTourStart }) => {
  const [intercomTarget, setIntercomTarget] = useState();
  useEffect(() => {
    handleTourStart(
      getConfig('REACT_APP_INTERCOM_EDIT_COURSE_TOUR'),
      'add_course_unit'
    );
  }, [handleTourStart]);

  return (
    <Container>
      <Body>
        <DetailsForm courseId={courseId} />
        <SimulationsContainer
          horizontal
          data-intercom-target="course-tab-nav-simulations"
        >
          <Box data-intercom-target="course-structure-box">
            <CardTitle>Course Structure</CardTitle>
            <ModuleList courseId={courseId} />
          </Box>
          <Box data-intercom-target={intercomTarget}>
            <CardTitle>Pick Content</CardTitle>
            <PreviewList
              courseId={courseId}
              setIntercomTarget={setIntercomTarget}
            />
          </Box>
        </SimulationsContainer>
      </Body>
      <PagePartHolder Component={SavebarHolder} outlet="course-savebar" />
    </Container>
  );
};

export default WithOnboardingHandler(EditCourseForm);
