import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import {
  EDIT_ONBOARD_PROGRESS,
  READ_ONBOARD_PROGRESS,
} from '../queries/UserQueries';
import {
  setLastStep,
  useOnboardingProgress,
  useResetOnboardingProgress,
} from '../utility/OnboardingHooks';
import withUser from './WithUser';
import {
  intercomToursSeenVar,
  setIntercomToursSeenVar,
  setShowOnboardDialogueVar,
} from '../ApolloReactiveVars';
import { CanViewOnboarding, ShowAdminOnboarding } from '../utility/Permissions';
import { withRouter } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import { useHistory } from 'react-router-dom';
import WithFlashMessaging from './WithFlashMessaging';
import useUser from '../apps/core/src/helpers/useUser';
import getConfig from '../lib/config';

const WithOnboardingHandler = (WrappedComponent, pageKey) => {
  const OnboardingHandler = (props) => {
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const { startTour } = useIntercom();
    const history = useHistory();

    const onboardingFeatureIsActive = CanViewOnboarding();
    const { SignupMethod } = useUser() || {};

    const { data, loading } = useQuery(READ_ONBOARD_PROGRESS, {
      variables: {
        ID: props.userID,
      },
      // disabled until after graphql4 migration
      skip: !props.userID || true,
    });

    const [mutateUpdateOnboarding, { loading: mutating }] = useMutation(
      EDIT_ONBOARD_PROGRESS
    );

    // reminder: this hook returns the object with all old fields and all progress keys set to false
    // to reset a user's progress, stringify and put it in the mutation as OnboardProgress
    const reset = useResetOnboardingProgress(false);
    const resetAndDismiss = useResetOnboardingProgress(true);

    const parsedData =
      data?.readOneMember?.OnboardProgress &&
      JSON.parse(data.readOneMember.OnboardProgress);

    // this effect checks if the progress data exists on a member's OnboardProgress, and if not, does a reset
    // this both starts the onboarding progress for new users and stops things from breaking for old users
    useEffect(() => {
      if (!data?.readOneMember) {
        return;
      }
      // new user case:
      if (data && !data.readOneMember.OnboardProgress) {
        const method = SignupMethod || 'public';
        // new user on organisation with onboarding enabled
        // exlcuding public signup for now
        if (onboardingFeatureIsActive && method !== 'public') {
          mutateUpdateOnboarding({
            variables: {
              Input: {
                ID: props.userID,
                OnboardProgress: JSON.stringify(resetAndDismiss),
                // OnboardProgress: JSON.stringify(reset), ---- removed until after graphql4 migration
              },
            },
          });
          // setShouldRedirect(true);  ----- removed until after GraphQL4 migration
          // startTour(getConfig("REACT_APP_INTERCOM_WELCOME_TO_VIRTI_TOUR"));  ----- removed until after GraphQL4 migration
          return;
        }

        // new user on organisation with onboarding disabled (they only have one chance to be put into onboarding)
        // this means public signup method may need to have onboarding restarted somehow if they become a real customer
        mutateUpdateOnboarding({
          variables: {
            Input: {
              ID: props.userID,
              OnboardProgress: JSON.stringify(resetAndDismiss),
            },
          },
        });
        return;
      }
      // old user that needs new onboarding data SET TO SEEN
      if (!parsedData?.progress) {
        mutateUpdateOnboarding({
          variables: {
            Input: {
              ID: props.userID,
              OnboardProgress: JSON.stringify(resetAndDismiss),
            },
          },
        });
      }
    }, [
      data,
      reset,
      mutateUpdateOnboarding,
      props.userID,
      resetAndDismiss,
      onboardingFeatureIsActive,
      startTour,
      parsedData,
      SignupMethod,
    ]);

    const parsedOnboardKeys = parsedData?.progress;

    // This runs the onboarding keys through the object with all of the task info
    const onboardingData = useOnboardingProgress(parsedOnboardKeys);

    // this sets the main progress bar in onboardingDash
    const progress = useMemo(() => {
      if (!onboardingData) {
        return 0;
      }
      const total = onboardingData.reduce((acc, section) => {
        return acc + section.Progress;
      }, 0);

      const percent = total / onboardingData.length;

      return percent;
    }, [onboardingData]);

    const updateProgress = (key, createdID) => {
      // don't update if they've dimissed onboarding or aren't an admin
      if (
        !parsedData ||
        parsedData?.onboardingDismissed ||
        !onboardingFeatureIsActive
      ) {
        return;
      }
      if (!ShowAdminOnboarding) {
        return;
      }
      if (!parsedData?.progress[key]) {
        setLastStep(key, onboardingData);
        const oldOnboardingData = onboardingData;

        const newOnboardKeys = oldOnboardingData.reduce((acc, section) => {
          section.Tasks.forEach((task) => {
            if (task.Key === key) {
              acc[task.Key] = true;

              return task;
            } else if (task.Completed === true) {
              acc[task.Key] = true;
            } else {
              acc[task.Key] = false;
              return task;
            }
          });
          return acc;
        }, {});

        const keysWithOldFields = { ...parsedData };
        keysWithOldFields.progress = newOnboardKeys;

        // this sets IDs for anything that locked tasks are dependent on once unlocked
        // this also is where you can choose to show the task completed dialogue
        if (progress !== 100) {
          switch (key) {
            case 'add_course':
              keysWithOldFields.createdCourseID =
                parsedData?.createdCourseID || createdID;
              break;
            case 'create_simulation':
              keysWithOldFields.createdSimID =
                parsedData?.createdSimID || createdID;
              break;
            case 'create_form':
              keysWithOldFields.createdFormID =
                parsedData?.createdFormID || createdID;
              setShowOnboardDialogueVar(true);
              break;
            case 'add_course_module':
            case 'create_form_question':
              // nothing needed
              break;
            default:
              setShowOnboardDialogueVar(true);
          }
        } else {
          // if progress is 100%, show this no matter what (in case tasks are done out of order)
          setShowOnboardDialogueVar(true);
        }
        mutateUpdateOnboarding({
          variables: {
            Input: {
              ID: props.userID,
              OnboardProgress: JSON.stringify(keysWithOldFields),
            },
          },
        });
      }
    };

    const setDismissOnboarding = (value) => {
      history.push('/');
      const dataForMutation = { ...parsedData };

      dataForMutation.onboardingDismissed = value;

      mutateUpdateOnboarding({
        variables: {
          Input: {
            ID: props.userID,
            OnboardProgress: JSON.stringify(dataForMutation),
          },
        },
        onError: () => {
          props.addFlashMessage(
            'Failed to dismiss onboarding. Please check your network and try again.',
            'error'
          );
        },
        onCompleted: () => {
          props.addFlashMessage(
            'Onboarding successfully dismissed. You will no longer get onboarding notifications.',
            'success',
            true
          );
        },
      });
    };

    const setRedirectedToOnboarding = () => {
      const newData = { ...parsedData };
      newData.redirectedToOnboarding = true;

      mutateUpdateOnboarding({
        variables: {
          Input: {
            ID: props.userID,
            OnboardProgress: JSON.stringify(newData),
          },
        },
      });
    };

    const resetOnboarding = () => {
      setIntercomToursSeenVar(null);
      mutateUpdateOnboarding({
        variables: {
          Input: {
            ID: props.userID,
            OnboardProgress: JSON.stringify(reset),
          },
        },
      });
    };

    const intercomToursSeen = useReactiveVar(intercomToursSeenVar);

    const handleTourStart = (tourId, taskKey) => {
      if (
        !onboardingFeatureIsActive ||
        parsedData?.onboardingDismissed ||
        parsedData?.progress[taskKey] ||
        intercomToursSeen === tourId ||
        !tourId
      ) {
        return;
      }
      setIntercomToursSeenVar(tourId);
      startTour(tourId);
    };

    return (
      <WrappedComponent
        {...props}
        updateProgress={updateProgress}
        onboardingData={onboardingData}
        totalProgress={progress}
        parsedData={parsedData}
        setDismissOnboarding={setDismissOnboarding}
        setRedirectedToOnboarding={setRedirectedToOnboarding}
        resetOnboarding={resetOnboarding}
        shouldRedirect={shouldRedirect}
        loading={loading || mutating}
        handleTourStart={handleTourStart}
      />
    );
  };
  return WithFlashMessaging(withUser(withRouter(OnboardingHandler)));
};
export default WithOnboardingHandler;
