import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  StyledAssets,
  StyledThumb,
  StyledThumbWrapper,
  StyledTitle,
  StyledSelectButton,
} from '../../../styled-components/ModalSelectorComponents';
import NoSearchResults from '../../NoSearchResults';

const StyledDate = styled.div`
  text-align: center;
`;
const ImageAssetSelector = (props) => {
  return (
    <StyledAssets itemsPerColumn="4">
      {props.imageEdges.length === 0 && <NoSearchResults />}
      {props.imageEdges.map((imageEdge) => (
        <StyledThumbWrapper key={imageEdge.node.ID}>
          <div>
            <StyledThumb src={imageEdge.node.URL} />
            <StyledSelectButton
              onClick={() => {
                props.onConfirm(imageEdge);
              }}
              loading={props.loading}
            >
              {props.confirmText || 'Upload New'}
            </StyledSelectButton>
          </div>
          <StyledTitle>{imageEdge.node.Title}</StyledTitle>
        </StyledThumbWrapper>
      ))}
    </StyledAssets>
  );
};

ImageAssetSelector.propTypes = {
  imageEdges: PropTypes.array.isRequired,
  onConfirm: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  confirmText: PropTypes.string,
  // disabledImageIDs: PropTypes.arrayOf(PropTypes.number),
};

export default ImageAssetSelector;
