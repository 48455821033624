import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import {
  COPY_FEEDBACK_FORM,
  DELETE_FEEDBACK_FORM,
  READ_FEEDBACK_FORMS,
} from '../queries/FeedbackFormQueries';
import StyledLink, { StyledLinkAsBtn } from '../styled-components/StyledLink';
import withFlashMessaging from '../HOCs/WithFlashMessaging';
import WithConfirmationBox from '../HOCs/WithConfirmationBox';
import withUser from '../HOCs/WithUser';
import Icon from './icons/Icon';
import { Button, PaginationControl } from '@virtidev/toolbox';
import useSortUrl from '@core/helpers/useSortUrl';
import { usePaginationUrl } from '@core/components/PaginationControl/helpers/usePaginationUrl';
import { usePageScroll } from '@core/components/Page';
import useStateUrl from '@core/helpers/useStateUrl';
import { createdTitle as sortOptions } from '@core/helpers/sortCollections';
import _ from 'lodash';
import {
  PlaceholderFeedbackFormListItem,
  StyledAdminTitle,
  StyledFormItem,
  StyledFormItemDetails,
  StyledFormTitleLink,
  StyledNoFormsMessage,
  Titlebar,
} from '@base/components/FeedbackFormsList.styled';

export const FeedbackFormsList = (props) => {
  const { scrollTop } = usePageScroll();
  const { pageQuery, controlProps } = usePaginationUrl({
    onChange: scrollTop,
    pageSize: 20,
  });
  const { value: titleFilter } = useStateUrl({});
  const { sortField, sortDirection } = useSortUrl(sortOptions);

  const variables = useMemo(
    () => ({
      OrganisationID: props.userOrganisationID,
      ...pageQuery,
      filter: {
        Organisation: { ID: { eq: props.userOrganisationID } },
        TitleOrAdminTitle: { contains: titleFilter || '' },
      },
      sort: {
        [sortField]: sortDirection,
      },
    }),
    [props.userOrganisationID, pageQuery, titleFilter, sortField, sortDirection]
  );

  const { data, loading, error } = useQuery(READ_FEEDBACK_FORMS, {
    variables,
  });

  const [deleteForm, { loading: mutatingDelete }] = useMutation(
    DELETE_FEEDBACK_FORM,
    {
      onCompleted: (e) => {
        props.addFlashMessage('Form deleted!');
      },
      update: (cache) => {
        // forces the pagination to refresh
        cache.evict({ fieldName: 'readFeedbackForms' });
      },
      onError: () => {
        props.addFlashMessage(
          'Something went wrong: form could not be deleted.',
          'error'
        );
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const [copyForm, { loading: mutatingCopy }] = useMutation(
    COPY_FEEDBACK_FORM,
    {
      onCompleted: (r) => {
        props.addFlashMessage('Copied!');
      },
      update: (cache) => {
        // forces the pagination to refresh
        cache.evict({ fieldName: 'readFeedbackForms' });
      },
      onError: () => {
        props.addFlashMessage(
          'Something went wrong: form could not be copied.',
          'error'
        );
      },
    }
  );

  const handleDelete = (ID) => {
    deleteForm({
      variables: {
        IDs: [ID],
      },
      onQueryUpdated: async (observableQuery) => {
        return observableQuery.refetch();
      },
    });
  };

  const handleCopy = async ({ ID }) => {
    await copyForm({
      variables: {
        FormID: ID,
      },
      onQueryUpdated: async (observableQuery) => {
        return observableQuery.refetch();
      },
    });
  };

  return (
    <div data-intercom-target="feedback-forms-list">
      {!data?.readFeedbackForms?.nodes?.length && !loading && (
        <StyledNoFormsMessage>
          {titleFilter ? (
            `No forms found for '${titleFilter}'!`
          ) : (
            <>
              No forms exist – why not{' '}
              <StyledLinkAsBtn onClick={props.handleCreate}>
                create one?
              </StyledLinkAsBtn>
            </>
          )}
          <Icon
            name="FormsPreviewUI"
            type="illustrations"
            customWidth="40rem"
            customHeight="30rem"
          />
        </StyledNoFormsMessage>
      )}
      {error && (
        <StyledNoFormsMessage>Something went wrong...</StyledNoFormsMessage>
      )}
      {mutatingCopy && <PlaceholderFeedbackFormListItem />}
      {data?.readFeedbackForms?.nodes?.length && (
        <>
          {data.readFeedbackForms.nodes.map((form, index) => {
            if (mutatingDelete) {
              return (
                <PlaceholderFeedbackFormListItem key={form?.ID || index + 1} />
              );
            }
            return (
              <StyledFormItem key={form.ID}>
                <Titlebar>
                  <StyledFormTitleLink to={`/feedback-forms/${form.ID}`}>
                    {form.Title || '(No title)'}
                    {form.AdminTitle && (
                      <StyledAdminTitle> ({form.AdminTitle})</StyledAdminTitle>
                    )}
                  </StyledFormTitleLink>

                  <Button
                    color="turquoise"
                    disabled={mutatingCopy}
                    loading={mutatingCopy}
                    icon="duplicate"
                    title="Duplicate form"
                    onClick={() =>
                      props.confirm(
                        () => {
                          handleCopy(form);
                        },
                        'Copy this form?',
                        `Question data will be copied but all submission and analytics data will be separate.`
                      )
                    }
                  />
                  <Button
                    color="turquoise"
                    design="outline"
                    disabled={mutatingDelete}
                    loading={mutatingDelete}
                    icon="bin"
                    title="Delete form"
                    onClick={() =>
                      props.confirm(
                        () => {
                          handleDelete(form.ID);
                        },
                        'Are you sure you want to delete this form?',
                        `All submission data for "${form.Title}" will be also be deleted.`,
                        'delete'
                      )
                    }
                  />
                </Titlebar>
                <StyledFormItemDetails>
                  <div>Submissions: {form.Submissions.pageInfo.totalCount}</div>
                  <div></div>
                  <StyledLink to={`/feedback-forms/${form.ID}/submissions`}>
                    View Submissions
                  </StyledLink>

                  <StyledLink to={`/feedback-forms/${form.ID}/analytics`}>
                    View Analytics
                  </StyledLink>
                </StyledFormItemDetails>
              </StyledFormItem>
            );
          })}
          <PaginationControl
            {...controlProps}
            total={data?.readFeedbackForms?.pageInfo?.totalCount}
          />
        </>
      )}
      {loading &&
        [...Array(3)].map((_, index) => {
          return <PlaceholderFeedbackFormListItem key={index + 1} />;
        })}
    </div>
  );
};

FeedbackFormsList.propTypes = {
  userOrganisationID: PropTypes.number.isRequired,
  handleCreate: PropTypes.func.isRequired,
};

export default _.flowRight(
  withUser,
  WithConfirmationBox,
  withFlashMessaging
)(FeedbackFormsList);
