import React, { FC, useCallback, useEffect, useState } from 'react';
import * as Styled from './CoachingSessionReportGenerator.styled';
import { Button } from '@virtidev/toolbox';
import { sendPostRequest } from '@vh/services/PDService';
import { useFlashMessage } from '@core/components/FlashMessage';

/**
 * @typedef {import('../../../../../../models/virtualhuman.types').CoachingSessionReport} CoachingSessionReport
 * @typedef {import('../../../../../../models/virtualhuman.types').CoachingSessionReportObjective} CoachingSessionReportObjective
 */

/** @type {FC<{assessmentId: number; onReportGenerated: (report: CoachingSessionReport & {objectiveReports: CoachingSessionReportObjective[]}) => void}>} */
const CoachingSessionReportGenerator = ({
  assessmentId,
  onReportGenerated,
}) => {
  const [loading, setLoading] = useState(false);
  const { addFlashMessage } = useFlashMessage();
  const handleGenerateReport = useCallback(async () => {
    setLoading(true);
    try {
      const response = await sendPostRequest(
        `assessments/${assessmentId}/coaching-session-report`
      );
      if (response?.data?.data) {
        onReportGenerated(response.data.data);
      } else {
        addFlashMessage('Failed to parse response', 'error');
      }
    } catch (e) {
      addFlashMessage('Failed to generate report', 'error');
    }
    setLoading(false);
  }, [assessmentId, addFlashMessage, onReportGenerated]);

  return (
    <Styled.ReportWrapper>
      <Styled.ReportTextSection>
        <Styled.ReportTitle>Analyse performance</Styled.ReportTitle>
        <Styled.ReportText>
          Get an AI-generated qualitative analysis of how well this session
          covered the objectives and how the user could improve.
        </Styled.ReportText>
      </Styled.ReportTextSection>
      <div>
        <Button
          color="turquoise"
          design="outline"
          loading={loading}
          disabled={loading}
          onClick={handleGenerateReport}
        >
          ✨ Generate with AI
        </Button>
      </div>
    </Styled.ReportWrapper>
  );
};

export default CoachingSessionReportGenerator;
