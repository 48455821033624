import styled, { css } from 'styled-components';
import { Clickable } from 'reakit/Clickable';

export const Heading = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.feedbackText && props.feedbackText !== ''
      ? '2.7rem 1fr 2rem auto'
      : '2.7rem 1fr 1fr'};
  align-items: center;
  line-height: 1.3rem;
  gap: 0.5rem;
`;

export const ExpandClickable = styled(Clickable)`
  width: 100%;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid var(--pd-secondary-color-light);
  padding: 1rem;
  margin-bottom: 1rem;
`;
export const ObjectiveCount = styled.strong`
  margin-left: auto;
  display: flex;
  font-size: 0.85rem;
  font-weight: bold;
`;
export const Objective = styled.div`
  background-color: #fff;
  border-radius: 6px;
  padding: 0.6rem;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 3rem 1fr 6rem;
  font-size: 0.9rem;
  ${(props) =>
    props.timesHit > 0 &&
    props.points > 0 &&
    props.scoreKey !== 'neutral' &&
    css`
      background-color: var(--pd-positive-color-lighter);
    `}
  ${(props) =>
    props.timesHit > 0 &&
    props.points < 0 &&
    props.scoreKey !== 'neutral' &&
    css`
      background-color: var(--pd-negative-color-lighter);
    `}
  ${(props) =>
    props.timesHit > 0 &&
    (props.scoreKey === 'neutral' || props.hintedToZeroPoints) &&
    css`
      background-color: var(--pd-neutral-color-lighter);
    `}
`;

export const ObjectiveTitle = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ObjectivePoints = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  row-gap: 0.2rem;
  font-size: 0.85rem;
`;

export const ObjectiveList = styled.div`
  padding-top: 1rem;
  display: grid;
  row-gap: 0.5rem;
`;
export const Expandable = styled.div``;
export const ChevronWrapper = styled.div`
  opacity: 0.4;
  margin-left: 0.5rem;
`;
export const ChevronUp = styled.div`
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
  ::after {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(-45deg);
    left: 4px;
    bottom: 2px;
  }
`;
export const ChevronDown = styled.div`
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
  :after {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
    left: 4px;
    top: 2px;
  }
`;

export const ObjectiveGroupFeedbackIcon = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ObjectiveGroupFeedbackWrapper = styled.div`
  background-color: #fff;
  border-radius: 6px;
  padding: 0.6rem;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 3rem 1fr;
  font-size: 0.9rem;
  border: 1px solid var(--secondary-color, #46beaf);
`;

export const ObjectiveGroupFeedbackIconWrapper = styled.div`
  background-color: var(--primary-color, #528fbf);
  color: #fff;
  width: 2rem;
  height: 2rem;
  position: relative;
  border-radius: 50%;
`;

export const ObjectiveGroupFeedbackText = styled.p`
  color: var(--primary-font-light);
`;
