import styled from 'styled-components';

export const ResultsBox = styled.div`
  max-height: 400px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 0.8rem;
`;

export const SuccessResult = styled.div`
  color: var(--positive-color);
  padding: 10px 0px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const FailResult = styled(SuccessResult)`
  color: var(--negative-color);
`;

export const ExpandWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  gap: 10px;
  font-size: 0.9rem;
  align-items: center;
`;
