import { gql } from '@apollo/client';
import { pseudoInteractionFragment } from './InteractionQueries';

// __typename = AnalyticsSubmission
export const pseudoScoreFragment = `
  ID
  LastEdited
  Created
  Score
  Points
  Time
  Finished
  FirstScore
  LatestScore
  BestScore
  UnitID
  Quiz
  Member {
    ID
    Name
  }
`;

// __typename = CompletedSimulation
const pseudoAltScoreFragment = `
  ID
  Created
  FirstScore
  LatestScore
  BestScore
  Member {
    ID
    Name
  }
`;

export const pseudoSimulationFragmentBase = `
  ID
  Title
  AdminTitle
  LastEdited
  LastSaved
  OrganisationID
  Author {
    ID
    Name
  }
  Created
  Description
  Status
  ImageMediaID
  ImageMedia {
    ID
    TusID
    URL
  }
  FirstVideo: Videos(limit: 1) {
    nodes {
      PosterURL
    }
  }
  Status
  TranscodingStatus
  TotalUsers
  TotalViews
  AverageScore
  # Views
  Likes
  isLiked
  TotalLength
  StartVideoID
  TotalCompletions
  Featured
  FeedbackFormBeforeCompleted
  FeedbackFormBefore {
    ID
    Title
    Hash
    Trigger
    AllowMultipleSubmissions
  }
  FeedbackFormAfterCompleted
  FeedbackFormAfter {
    ID
    Title
    Hash
    Trigger
    AllowMultipleSubmissions
  }
  CoursesOnly
  Content360
  ViewingPosition
  Quiz
  Editors {
    edges {
      node {
        ID
        Name
      }
    }
  }
  AnalyticsLastUpdated
  PublicAccess
  HideScoresFromUsers
  ContentEndpoint
`;

export const pseudoSimulationFragment = `
  ${pseudoSimulationFragmentBase}
  Events {
    nodes {
      ${pseudoInteractionFragment}
    }
  }
  Editors {
    edges {
      node {
        ID
        Name
      }
    }
  }
  EventClicks(limit: 10, filter: { SimulationID: { eq: $ID } }) {
    edges {
      node {
        ID
        Action
        Label
        Title
        Clicks
        AnalyticsEvents {
          pageInfo {
            totalCount
          }
        }
        Action
      }
    }
  }

  leaderboard: AnalyticsSubmissions(
    filter: { Quiz: { eq: true } }
    limit: 10
    sort: { Points: DESC }
  ) {
    edges {
      node {
        ${pseudoScoreFragment}
      }
    }
  }
  mostRecent: AnalyticsSubmissions(
    filter: { Finished: { eq: true } }
    limit: 10
    sort: { Created: DESC }
  ) {
    edges {
      node {
        ${pseudoScoreFragment}
      }
    }
  }
`;

export const pseudoVideoMediaFragment = `
  ID
  TranscodingStatus
  URL
  EditorURL
  PosterURL
  SourceURL
  Title
  Created
  Length
  TusID
  Content360
  Width
  Height
  Archived
  Subtitles {
    pageInfo {
      totalCount
    }
  }
  `;

export const pseudoVideoFragment = `
  ID
  TranscodingStatus
  URL
  EditorURL
  PosterURL
  Rotation
  Title
  EndAction
  VideoRedirectID
  VideoRedirectEventID
  VideoURL
  Created
  Length
  TusID
  Content360
  Width
  Height
  Archived
  Events {
    nodes {
      ID
      Title
      Type
      SimulationID
    }
  }
  `;
// extra vars used by unity:
// VideoID
// VideoDownloadLowURL
// VideoDownloadURL
// VideoDownloadHighURL
// Length

export const pseudoStudentVideoFragment = `
  ID
  TranscodingStatus
  URL
  EditorURL
  PosterURL
  Rotation
  Title
  EndAction
  VideoRedirectID
  VideoRedirectEventID
  Created
  TusID
  Content360
  Width
  Height
  Archived
  Length
  `;

export const EDIT_VIDEO = gql`
  mutation updateVideo($Input: UpdateVideoInput!) {
    updateVideo(input: $Input) {
      ${pseudoVideoFragment}
    }
  }
`;

export const EDIT_VIDEO_MEDIA = gql`
  mutation updateVideoMedia($Input: UpdateVideoMediaInput!) {
    updateVideoMedia(input: $Input) {
      ID
      Title
    }
  }
`;

export const READ_SIMULATIONS = gql`
query readSimulations($Filter: String, $organisationID: ID) {
  readSimulations(
    filter: {
      Title: { contains: $Filter }
      Organisation: { ID: { eq: $organisationID } }
    }
  ) {
    edges {
      node {
        ${pseudoSimulationFragmentBase}
      }
    }
  }
}
`;

export const READ_SIMULATIONS_PAGINATED = gql`
query readSimulationsPaginated(
  $limit: Int!
  $offset: Int!
  $sort: SimulationSortFields
  $filter: SimulationFilterFields
) {
  readSimulations(
    offset: $offset,
    limit: $limit,
    filter: $filter,
    sort: $sort,
  ) {
    pageInfo {
      totalCount
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        ${pseudoSimulationFragmentBase}
        Tags {
          nodes {
            ID
            Name
          }
        }
        OrganisationGroups {
          nodes {
            ID
            Name
          }
        }
      }
    }
  }
}

`;
// ${simulationDataFragments.simulationData}

export const EDIT_SIMULATION = gql`
  mutation updateSimulation($Input: UpdateSimulationInput!, $ID: ID!) {
    updateSimulation(input: $Input) {
      ${pseudoSimulationFragment}
    }
  }
`;

export const UPDATE_SIMULATION_GROUPS = gql`
  mutation updateSimulationGroups($ID: ID!, $GroupIDs: [ID]!) {
    updateSimulationGroups(SimulationID: $ID, GroupIDs: $GroupIDs) {
      ID
      OrganisationGroups {
        nodes {
          ID
        }
      }
    }
  }
`;

export const EDIT_SIMULATION_GROUPS = gql`
  mutation updateSimulation($Input: UpdateSimulationInput!, $ID: ID!) {
    updateSimulation(input: $Input) {
      ${pseudoSimulationFragment}
      OrganisationGroups {
        nodes {
          ID
        }
      }
    }
  }
`;
// ${simulationDataFragments.simulationData}

export const READ_SIMULATION_UNITS = gql`
  query readSimulationUnits($ID: ID!) {
    readOneSimulation(filter: { ID: { eq: $ID } }) {
      ID
      Units {
        nodes {
          ID
          Module {
            ID
            Title
            Course {
              ID
              Title
            }
          }
        }
      }
    }
  }
`;

export const READ_SIMULATION = gql`
  query readOneBigSimulation($ID: ID!) {
    readOneSimulation(filter: { ID: { eq: $ID } }) {
      ${pseudoSimulationFragment}
      ShareToken
      ShowRegister
      Tags {
        nodes {
          ID
          Name
        }
      }
      OrganisationGroups {
        nodes {
          ID
        }
      }
      Videos(sort: {SortOrder: ASC}) {
        nodes {
          ${pseudoVideoFragment}
        }
      }
    }
  }
`;

export const READ_SIMULATION_SCORES = gql`
  query readSimulationScores($ID: ID!) {
    readOneSimulation(filter: { ID: { eq: $ID } }) {
      ID
      Quiz
      allScores: Scores(sort: {Points: DESC}) {
        edges {
          node {
            ${pseudoScoreFragment}
          }
        }
      }
    }
  }
`;

export const READ_SIMULATION_SESSIONS = gql`
  query readSimulationSessions($ID: ID!) {
    readOneSimulation(filter: { ID: { eq: $ID } }) {
      ID
      Quiz
      allScores: AnalyticsSubmissions(filter: { Finished: { eq: true } }, sort: {Created: DESC}) {
        edges {
          node {
            ${pseudoScoreFragment}
          }
        }
      }
    }
  }
`;

export const READ_TRANSCODING_STATUS = gql`
  query readTranscodingStatus($ID: ID!) {
    readOneVideo(filter: { ID: { eq: $ID } }) {
      ID
      TranscodingStatus
    }
  }
`;

export const DELETE_SIMULATIONS = gql`
  mutation ($IDs: [ID]!) {
    deleteSimulations(ids: $IDs)
  }
`;

export const ADD_SIMULATION = gql`
  mutation createSimulationFromVideoMedia($VideoID: ID!) {
    createSimulationFromVideoMedia(VideoMediaID: $VideoID) {
      ID
    }
  }
`;

export const ADD_SIMULATION_FROM_VIDEO_URL = gql`
  mutation createSimulationFromURL(
    $URL: String!
    $Filename: String!
    $TusID: String!
    $organisationID: ID!
    $Content360: Boolean!
  ) {
    createSimulationFromURL(
      URL: $URL
      Filename: $Filename
      TusID: $TusID
      OrganisationID: $organisationID
      Content360: $Content360
    ) {
      ID
    }
  }
`;

// also used for hotspot videos
export const ADD_SIMULATION_VIDEO = gql`
  mutation createVideoMediaFromURL(
    $URL: String!
    $Filename: String!
    $TusID: String!
    $SimulationID: ID
    $OrganisationID: ID!
    $Content360: Boolean!
  ) {
    createVideoMediaFromURL(
      URL:$URL
      Filename:$Filename
      TusID: $TusID
      SimulationID: $SimulationID
      OrganisationID: $OrganisationID
      Content360: $Content360
    ) {
      ${pseudoVideoMediaFragment}
    }
  }
`;

export const USER_SIMULATION_ANALYTICS = gql`
query userSimulationAnalytics($SimulationID: ID!, $UserID: ID!){
  userSimulationAnalytics(SimulationID: $SimulationID, UserID: $UserID) {
    TotalCompletions
    AverageTimeWatched
    TotalViews
    AverageScore
    AverageReactionTime
    AverageScoreFirst
    AverageScoreLast
    DailyTotalViewsDays
    DailyTotalViewsViews
    DailyTotalCompletedSessions
    SimulationScores {
      ${pseudoScoreFragment}
    }
  }
}
`;

export const REMOVE_VIDEO_FROM_SIMULATION = gql`
  mutation removeVideoFromSimulation($SimulationID: ID!, $VideoID: ID!) {
    removeVideoFromSimulation(SimulationID: $SimulationID, VideoID: $VideoID) {
      ID
      Videos(sort: { SortOrder: ASC }) {
        nodes {
          ID
        }
      }
    }
  }
`;

export const SET_START_VIDEO = gql`
  mutation setStartVideo($SimulationID: ID!, $VideoID: ID!) {
    updateSimulation(input: { ID: $SimulationID, StartVideoID: $VideoID }) {
      ID
      StartVideoID
    }
  }
`;

export const READ_ANALYTICS_SUBMISSION = gql`
  query readSimulationLogs($ID: ID!, $limit: Int, $offset: Int) {
    readOneAnalyticsSubmission(filter: { ID: { eq: $ID } }) {
      ID
      TimeSpent
      Created
      LastEdited
      Score
      Points
      Finished
      Simulation {
        ID
        Title
        Quiz
      }
      Member {
        ID
        Name
      }
      AnalyticsEvents(
        sort: { Created: ASC, ID: ASC }
        limit: $limit
        offset: $offset
      ) {
        pageInfo {
          totalCount
        }
        nodes {
          ID
          Type
          Finished
          Created
          Video {
            Title
          }
          TimeSpent
          Option {
            ID
            Text
            Description
            Feedback
            Correct
            Points
            Action
            AnswerCount
          }
          Options {
            nodes {
              ID
              Text
              Description
              Feedback
              Correct
              Points
              Action
              AnswerCount
            }
          }
          Event {
            ID
            Title
            VideoDownloadLowURL
            AudioURL
            Type
            Action
            Description
            Label
            Timestamp
            VideoTimestamp
            QuestionType
            Options {
              nodes {
                ID
                Text
                Description
                Feedback
                Correct
                Points
                Action
                AnswerCount
              }
            }
          }
        }
      }
    }
  }
`;
