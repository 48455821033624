import { gql } from '@apollo/client';

export const CREATE_PUBLIC_TOKEN = gql`
  mutation publicToken {
    createPublicToken {
      Token
      PDToken
      Version
      Message
      Organisations {
        ID
      }
      Member {
        ID
        Email
        Name
        FirstName
        Surname
        UserType
        AvatarMedia {
          URL
          TusID
        }
      }
    }
  }
`;
