import { ButtonGroup, TextInput } from '@virtidev/toolbox';
import { useEffect, useMemo, useState } from 'react';
import { useDebouncedSave } from '../../../form/hooks/useDebouncedSave';
import Label from '../../../form/Label';
import { SelectGroup } from '../../../form/Select';
import SimulationList from '../SimulationList/SimulationList';
import VirtualHumanList from '../VirtualHumanList/VirtualHumanList';
import { FiltersContainer } from './PreviewList.styled';

const buttonLabels = ['Interactive Videos', 'Virtual Humans'];

export const PreviewList = ({ setIntercomTarget }) => {
  const [typedFilter, setTypedFilter] = useState('');
  const [groupFilter, setGroupFilter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('Interactive Videos');

  const debouncedProps = useDebouncedSave(typedFilter, {
    onUpdate: setTypedFilter,
    enter: true,
  });

  const [ListComponent, name] = useMemo(() => {
    if (type === 'Virtual Humans') {
      return [VirtualHumanList, 'virtual humans'];
    }

    return [SimulationList, 'interactive videos'];
  }, [type]);

  useEffect(() => {
    setIntercomTarget(
      type === 'Virtual Humans' ? 'pick-course-vh-box' : 'pick-course-sims-box'
    );
  }, [type, setIntercomTarget]);
  return (
    <>
      <FiltersContainer data-intercom-target="course-add-unit">
        <div>
          <Label>Drag and drop {name} to the course structure.</Label>
          <ButtonGroup labels={buttonLabels} size="small" onChange={setType} />
        </div>
        <SelectGroup
          value={groupFilter}
          onChange={setGroupFilter}
          placeholder="Filter by group..."
          disabled={loading}
          clearable
        />
        <TextInput
          placeholder="Search..."
          disabled={loading}
          {...debouncedProps}
        />
      </FiltersContainer>
      <ListComponent
        filter={typedFilter}
        groupId={groupFilter?.value}
        setLoading={setLoading}
      />
    </>
  );
};

export default PreviewList;
