import {
  Container,
  Splash,
  FormContainer,
  Heading,
  Gap,
  MessageContainer,
  ErrorPoint,
} from './TotaraRegistration.styled';
import { Redirect, useHistory } from 'react-router-dom';
import illustration from './images/bauhaus-illustration.svg';
import { useCallback, useMemo } from 'react';
import { Image } from '@virtidev/toolbox';
import maudsley from './images/maudsley-learning.png';
import { useMutation, useQuery } from '@apollo/client';
import { CHECK_EMAIL, TOTARA_REGISTRATION } from './TotaraRegistration.query';
import { LOGIN } from '../../../../../queries/UserQueries';
import { StoreLogin } from '../../../../../utility/LoginUtility';
import { setSecondarySidebarContentVar } from '../../../../../ApolloReactiveVars';
import useIntercomHelpers from '../../helpers/useIntercomHelpers';
import TotaraRegistrationForm from './components/TotaraRegistrationForm';
import useUser from '../../helpers/useUser';

export const TotaraRegistration = ({ defaultValues, simulationID }) => {
  const intercom = useIntercomHelpers();
  const history = useHistory();
  const { ID } = useUser();

  const [
    registerTotara,
    { data: registeredCheck, loading: registerLoading, error: registerError },
  ] = useMutation(TOTARA_REGISTRATION);

  const [login, { loading: loginLoading, error: loginError }] =
    useMutation(LOGIN);

  const { data: emailExists } = useQuery(CHECK_EMAIL, {
    variables: {
      Email: defaultValues?.Email,
    },
    skip: !defaultValues?.Email || ID,
  });

  const handleSubmit = useCallback(
    async (input) => {
      try {
        const { data: registerData } = await registerTotara({
          variables: input,
        });

        const { data: loginData } = await login({
          variables: {
            Email: input.Email,
            Password: input.Password,
          },
        });

        const { Token, PDToken } = loginData?.createVirtiToken || {};

        StoreLogin(Token, PDToken, registerData.signupTotaraUser);

        intercom.bootIntercom();
        intercom.setUserData();
        setSecondarySidebarContentVar(null);

        if (simulationID) {
          history.replace(`/my-simulations/${simulationID}`);
          return;
        }
        history.replace('/home');
      } catch (e) {
        console.error(e);
      }
    },
    [registerTotara, intercom, login, history, simulationID]
  );

  const loading = loginLoading || registerLoading;

  const loadingMessage = useMemo(() => {
    if (registerLoading) {
      return 'Registering account...';
    }
    if (loginLoading) {
      return 'Logging in to account...';
    }
    return null;
  }, [loginLoading, registerLoading]);

  // if user is already logged in
  if (ID) {
    const url = simulationID ? `/my-simulations/${simulationID}` : '/';

    return <Redirect to={url} />;
  }

  // don't display until email check is done
  if (emailExists == null) {
    return null;
  }

  // account already exists, redirect to login
  if (emailExists.checkEmail) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: {
            errorMessage: 'Your account already exists, please log in.',
            prevPath: `/my-simulations/${simulationID}`,
          },
        }}
      />
    );
  }

  return (
    <Container>
      <Splash src={illustration} alt="" />
      <FormContainer>
        <Heading>
          <Image src={maudsley} /> Register with Virti!
        </Heading>
        <Gap>{loadingMessage}</Gap>
        {(registerError || loginError) && (
          <MessageContainer>
            Something went wrong, reason reported is:
            {registerError && (
              <ErrorPoint>
                {registerError?.message || 'Unknown error found.'}
              </ErrorPoint>
            )}
            {loginError && (
              <ErrorPoint>
                {loginError?.message || 'Unknown error found.'}
              </ErrorPoint>
            )}
          </MessageContainer>
        )}
        {defaultValues && (
          <TotaraRegistrationForm
            defaultValues={defaultValues}
            onSubmit={handleSubmit}
            disablePrompt={registeredCheck}
            loading={loading}
          />
        )}
      </FormContainer>
    </Container>
  );
};

export default TotaraRegistration;
