import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import RedirectWithPrevState from '../components/RedirectWithPrevState';
import VirtualHumanTabNav from '../components/VirtualHumanTabNav';
import WithVirtualHumanPageTemplate from '../HOCs/templates/WithVirtualHumanPageTemplate';
import VirtualHumanManager from '../apps/core/src/components/VirtualHumanManager/VirtualHumanManager';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import useIntercomHelpers from '../apps/core/src/helpers/useIntercomHelpers';
import { useFeature } from '../apps/core/src/components/LaunchDarkly';

const PageContent = styled.div`
  width: 100%;
  height: 100%;
  > iframe {
    width: 100%;
    height: 100%;
  }
`;

const VirtualHumanPreviewPage = (props) => {
  const { ssVirtualHumanID } = useParams();
  const intercom = useIntercomHelpers();
  const vhBigUiFeature = useFeature('vh-big-ui');

  const handleStartSurvey = useCallback(() => {
    intercom.startSurvey(38784095);
    localStorage.setItem('seen_intercom_survey_38784095', 'true');
  }, [intercom]);

  useEffect(() => {
    window.document.addEventListener(
      'showAIGenerationSurvey',
      handleStartSurvey,
      false
    );
    return () => {
      window.document.removeEventListener(
        'showAIGenerationSurvey',
        handleStartSurvey,
        false
      );
    };
  }, [handleStartSurvey]);

  if (!props.userID) {
    return <RedirectWithPrevState to="/login" />;
  }
  return (
    <>
      <VirtualHumanTabNav virtualHumanData={props.data.readOneVirtualHuman} />
      <PageContent>
        <VirtualHumanManager
          virtualHumanId={ssVirtualHumanID}
          isPreviewPlayer={true}
        />
      </PageContent>
    </>
  );
};

export default WithVirtualHumanPageTemplate(
  VirtualHumanPreviewPage,
  undefined,
  false,
  true
);

VirtualHumanPreviewPage.propTypes = {
  userID: PropTypes.number,
  data: PropTypes.object.isRequired,
};
