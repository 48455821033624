import React, { FC, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter, useLocation, NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { CanSeeUserAdminSwitch } from '@base/utility/Permissions';
import { NavMenu } from '@core/components/NavMenu/NavMenu';
import { useReactiveVar } from '@apollo/client';
import {
  showOnboardDialogueVar,
  showOnboardProgressVar,
  userModeVar,
} from '@base/ApolloReactiveVars';
import NavDropdown from '@base/components/NavDropdown';
import useUser, { withUser } from '@core/helpers/useUser';
import { getResizeImage } from '@core/helpers/imageResizing/getResizeImage';
import fallbackAvatar from '@base/components/icons/avatar-default.jpeg';

const getScrollbarWidth = () => {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
};

const scrollbarWidth = getScrollbarWidth();

const StyledNavBar = styled.div`
  position: fixed;
  top: ${(props) => {
    if (props.dialogueBarExists && props.featureUpdateExists) {
      return '13.4rem';
    } else if (props.dialogueBarExists || props.featureUpdateExists) {
      return '6.7rem';
    } else {
      return '0';
    }
  }};
  right: ${(props) => (props.scrollGap ? `${scrollbarWidth}px` : 0)};
  background-color: var(--primary-bg-color);
  height: var(--nav-bar-height);
  display: flex;
  justify-content: flex-end;
  z-index: 1004;
  display: flex;
  align-items: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s linear;
  ${({ atTop, dialogueBarExists }) =>
    atTop &&
    !dialogueBarExists &&
    css`
      box-shadow: none;
    `}
  border-bottom-left-radius: 24px;
  /* will need to multiply sizes by 1.25 to get the proper sizes */
  transform: scale(0.8) translate(12.5%, -12.5%);
  padding-right: 0.8rem;
`;

const StyledUserFullName = styled.div`
  font-weight: 500;
  color: #000;
  font-size: 1rem;
  margin: 0 1rem;
  width: auto;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledAvatar = styled.img`
  border-radius: 20rem;
  width: 2.5rem;
  height: 2.5rem;
  background: #ccc;
`;

const StyledUserViewLink = styled(NavLink)`
  margin-left: 11px;
  :hover {
    color: var(--primary-color);
  }
`;

/**
 * @type {FC<{
 *    pageKey?: string
 * }>}
 */
const NavBar = (props) => {
  const [atTop, setAtTop] = useState(true);
  const { pathname } = useLocation();
  const userMode = useReactiveVar(userModeVar);
  const { AvatarMedia } = useUser();

  const showOnboardDialogue = useReactiveVar(showOnboardDialogueVar);
  const showOnboardProgress = useReactiveVar(showOnboardProgressVar);

  useEffect(() => {
    const checkAtTop = (event) => {
      const top = window.pageYOffset || document.documentElement.scrollTop;

      setAtTop(!top);
    };
    document.addEventListener('scroll', checkAtTop, { passive: true });
    checkAtTop();

    return () =>
      document.removeEventListener('scroll', checkAtTop, { passive: true });
  }, [setAtTop]);

  const avatarSrc = useMemo(() => {
    if (AvatarMedia?.TusID) {
      return getResizeImage(AvatarMedia?.TusID, {
        resize: {
          width: 50,
          height: 50,
        },
      });
    } else {
      return fallbackAvatar;
    }
  }, [AvatarMedia?.TusID]);

  return (
    <StyledNavBar
      className="virti-navbar"
      atTop={atTop}
      scrollGap={props.scrollGap}
      dialogueBarExists={
        showOnboardDialogue || (showOnboardProgress && pathname === '/')
      }
    >
      <NavDropdown />
      {CanSeeUserAdminSwitch() ? (
        <StyledUserViewLink to={userMode === 'admin' ? '/home' : '/'} exact>
          View as {userMode === 'admin' ? 'User' : 'Admin'}
        </StyledUserViewLink>
      ) : null}
      <NavMenu
        userName={
          <StyledUserFullName title={props.user.Name}>
            {props.user.Name}
          </StyledUserFullName>
        }
        avatar={<StyledAvatar alt="Avatar Image" src={avatarSrc} />}
      />
    </StyledNavBar>
  );
};

NavBar.propTypes = {
  user: PropTypes.object,
};

export default withRouter(withUser(NavBar));
