import { gql } from '@apollo/client';

export const COURSE_AUTHOR_FRAGMENT = gql`
  fragment course on Course {
    ID
    Author {
      ID
    }
  }
`;

export const SIMULATION_AUTHOR_FRAGMENT = gql`
  fragment simulation on Simulation {
    ID
    Author {
      ID
    }
    Editors {
      nodes {
        ID
      }
    }
  }
`;

export const SIMULATION_AUTHOR = gql`
  query simulationAuthor($ID: ID!) {
    readOneSimulation(filter: { ID: { eq: $ID } }) {
      ID
      Author {
        ID
      }
      Editors {
        nodes {
          ID
        }
      }
    }
  }
`;
