import {
  Button,
  Datetime,
  HelpTooltip,
  Loading,
  Modal,
} from '@virtidev/toolbox';
import React, { useEffect, useMemo, useState } from 'react';
import FieldContainer from '../../../form/FieldContainer';
import Label from '../../../form/Label';
import { DateContainer } from '../../CourseForm.styled';
import { CardTitle } from '../Card';
import DatePicker from '../../../form/Datepicker';
import ControlledToggleSwitch from '../../../form/ToggleSwitch/ControlledToggleSwitch';
import FieldNote from '../../../form/FieldNote';
import CourseAccessGroup from '../CourseAccessGroup/CourseAccessGroup';
import { addDays, endOfDay, parseISO, startOfDay } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import cleanupAccessData from './helpers/cleanupAccessData';
import rebuildAccessData from './helpers/rebuildAccessData';
import { AccessContainer } from './AccessModal.styled';
import {
  READ_GROUP_USERS_COUNT,
  UPDATE_COURSE,
  UPDATE_COURSE_GROUPS,
} from './AccessModal.query';
import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import { SaveBarContent } from '../DetailsForm/DetailsForm.styled';
import AutosaveIndicator from '../../../form/AutosaveIndicator/AutosaveIndicator';
import { zonedTimeToUtc } from 'date-fns-tz';

export const AccessModal = ({ show, onHide, course }) => {
  const formMethods = useForm();
  const [loading, setLoading] = useState(false);
  const [initialised, setInitialised] = useState(false);

  const [updateCourse] = useMutation(UPDATE_COURSE);

  const [updateCourseGroups] = useMutation(UPDATE_COURSE_GROUPS);

  const { reset, handleSubmit: submit, watch, formState } = formMethods;

  const [rawStartDate, rawDueDate, GroupIDs, notifyUsers] = watch([
    'StartDate',
    'DueDate',
    'GroupIDs',
    'NotifyUsers',
  ]);

  const { PublishNotificationSent, PublishNotificationCount } = course || {};

  const groupIds = useMemo(
    () => (GroupIDs || []).map(({ ID }) => ID),
    [GroupIDs]
  );

  const { data: groupData, loading: groupLoading } = useQuery(
    READ_GROUP_USERS_COUNT,
    {
      variables: {
        GroupIDs: groupIds,
      },
      skip: !groupIds?.length || !show || !notifyUsers,
    }
  );

  const [StartDate, DueDate] = useMemo(
    () => [parseISO(rawStartDate), parseISO(rawDueDate)],
    [rawStartDate, rawDueDate]
  );

  const groupCount = useMemo(() => {
    const count = groupData?.readMembers?.pageInfo?.totalCount;

    if (count == null) {
      return '';
    }

    return count;
  }, [groupData]);

  const published = useMemo(() => {
    if (!rawStartDate) {
      return false;
    }
    const startPublished = startOfDay(StartDate) <= endOfDay(new Date());

    return startPublished;
  }, [rawStartDate, StartDate]);

  const notifyMessage = useMemo(() => {
    if (PublishNotificationSent) {
      return (
        <>
          A notification was sent to {PublishNotificationCount} users on{' '}
          <Datetime datetime={PublishNotificationSent} long />.
        </>
      );
    }
    if (groupLoading) {
      return <Loading inline size="small" />;
    }
    const startUtc = startOfDay(zonedTimeToUtc(new Date(), 'UTC'));
    if (
      !rawStartDate ||
      !groupCount ||
      startOfDay(StartDate) < addDays(startUtc, -2)
    ) {
      return null;
    }

    if (StartDate >= startUtc) {
      return (
        <>
          {groupCount} users will be notified of this course at midnight UTC
          time, which is at <Datetime datetime={startUtc} long />.
        </>
      );
    }
    return `${groupCount} users will be notified of this course as soon as we can.`;
  }, [
    groupLoading,
    groupCount,
    StartDate,
    rawStartDate,
    PublishNotificationSent,
    PublishNotificationCount,
  ]);

  const handleSubmit = useMemo(() => {
    return submit(async (Input) => {
      setLoading(true);
      // update groups first
      await updateCourseGroups({
        variables: {
          GroupIDs: Input.GroupIDs.map(({ ID }) => ID),
          CourseID: Input.ID,
        },
      });
      // update course data and retrieve groups data as well
      const { data: courseData } = await updateCourse({
        variables: {
          Input: _.omit(Input, ['GroupIDs']),
        },
        optimisticResponse: {
          updateCourse: rebuildAccessData(Input),
        },
      });

      // setup the new data from server back to form
      const newCourse = cleanupAccessData(courseData.updateCourse);
      reset(newCourse);
      setLoading(false);
    });
  }, [submit, reset, updateCourse, updateCourseGroups]);

  useEffect(() => {
    // reset form when hiding modal
    if (!show) {
      setInitialised(false);
      return;
    }
    if (course && !initialised) {
      const cleanData = cleanupAccessData(course);
      reset(cleanData);
      setInitialised(true);
    }
  }, [show, reset, initialised, course]);

  return (
    <Modal
      title="Manage course access"
      show={show}
      onHide={onHide}
      render={() => (
        <FormProvider {...formMethods}>
          <AccessContainer onSubmit={handleSubmit}>
            <CardTitle>Access</CardTitle>
            <DateContainer>
              <FieldContainer>
                <Label>Course start</Label>
                <DatePicker
                  name="StartDate"
                  maxDate={DueDate}
                  disabled={!initialised}
                  isClearable
                  clearButtonClassName="date-picker-clear-button"
                />
              </FieldContainer>
              <FieldContainer>
                <Label>Course end</Label>
                <DatePicker
                  name="DueDate"
                  minDate={StartDate}
                  disabled={!initialised}
                />
                {rawDueDate && (
                  <FieldNote>
                    This is a date for reminders, this course will continue to
                    appear after this date.
                  </FieldNote>
                )}
              </FieldContainer>
            </DateContainer>
            <FieldContainer data-intercom-target="course-unlock-simulations">
              <ControlledToggleSwitch
                name="Unlocked"
                label="Unlock all units"
                disabled={!initialised}
              />
            </FieldContainer>
            <FieldContainer data-intercom-target="edit-course-access-groups">
              <Label>User Groups</Label>
              <CourseAccessGroup
                name="GroupIDs"
                courseId={course?.ID}
                loading={!initialised}
              />
            </FieldContainer>
            <FieldContainer>
              <ControlledToggleSwitch
                name="NotifyUsers"
                label={
                  <>
                    Notify Learners when available{' '}
                    <HelpTooltip>
                      Notifications will be sent in the form of email, in app
                      notification or SMS depending on a user's preference or
                      organisation's settings.
                    </HelpTooltip>
                  </>
                }
                disabled={!initialised}
              />
              {notifyUsers && groupIds.length > 0 && (
                <FieldNote>{notifyMessage}</FieldNote>
              )}
            </FieldContainer>
          </AccessContainer>
        </FormProvider>
      )}
      footer={
        <SaveBarContent>
          <AutosaveIndicator dirty={formState.isDirty} loading={loading} />
          <Button color="turquoise" onClick={handleSubmit}>
            {published ? 'Save & Publish' : 'Save'}
          </Button>
        </SaveBarContent>
      }
    />
  );
};

export default AccessModal;
