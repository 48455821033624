import { useQuery } from '@apollo/client';
import PageLoadError from '@base/components/PageLoadError';
import LoadingSplash from '@core/components/VirtiApolloProvider/components/LoadingSplash';
import usePublicToken from '@core/helpers/usePublicToken';
import { GET_VH_TOKEN } from '@core/pages/admin/virtual-humans/VHWebPlayerPagePublic/VHWebPlayerPagePublicRedirect.query';
import { Redirect, useParams } from 'react-router-dom';

export const VHWebPlayerPagePublicRedirect = () => {
  const { data: publicToken } = usePublicToken();
  const { ssVirtualHumanID } = useParams();

  const { data, loading, error } = useQuery(GET_VH_TOKEN, {
    variables: { id: ssVirtualHumanID },
    skip: !ssVirtualHumanID || !publicToken,
  });

  if (error || !ssVirtualHumanID || (data && !data?.readOneVirtualHuman)) {
    return (
      <PageLoadError
        graphQLErrorObj={error}
        message={
          !ssVirtualHumanID || (data && !data?.readOneVirtualHuman)
            ? 'We could not find an ID to load.'
            : ''
        }
      />
    );
  }

  return (
    <>
      <LoadingSplash key="loading" show={loading} />
      {data?.readOneVirtualHuman && (
        <Redirect
          to={`/embed/my-virtual-humans/${data?.readOneVirtualHuman?.ShareToken}`}
        />
      )}
    </>
  );
};

export default VHWebPlayerPagePublicRedirect;
