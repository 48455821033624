import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 90%;
  gap: var(--content-padding);
  flex: 1;
  padding-bottom: var(--content-padding);
`;

export const ContentBody = styled.div`
  flex: 1;
`;

export const CoursesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: var(--content-padding);
`;

export const PagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const EmptyCoursesCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 540px;
  max-width: 100%;
  margin: 0 auto;
  align-self: center;
  border-radius: var(--card-border-radius);
  background-color: var(--card-bg-color);
  box-shadow: var(--card-box-shadow);
  padding: var(--content-padding);
`;
