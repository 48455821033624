import { useMutation } from '@apollo/client';
import { Button, Icon } from '@virtidev/toolbox';
import { produce } from 'immer';
import React, { useCallback, useEffect, useMemo } from 'react';
import { READ_FEEDBACK_FORM } from '../../../../../../../queries/FeedbackFormQueries';
import { Card } from '../../../CourseForm/components/Card';

import {
  CREATE_FEEDBACK_PAGE,
  DELETE_FEEDBACK_PAGE,
  FEEDBACK_FORM_FRAGMENT,
} from './EditPages.query';
import { DeleteIcon, PageButton, PageList } from './EditPages.styled';
import EditPageTitle from './EditPageTitle/EditPageTitle';

export default function EditPages({
  feedbackForm,
  activePage,
  setActivePage,
  setLoading,
}) {
  const pages = useMemo(() => {
    if (feedbackForm) {
      return feedbackForm.Pages.nodes;
    }
    return null;
  }, [feedbackForm]);

  useEffect(() => {
    if (pages && !activePage) {
      setActivePage(pages[0]);
    }
  });

  const [createFeedbackPage] = useMutation(CREATE_FEEDBACK_PAGE, {
    onCompleted: () => {
      setLoading(false);
    },

    update: (cache, { data }) => {
      const cacheData = cache.readQuery({
        query: READ_FEEDBACK_FORM,
        variables: {
          ID: feedbackForm.ID,
        },
      });

      const addition = { ...data.createFeedbackPage, Questions: [] };

      const newData = produce(cacheData, (state) => {
        if (state?.readOneFeedbackForm) {
          state.readOneFeedbackForm.Pages.nodes = [
            ...state.readOneFeedbackForm.Pages.nodes,
            addition,
          ];
        }
      });
      cache.writeQuery({
        query: READ_FEEDBACK_FORM,
        variables: {
          ID: feedbackForm.ID,
        },
        data: newData,
      });
    },
  });

  const handleClick = useCallback(() => {
    setLoading(true);
    createFeedbackPage({
      variables: {
        input: {
          Title: 'New Page',
          FeedbackFormID: feedbackForm.ID,
          SortOrder: pages.length,
        },
      },
      update: (cache, { data: pageData }) => {
        const fragment = cache.readFragment({
          fragment: FEEDBACK_FORM_FRAGMENT,
          id: `FeedbackForm:${feedbackForm.ID}`,
        });

        const newData = produce(fragment, (state) => {
          state.Pages.nodes = [
            ...state.Pages.nodes,
            pageData.createFeedbackPage,
          ];
        });

        cache.writeFragment({
          fragment: FEEDBACK_FORM_FRAGMENT,
          id: `FeedbackForm:${feedbackForm.ID}`,
          data: newData,
        });
      },
      // the SortOrder breaks things....
      // optimisticResponse: {
      //   createFeedbackPage: {
      //     ID: ID,
      //     Title: 'New Page',
      //     FeedbackFormID: feedbackForm.ID,
      //     SortOrder: pages.length,
      //   },
      // },
    });
  }, [createFeedbackPage, feedbackForm?.ID, pages, setLoading]);

  const [deletePage] = useMutation(DELETE_FEEDBACK_PAGE, {
    onCompleted: () => {
      setLoading(false);
    },
  });

  const handleDelete = (ID) => {
    setLoading(true);
    deletePage({
      variables: {
        ids: [ID],
      },
      optimisticResponse: {
        deleteFeedbackPages: [],
      },
      update: (cache, { data }) => {
        const cacheData = cache.readQuery({
          query: READ_FEEDBACK_FORM,
          variables: {
            ID: feedbackForm.ID,
          },
        });

        const newData = produce(cacheData, (state) => {
          if (state?.readOneFeedbackForm) {
            state.readOneFeedbackForm.Pages.nodes =
              state.readOneFeedbackForm.Pages.nodes.filter((page) => {
                return page.ID !== ID;
              });
          }
        });

        cache.writeQuery({
          query: READ_FEEDBACK_FORM,
          variables: {
            ID: feedbackForm.ID,
          },
          data: newData,
        });
      },
    });
  };

  return (
    <div>
      <Card>
        <PageList>
          {!pages?.length && 'Add a page to start creating your form...'}
          {pages &&
            pages.map((page) => {
              return (
                <PageButton
                  key={page.ID}
                  active={page.ID === activePage?.ID}
                  onClick={() => setActivePage(page)}
                >
                  {page.Title}
                  <DeleteIcon
                    icon="bin"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleDelete(page.ID);
                    }}
                  />
                </PageButton>
              );
            })}
          <Button
            onClick={handleClick}
            style={{ width: '20px', padding: '10px 15px' }}
            color="turquoise"
            title="Add page"
          >
            <Icon icon="plus" color="#fff" size="10px" />
          </Button>
        </PageList>
      </Card>
      {activePage && (
        <div style={{ marginTop: '20px' }}>
          <EditPageTitle
            key={activePage?.ID}
            activePage={activePage}
            setLoading={setLoading}
          />
        </div>
      )}
    </div>
  );
}
