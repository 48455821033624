import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import withUser from '../HOCs/WithUser';
import OnboardingTask from './OnboardingTask';

const slideAnimation = keyframes`
 0% { transform: translateY(-1rem) }

 100% { transform: translateY(0px) }
`;

const StyledTasks = styled.div`
  font-size: 0.9rem;
  border-radius: var(--card-border-radius);
  animation-name: ${slideAnimation};
  animation-duration: 0.3s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  border: 1px solid var(--neutral-color-lighter);
  background-color: var(--card-bg-color);
  border-radius: 25px;
`;

function OnboardingSection(props) {
  const [open, setOpen] = useState(props.startOpen);

  const shouldStartOpen = (index, completed) => {
    if (completed) {
      return false;
    }
    if (index === 0) {
      return true;
    } else if (index === 1 && props.tasks[0].Completed) {
      return true;
    } else if (index === 2 && props.tasks[1].Completed) {
      return true;
    }
  };

  return (
    <div>
      {open && (
        <StyledTasks
          className={props.className}
          data-intercom-target={`onboarding-section-${props.Title}`}
        >
          {props.tasks.map((task, index) => {
            return (
              <OnboardingTask
                key={`task:${task.Title}`}
                startOpen={() => shouldStartOpen(index, task.Completed)}
                index={index}
                task={task}
                data={props.data}
              />
            );
          })}
        </StyledTasks>
      )}
    </div>
  );
}

OnboardingSection.propTypes = {
  data: PropTypes.object.isRequired,
  tasks: PropTypes.array.isRequired,
  startOpen: PropTypes.bool,
};

export default withUser(OnboardingSection);
