import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { UPDATE_SIMULATION_GROUPS } from '../queries/SimulationQueries';
import withFlashMessaging from '../HOCs/WithFlashMessaging';
import ScrollableGroupCheckboxList from '../components/ScrollableGroupCheckboxList';
import withUser from '../HOCs/WithUser';
import SimulationEditors from '../apps/core/src/components/SimulationForm/components/VisibilityForm/components/SimulationEditors/SimulationEditors';
import Tooltip from '../apps/core/src/components/Tooltip/Tooltip';

const StyledGroupsInputWrapper = styled.div`
  padding: 1rem;
  border-top: var(--primary-border);
  margin-top: 2px;
`;

const EditorsSectionWrapper = styled.div`
  margin: 0.5rem 0rem 2rem 0rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const StyledLabel = styled.label`
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TooltipContent = styled.div`
  font-weight: 400;
  line-height: 1rem;
  font-size: 0.8rem;
`;

const GroupCheckboxesForSimulation = (props) => {
  return (
    <StyledGroupsInputWrapper data-intercom-target="sim-edit-access-component">
      <EditorsSectionWrapper>
        <StyledLabel>
          Give Editor Access
          <Tooltip icon="help">
            <TooltipContent>
              Users with the Type: <strong>Content Creator </strong>can be
              assigned simulations that they can then edit. They will be able to
              mark simulations as
              <strong> Ready to Publish</strong>, after which an admin can then
              set to
              <strong> Published.</strong>
            </TooltipContent>
          </Tooltip>
        </StyledLabel>
        <SimulationEditors
          simulationId={props.simulationID}
          usersData={props.dataObject.Editors}
          userType="contentcreator"
        />
      </EditorsSectionWrapper>

      <ScrollableGroupCheckboxList
        mainObjectID={props.simulationID}
        updateMutation={UPDATE_SIMULATION_GROUPS}
        type="simulation"
        showEveryoneCheckbox={true}
        checkedGroupIDs={props.checkedGroupIDs}
        // greyFormBG={true}
      />
    </StyledGroupsInputWrapper>
  );
};

GroupCheckboxesForSimulation.defaultProps = {
  simulationID: null,
};

GroupCheckboxesForSimulation.propTypes = {
  checkedGroupIDs: PropTypes.array.isRequired,
  userOrganisationID: PropTypes.number.isRequired,
  simulationID: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default withUser(withFlashMessaging(GroupCheckboxesForSimulation));
