import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './ObjectiveProgressCircle.styled';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const ObjectiveProgressCircle = ({
  percentageFraction,
  pathColor,
  trailColor,
}) => {
  return (
    <Styled.ListIconWrapper>
      <Styled.ListIconCircleWrapper>
        <CircularProgressbar
          styles={buildStyles({
            pathColor: pathColor ?? `var(--pd-primary-color)`,
            trailColor: trailColor ?? 'rgba(0,0,0,0)',
          })}
          value={percentageFraction * 100}
        />
      </Styled.ListIconCircleWrapper>
      <Styled.ListIcon />
    </Styled.ListIconWrapper>
  );
};

ObjectiveProgressCircle.propTypes = {
  percentageFraction: PropTypes.number.isRequired,
  pathColor: PropTypes.string,
  trailColor: PropTypes.string,
};

export default ObjectiveProgressCircle;
