import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './PillData.styled';

const PillData = (props) => {
  return <Styled.PillData>{props.children}</Styled.PillData>;
};

PillData.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PillData;
