import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import _ from 'lodash';
import { sendGetRequest } from '../services/PDService';
import * as Sentry from '@sentry/react';

export const getPatient = createAsyncThunk(
  'patient/getPatient',
  async (
    { ExternalID, ShareToken },
    { getState, dispatch, rejectWithValue }
  ) => {
    const response = await sendGetRequest('patient/' + ExternalID, ShareToken);
    if (response.data) {
      return response.data;
    }
    Sentry.captureException(
      new Error(
        'No data supplied from getPatient response: ' + JSON.stringify(response)
      )
    );
    rejectWithValue(null);
  }
);

const PatientSlice = createSlice({
  name: 'patient',
  initialState: {
    status: 'idle',
    begunFetchingPatient: false,
    patient: null,
    error: null,
  },
  reducers: {
    resetPatient: (state, action) => {
      state.status = 'idle';
      state.begunFetchingPatient = false;
      state.patient = null;
      state.currentState = null;
      state.error = null;
    },
  },
  extraReducers: {
    [getPatient.pending]: (state, action) => {
      state.status = 'pending';
      state.begunFetchingPatient = true;
    },
    [getPatient.rejected]: (state, action) => {
      state.status = 'rejected';
      state.error = 'Failed to connect.';
    },
    [getPatient.fulfilled]: (state, action) => {
      state.status = 'idle';
      state.patient = action.payload;
    },
  },
});

export const patientSelector = (state) => state.patient.patient;
// TODO: replace [0] with state selection setup once more states are in
export const statePropertiesSelector = createSelector(
  patientSelector,
  (patient) =>
    patient.states[0].properties.filter(
      (prop) => prop.isActive && !prop.isDeleted
    )
);
export const languageSelector = createSelector(
  patientSelector,
  (patient) => patient?.lang
);
export const investigationStatePropertiesSelector = createSelector(
  patientSelector,
  (patient) =>
    patient?.states[0].properties.filter(
      (prop) => prop.isInvestigation && prop.isActive && !prop.isDeleted
    )
);
export const examStatePropertiesSelector = createSelector(
  patientSelector,
  (patient) =>
    patient?.states[0].properties.filter(
      (prop) => !prop.isInvestigation && prop.isActive && !prop.isDeleted
    )
);
export const nonVitalsExamStatePropertiesSelector = createSelector(
  examStatePropertiesSelector,
  (stateProperties) =>
    stateProperties?.filter((prop) => prop.heading !== 'Vitals')
);
export const examsCountSelector = createSelector(
  examStatePropertiesSelector,
  (exams) => exams.length
);
export const nonVitalsParentExamCountSelector = createSelector(
  nonVitalsExamStatePropertiesSelector,
  (exams) =>
    exams && exams.length
      ? exams.filter((exam) => exam.parentId === null).length
      : 0
);
export const investigationsParentCountSelector = createSelector(
  investigationStatePropertiesSelector,
  (investigations) =>
    investigations?.filter((investigation) => investigation.parentId === null)
      .length
);

export const statesSelector = createSelector(patientSelector, (patient) => {
  return patient?.patient?.states ?? [];
});

export const examObjectivesSelector = (state) => {
  return state?.patient?.patient?.examObjectives || [];
};
export const objectivesSelector = (state) => {
  return (
    state?.patient?.patient?.objectiveGroups?.reduce((carry, objGroup) => {
      return [...carry, ...objGroup.objectives];
    }, []) ?? []
  );
};
export const objectiveGroupsSelector = (state) => {
  return state?.patient?.patient?.objectiveGroups ?? [];
};
export const objectiveGroupsWithObjectivesAndPointsSelector = createSelector(
  objectiveGroupsSelector,
  (objGroups) =>
    objGroups.filter(
      (objGroup) =>
        objGroup.objectives.length > 0 &&
        objGroup.objectives.some(
          (obj) => obj.scoreMetadata?.points && obj.scoreMetadata.points > 0
        )
    )
);
export const objectiveGroupsWithHintsSelector = createSelector(
  objectiveGroupsSelector,
  (objGroups) =>
    objGroups.filter(
      (objGroup) =>
        objGroup.objectives.length > 0 &&
        objGroup.objectives.some(
          (obj) =>
            obj.scoreMetadata?.points &&
            obj.scoreMetadata.points > 0 &&
            !!obj.hint
        )
    )
);
export const patientStatusSelector = (state) => state.patient?.status;

// Destructure and export the plain action creators
export const { resetPatient } = PatientSlice.actions;
export default PatientSlice;
