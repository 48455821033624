import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import withUser from '../HOCs/WithUser';
import StyledPage, {
  StyledPageContentInner,
  StyledTopArea,
} from '../components/StyledPage';
import StyledPageHeader from '../styled-components/StyledPageHeader';
import {
  StyledCards,
  StyledCard,
  StyledProgressBar,
  StyledCardTitle,
  StyledIconWrapper,
  StyledIcon,
  StyledResetMessageBlock,
  StyledProgressTitle,
  StyledOnboardingResetButton,
  StyledCardBody,
  StyledGreenBorder,
  StyledCompletedIconsWrapper,
} from '../styled-components/StyledOnboardingDashboard';

import WithOnboardingHandler from '../HOCs/WithOnboardingHandler';
import Icon from '../components/icons/Icon';
import {
  showOnboardDialogueVar,
  setShowOnboardDialogueVar,
  sidebarContractedVar,
} from '../ApolloReactiveVars';
import Button from '../components/buttons/Button';
import WithConfirmationBox from '../HOCs/WithConfirmationBox';
import LoadingPageIndicator from '../components/LoadingPageIndicator';
import { useEffectOnlyOnce } from '../utility/CustomHooks';
import LoadingIndicator from '../components/LoadingIndicator';
import { useReactiveVar } from '@apollo/client';
import OnboardingSection from '../components/OnboardingSection';
import OnboardingSkipSectionButton from '../components/OnboardingSkipSectionButton';
import NavMenu from '../apps/core/src/components/NavMenu/NavMenu';
import {
  DropdownLinkContent,
  DropdownLinksWrapper,
  DropdownOnClickLink,
} from '../components/DropdownLinks';
import OnboardingSettings from '../components/OnboardingSettings';
import OnboardingCompletedScreen from '../components/OnboardingCompletedScreen';
import OnboardingDismissedMessage from '../components/OnboardingDismissedMessage';
import { CanViewOnboarding } from '../utility/Permissions';
import _ from 'lodash';

const StyledOnboardingPageContentInner = styled(StyledPageContentInner)`
  ${'' /* padding: 2rem 5rem 2rem 4rem; */}
`;

function OnboardingDashboard(props) {
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);

  const sidebarContracted = useReactiveVar(sidebarContractedVar);

  const handleCardClick = (cardIndex) => {
    setSelectedSectionIndex(cardIndex);

    setTimeout(() => {
      const section = document.querySelector('.onboarding-section');
      if (section) {
        // Safari cannot smooth scroll, it will snap to position
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }, 250);
  };

  useEffectOnlyOnce(() => {
    setShowOnboardDialogueVar(false);
  });

  useEffectOnlyOnce(() => {
    if (props.parsedData && !props.parsedData.redirectedToOnboarding) {
      props.setRedirectedToOnboarding();
    }
  });

  // I don't want to add this as a data field atm as icon names are probably going to change.
  const getCardIcon = (sectionTitle) => {
    switch (sectionTitle) {
      case 'User Setup':
        return 'Profile';
      case 'Add a Course':
        return 'Course';
      case 'Create a Form':
        return 'Form';
      case 'Upload a Video':
        return 'Video';
      case 'Create a Simulation':
        return 'Sim';
      default:
        return 'blob';
    }
  };

  useEffect(() => {
    const startSection =
      props.onboardingData &&
      props.onboardingData.reduce((acc, section, index) => {
        if (section.Progress === 100) {
          if (index < props.onboardingData.length) {
            acc = index + 1;
          } else {
            acc = 0;
          }
        }
        return acc;
      }, 0);

    if (props && props.onboardingData && props.onboardingData[startSection]) {
      setSelectedSectionIndex(startSection);
    }
  }, [props]);

  useEffect(() => {
    if (props.totalProgress === 100) {
      setTimeout(() => {
        const section = document.querySelector('.congratulations-screen');
        if (section) {
          // Safari cannot smooth scroll, it will snap to position
          section.scrollIntoView({ behavior: 'smooth' });
        }
      }, 250);
    }
  }, [props.totalProgress]);

  return (
    <StyledPage>
      {props.onboardingData ? (
        <StyledOnboardingPageContentInner>
          <StyledTopArea data-intercom-target="onboarding-page-top-area">
            {' '}
            <StyledPageHeader>Onboarding</StyledPageHeader>
          </StyledTopArea>
          <OnboardingDismissedMessage />
          {!props.parsedData.onboardingDismissed && CanViewOnboarding() && (
            <div>
              <StyledProgressBar
                value={props.totalProgress}
                max="100"
                width={'100%'}
              />
              {props.totalProgress === 100 ? (
                <StyledProgressTitle>
                  Completed{' '}
                  <Icon type="formtools" size="tiny" name="success-outline" />
                </StyledProgressTitle>
              ) : (
                <StyledProgressTitle>Progress</StyledProgressTitle>
              )}

              {props.totalProgress === 100 && <OnboardingCompletedScreen />}
              <StyledCards
                data-intercom-target="onboarding-task-cards"
                sidebarContracted={sidebarContracted}
              >
                {props.onboardingData.map((card, index) => {
                  return (
                    <StyledGreenBorder
                      key={`section:${card.Title}`}
                      selected={index === selectedSectionIndex}
                    >
                      <StyledCard
                        selected={index === selectedSectionIndex}
                        onClick={() => handleCardClick(index)}
                        completed={card.Progress === 100}
                      >
                        <StyledCardBody completed={card.Progress === 100}>
                          <StyledProgressBar
                            value={card.Progress}
                            max="100"
                            height={'0.5rem'}
                          />
                        </StyledCardBody>
                        <StyledIconWrapper>
                          {card.Progress !== 100 ? (
                            <Icon
                              name={getCardIcon(card.Title)}
                              type="illustrations"
                              size="extra-large"
                            />
                          ) : (
                            <StyledCompletedIconsWrapper>
                              <StyledIcon
                                name={getCardIcon(card.Title)}
                                type="illustrations"
                                size="extra-large"
                                completed={card.Progress === 100}
                              />
                              <StyledIcon
                                position="absolute"
                                type="formtools"
                                size="medium-large"
                                name="success-outline-white"
                              />
                            </StyledCompletedIconsWrapper>
                          )}
                        </StyledIconWrapper>

                        <StyledCardBody completed={card.Progress === 100}>
                          <StyledCardTitle> {card.Title}</StyledCardTitle>
                        </StyledCardBody>
                      </StyledCard>
                    </StyledGreenBorder>
                  );
                })}
              </StyledCards>

              {props.onboardingData && (
                <OnboardingSection
                  className="onboarding-section"
                  startOpen={true}
                  key={props.onboardingData[selectedSectionIndex].Title}
                  tasks={props.onboardingData[selectedSectionIndex].Tasks}
                  showProgress={false}
                  title={props.onboardingData[selectedSectionIndex].Title}
                  data={props.parsedData}
                  parsedData={props.parsedData}
                />
              )}
              <StyledResetMessageBlock>
                <OnboardingSkipSectionButton
                  tasks={props.onboardingData[selectedSectionIndex].Tasks}
                  completed={
                    props.onboardingData[selectedSectionIndex].Progress === 100
                  }
                  parsedData={props.parsedData}
                />
              </StyledResetMessageBlock>
              <OnboardingSettings />
            </div>
          )}
        </StyledOnboardingPageContentInner>
      ) : (
        <LoadingPageIndicator />
      )}
    </StyledPage>
  );
}

OnboardingDashboard.propTypes = {
  onboardingData: PropTypes.array.isRequired,
  parsedData: PropTypes.object.isRequired,
  totalProgress: PropTypes.number.isRequired,
  loading: PropTypes.bool,
};

export default _.flowRight(
  WithConfirmationBox,
  withUser,
  WithOnboardingHandler
)(OnboardingDashboard);
