import React from 'react';
import { Container } from '../../DragAndDrop/Container';
import { CustomDragLayer } from '../../DragAndDrop/CustomDragLayer';

export default function EditQuestions({
  feedbackForm,
  activePage,
  loading,
  setLoading,
}) {
  return (
    <>
      <CustomDragLayer />
      {activePage &&
        !!feedbackForm?.Pages.nodes[activePage?.SortOrder]?.Questions?.nodes
          ?.length && (
          <Container
            questions={
              feedbackForm?.Pages.nodes[activePage?.SortOrder].Questions?.nodes
            }
            setLoading={setLoading}
            activePage={activePage}
            formID={feedbackForm?.ID}
            // disabled={}
            // removeQuestion={}
            // addQuestion={}
            // onQuestionEdit={}
            // updatePageValue={}
          />
        )}
    </>
  );
}
