import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADD_SIMULATION } from '../../queries/SimulationQueries';
import { Mutation } from '@apollo/client/react/components';
import RedirectWithPrevState from '../RedirectWithPrevState';
import withFlashMessaging from '../../HOCs/WithFlashMessaging';
import Button from './Button';
import SelectFromAssetsModal from '../modals/SelectFromAssetsModal';
import WithOnboardingHandler from '../../HOCs/WithOnboardingHandler';
import _ from 'lodash';
import tracker from '../../apps/core/src/helpers/tracker';

class CreateSimulationFromVideoButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createdSimulationID: null,
      assetSelectorModalVisible: false,
    };
  }

  render() {
    return (
      <Mutation
        update={(cache, data) => {
          cache.evict({ fieldName: 'readSimulations' });
        }}
        onError={(err) => {
          console.log(err);
          this.props.addFlashMessage(
            'There was an error creating the simulation',
            'error'
          );
        }}
        onCompleted={(data) => {
          this.props.updateProgress(
            'create_simulation',
            data.createSimulationFromVideoMedia.ID
          );
          tracker.track('simulation_created', {
            simulation_id: data.createSimulationFromVideoMedia.ID,
          });
          this.props.addFlashMessage('Simulation created', 'success');
          this.setState({
            createdSimulationID: data.createSimulationFromVideoMedia.ID,
          });
        }}
        mutation={ADD_SIMULATION}
        refetchQueries={['readSimulations']}
      >
        {(mutateCreateSimulation, { mutationData, loading: mutating }) => {
          if (this.state.createdSimulationID) {
            return (
              <RedirectWithPrevState
                to={'/simulations/' + this.state.createdSimulationID}
              />
            );
          }
          return (
            <>
              <Button
                data-intercom-target="choose-existing-video-button"
                iconResizeHeight={false}
                iconAllWhite={false}
                iconType="redesign"
                icon="assets"
                iconSize="small"
                type="material-white"
                className={this.props.className}
                to="/files"
                onClick={() =>
                  this.setState({ assetSelectorModalVisible: true })
                }
                loading={mutating}
              >
                Choose Existing Video
              </Button>
              <SelectFromAssetsModal
                visible={this.state.assetSelectorModalVisible}
                mediaTypes={['videos']}
                organisationID={this.props.userOrganisationID}
                hideModal={() => {
                  if (!mutating) {
                    this.setState({ assetSelectorModalVisible: false });
                  }
                }}
                onConfirm={(videoEdge) => {
                  mutateCreateSimulation({
                    variables: {
                      VideoID: videoEdge.node.ID,
                    },
                  });
                }}
                disabledVideoIDs={this.props.simulationVideoIDs}
                confirmText="Add Video"
                loading={mutating}
              />
            </>
          );
        }}
      </Mutation>
    );
  }
}

CreateSimulationFromVideoButton.propTypes = {
  addFlashMessage: PropTypes.func.isRequired,
  userOrganisationID: PropTypes.number.isRequired,
};

export default _.flowRight(
  WithOnboardingHandler,
  withFlashMessaging
)(CreateSimulationFromVideoButton);
