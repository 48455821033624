import { useReactiveVar } from '@apollo/client';
import {
  secondarySidebarContentVar,
  setSecondarySidebarContentVar,
  userModeVar,
} from '@base/ApolloReactiveVars';
import {
  SubContent,
  SubSidebarLink,
} from '@core/components/Sidebar/components/SubSidebar/SubSidebar.styled';
import { FC, useCallback, useMemo } from 'react';
import { Sublink } from '@core/components/Sidebar/Sidebar.types';
import { useCanViewUserSideVirtualHumans } from '@core/helpers/permissions.hooks';
import {
  CanViewAnalyticsPage,
  CanViewGroupsList,
  CanViewUsersList,
} from '@base/utility/Permissions';

/**
 * @type {FC<{
 * }>}
 */
export const SubSidebar = () => {
  const sidebarContent = useReactiveVar(secondarySidebarContentVar);
  const userMode = useReactiveVar(userModeVar);
  const canViewVHs = useCanViewUserSideVirtualHumans();

  const closeSubSidebar = useCallback((e) => {
    if (e.target.classList.contains('active')) {
      return;
    }
    setTimeout(() => setSecondarySidebarContentVar(null), 100);
  }, []);

  /**
   * @type {Sublink[]}
   */
  const links = useMemo(() => {
    if (userMode === 'user' && sidebarContent === 'my-profile') {
      return [
        { to: '/my-profile/badges', label: 'Badges' },
        { to: '/my-profile/simulations', label: 'Simulations' },
        canViewVHs && {
          to: '/my-profile/virtual-humans',
          label: 'Virtual Humans',
        },
      ];
    }

    if (
      userMode === 'admin' &&
      sidebarContent === 'analytics' &&
      CanViewAnalyticsPage()
    ) {
      return [
        { to: '/analytics-data/overall', label: 'Overall' },
        // { to: '/analytics-data/users', label: 'Users' },
        { to: '/analytics-data/simulations', label: 'Simulations' },
        { to: '/analytics-data/courses', label: 'Courses' },
        { to: '/analytics-data/virtual-human', label: 'Virtual Humans' },
        { to: '/analytics-data/forms', label: 'Feedback Forms' },
        { to: '/analytics-data/tags', label: 'Tags' },
      ];
    }

    if (
      userMode === 'admin' &&
      sidebarContent === 'users' &&
      (CanViewUsersList() || CanViewGroupsList())
    ) {
      return [
        CanViewUsersList() && { to: '/users', label: 'Users' },
        CanViewGroupsList() && { to: '/groups', label: 'Groups' },
      ];
    }

    return [];
  }, [sidebarContent, canViewVHs, userMode]);

  return (
    <SubContent $show={!!links.length}>
      {(links || []).map((link) => {
        if (!link || !link?.to) {
          return null;
        }
        const { to, label } = link;
        let key = label;
        if (typeof to === 'object' && to.pathname) {
          key = to.pathname;
        } else if (typeof to === 'string') {
          key = to;
        }
        return (
          <SubSidebarLink key={key} to={to} onClick={closeSubSidebar}>
            {label}
          </SubSidebarLink>
        );
      })}
    </SubContent>
  );
};

export default SubSidebar;
