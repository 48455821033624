import { useMutation } from '@apollo/client';
import { Button, Icon } from '@virtidev/toolbox';
import { produce } from 'immer';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { READ_FEEDBACK_FORM } from '../../../../../../../queries/FeedbackFormQueries';

import StyledModalWithProps from '../../../../../../../styled-components/StyledModal';
import useFlashMessage from '../../../FlashMessage';
import { CREATE_QUESTION } from './AddNewQuestionButton.query';
import {
  StyledIconAndLabel,
  StyledIconButton,
  StyledIconsWrapper,
  StyledSelectWrapper,
  StyledTypeLabel,
} from './AddNewQuestionButton.styled';

export default function AddNewQuestionButton({
  activePage,
  setLoading,
  feedbackForm,
}) {
  const [hoverType, setHoverType] = useState('');
  const [open, setOpen] = useState(false);

  const { addFlashMessage } = useFlashMessage();

  const [createQuestion] = useMutation(CREATE_QUESTION, {});

  const SortOrder = useMemo(() => {
    if (activePage && activePage?.Questions?.nodes) {
      return activePage.Questions.nodes.length + 1;
    }
    return 0;
  }, [activePage]);

  const handleAddQuestion = useCallback(
    (Type) => {
      setLoading(true);
      createQuestion({
        variables: {
          input: {
            Type,
            PageID: activePage.ID,
            SortOrder,
            Length: Type === 'likertscalev2' ? 7 : null,
            CharacterLimit: Type.includes('text') ? 48 : null,
          },
        },
        onCompleted: (data) => {
          setLoading(false);
          addFlashMessage('Question created!');
        },
        update: (cache, { data }) => {
          const cacheData = cache.readQuery({
            query: READ_FEEDBACK_FORM,
            variables: {
              ID: feedbackForm.ID,
            },
          });

          const newItem = data.createQuestion;
          const newData = produce(cacheData, (state) => {
            if (state?.readOneFeedbackForm) {
              return;
            }
            if (
              state.readOneFeedbackForm.Pages.nodes[activePage.SortOrder]
                .Questions.nodes
            ) {
              state.readOneFeedbackForm.Pages.nodes[
                activePage.SortOrder
              ].Questions.nodes = [
                ...state.readOneFeedbackForm.Pages.nodes[activePage.SortOrder]
                  .Questions.nodes,
                newItem,
              ];
            } else {
              state.readOneFeedbackForm.Pages.nodes[
                activePage.SortOrder
              ].Questions = { nodes: [newItem] };
            }
          });

          cache.writeQuery({
            query: READ_FEEDBACK_FORM,
            variables: {
              ID: feedbackForm.ID,
            },
            data: newData,
          });
        },
      });
    },
    [
      createQuestion,
      activePage,
      addFlashMessage,
      setLoading,
      SortOrder,
      feedbackForm?.ID,
    ]
  );

  const questionTypeData = useMemo(() => {
    return [
      {
        label: 'Short Answer',
        type: 'text',
        icon: 'singlequestion',
      },
      {
        label: 'Paragraph',
        type: 'textarea',
        icon: 'paragraph',
      },
      {
        label: 'Radio List',
        type: 'radiolist',
        icon: 'hotspot',
      },
      {
        label: 'Checkbox',
        type: 'checkbox',
        icon: 'checkbox',
      },
      {
        label: 'Linear Scale',
        type: 'likertscalev2',
        icon: 'likertscale',
      },
    ];
  }, []);

  return (
    <StyledSelectWrapper>
      <StyledModalWithProps
        title={
          hoverType !== ''
            ? `Add a ${hoverType} Question`
            : 'Select a question type:'
        }
        visible={open}
        hideModal={() => setOpen(false)}
      >
        <StyledIconsWrapper>
          {questionTypeData.map((item) => {
            return (
              <StyledIconAndLabel
                onMouseOut={() => setHoverType('')}
                onMouseOver={() => setHoverType(item.label)}
                key={item.type}
                onClick={() => {
                  setOpen(false);
                  handleAddQuestion(item.type);
                }}
              >
                <StyledIconButton>
                  <Icon icon={item.icon} color="#ccc" size="35px" />
                </StyledIconButton>
                <StyledTypeLabel>{item.label}</StyledTypeLabel>
              </StyledIconAndLabel>
            );
          })}
        </StyledIconsWrapper>
      </StyledModalWithProps>
      <br></br>
      <Button
        color="turquoise"
        title="Add new question"
        data-intercom-target="forms-add-question-button"
        onClick={() => setOpen(true)}
      >
        <Icon icon="plus" />
      </Button>
    </StyledSelectWrapper>
  );
}
