/**
 * @typedef {import("../../../apps/core/src/models/simulation.types").Simulation} Simulation
 * @typedef {import("../../../apps/core/src/models/simulation.types").Video} Video
 */

/**
 * @param {Simulation} simulation
 * @return {Video | null}
 */
export const getStartVideo = (simulation) => {
  const videos = simulation?.Videos?.nodes || [];

  if (simulation.StartVideoID === '0' || !simulation.StartVideoID) {
    return videos[0] || null;
  }

  const startVideo = videos.find(({ ID }) => ID === simulation.StartVideoID);

  return startVideo || null;
};

export default getStartVideo;
