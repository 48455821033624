import { gql } from '@apollo/client';

export const CREATE_NEW_ACCOUNT = gql`
  mutation createNewAccount(
    $OrganisationName: String!
    $Email: String!
    $Password: String!
    $FirstName: String!
    $Surname: String!
    $TrialLength: Int
    $OrganisationPartner: String
  ) {
    signupNewUser(
      OrganisationName: $OrganisationName
      Email: $Email
      Password: $Password
      FirstName: $FirstName
      Surname: $Surname
      SignupType: "web"
      TrialLength: $TrialLength
      OrganisationPartner: $OrganisationPartner
    ) {
      ID
      Name
      FirstName
      Surname
      Email
      UserType
      IntercomHash
      AvatarMedia {
        ID
        TusID
        URL
      }
      Organisations(limit: 1) {
        nodes {
          ID
          Name
          AccountID
          Trial
          TrialExpiryDate
          LogoMedia {
            ID
            TusID
            URL
          }
          VirtualHumanAPI
          CustomerStage
          Partner
          Medical
        }
      }

      # onboarding related data
      OnboardProgress
      SignupMethod
    }
  }
`;

export const CREATE_TOKEN_ACCOUNT = gql`
  mutation createTokenAccount(
    $Email: String!
    $Password: String
    $FirstName: String
    $Surname: String
    $tokenType: String
    $token: String
  ) {
    signupTokenUser(
      Email: $Email
      Password: $Password
      FirstName: $FirstName
      Surname: $Surname
      TokenType: $tokenType
      Token: $token
    ) {
      ID
      Name
      FirstName
      Surname
      Email
      UserType
      IntercomHash
      AvatarMedia {
        ID
        TusID
        URL
      }
      Organisations {
        nodes {
          ID
          Name
          AccountID
          Trial
          TrialExpiryDate
          LogoMedia {
            ID
            TusID
            URL
          }
          VirtualHumanAPI
          CustomerStage
          Partner
          Medical
        }
      }

      # onboarding related data
      OnboardProgress
      SignupMethod
    }
  }
`;

export const LOGIN = gql`
  mutation loginRegister($Email: String!, $Password: String!) {
    createVirtiToken(Email: $Email, Password: $Password) {
      ID
      Token
      PDToken
      Message
      Member {
        ID
      }
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation refreshToken {
    refreshVirtiToken {
      ID
      Token
      PDToken
      Member {
        ID
      }
    }
  }
`;

export const WELCOME_COURSE = gql`
  query welcomeCourse($OrganisationID: ID!) {
    readOneOrganisation(filter: { ID: { eq: $OrganisationID } }) {
      ID
      SignupType
    }
    welcomeCourse(OrganisationID: $OrganisationID) {
      ID
      Title
      Description
      Modules(limit: 1) {
        nodes {
          ID
          Units(limit: 1) {
            nodes {
              ID
              Simulation {
                ID
                Title
                ImageMediaID
                ImageMedia {
                  ID
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const COPY_CONTENT = gql`
  mutation copyContent($OrganisationID: ID!) {
    copyContent(OrganisationID: $OrganisationID, InterestIDs: []) {
      ID
    }
  }
`;

export const GET_VH_CONTENT = gql`
  query getVHContent($filter: VirtualHumanFilterFields) {
    readOneVirtualHuman(filter: $filter) {
      ID
      ShareToken
      OrganisationID
    }
  }
`;

export const GET_SIM_CONTENT = gql`
  query getSimContent($filter: SimulationFilterFields) {
    readOneSimulation(filter: $filter) {
      ID
      ShareToken
      OrganisationID
    }
  }
`;
