import styled, { css } from 'styled-components';

export const PillData = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.color.primary};
    border-radius: 60px;
    color: ${theme.color.contrast.primary};
    font-size: 0.875rem;
    padding: 0.4rem 1rem;
  `}
`;
