import { gql } from '@apollo/client';

export const READ_SIMULATIONS = gql`
  query readSimulationsList(
    $limit: Int!
    $offset: Int!
    $filters: SimulationFilterFields!
    $sort: SimulationSortFields
  ) {
    readSimulations(
      offset: $offset
      limit: $limit
      filter: $filters
      sort: $sort
    ) {
      pageInfo {
        totalCount
      }
      edges {
        node {
          ID
          ImageMedia {
            ID
            URL
            TusID
          }
          Author {
            ID
          }
          Editors {
            nodes {
              ID
            }
          }
          hasHeatmaps
          Title
          AdminTitle
          LastSaved
          LastEdited
          TotalUsers
          TotalViews
          TotalCompletions
          Quiz
          AverageScore
          TranscodingStatus
          Status
          Tags {
            nodes {
              ID
              Name
            }
          }
          FirstVideo: Videos(limit: 1) {
            nodes {
              ID
              PosterURL
            }
          }
          OrganisationGroups {
            nodes {
              ID
              Name
            }
          }
        }
      }
    }
  }
`;
