import styled, { css } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { rebrand } from '@core/templates/components/SplashPage/SplashPage.styled';

export const RegisterFormContainer = styled.div`
  ${({ theme }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.lg} ${rebrand.page.splash.square.md}
      ${theme.spacing.md};
    align-self: stretch;
    background: ${theme.color.contrast.base};
    color: ${theme.color.base};
    gap: ${theme.spacing.md};
    z-index: 1;

    @media screen and (min-width: 1200px) {
      flex: 1.4;
    }
  `}
`;

export const RegisterFormInner = styled.form.attrs({
  method: 'post',
})`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    gap: ${theme.spacing.md};
  `}
`;

export const RegisterFormLogo = styled.img`
  ${({ theme }) => css`
    display: none;
    width: 40%;
    min-width: 140px;
    max-width: 200px;
    align-self: flex-start;
    margin: ${theme.spacing.sm} auto 0
      calc(10% - ${rebrand.page.splash.square.md});

    @media screen and (min-width: 992px) {
      display: block;
    }
  `}
`;

export const RegisterFormTitle = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.xxl};
    text-align: center;
    margin-top: ${theme.spacing.md};
  `}
`;

export const Link = styled(RouterLink)`
  text-decoration: underline;
`;

export const PrivacyField = styled.div`
  ${({ theme }) => css`
    align-self: center;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: auto 1fr;
    grid-template-areas:
      'checkbox description'
      '. error';

    gap: 0 ${theme.spacing.md};
    margin-bottom: ${theme.spacing.lg};
    align-item: center;

    > .error {
      grid-area: error;
      padding-left: 0;
    }

    > .description {
      grid-area: description;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 0 0.3rem;
      cursor: pointer;

      > * {
        word-wrap: nowrap;
      }
    }

    @media screen and (min-width: 992px) {
      > .description {
        align-items: center;
      }
    }
  `}
`;

export const GoLoginMessage = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${theme.spacing.sm};
    padding: 10% ${theme.spacing.md};
  `}
`;
