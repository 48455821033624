import { gql } from '@apollo/client';
import { pseudoSimulationFragmentBase } from './SimulationQueries';
import { pseudoGroupFragment } from './GroupQueries';

export const pseudoMemberFragment = `
ID
Email
Name
UserType
FirstName
Surname
LoginCode
Created
Active
Disabled
DeleteRequestID
AvatarMediaID
AvatarMedia {
  ID
  TusID
  URL
}
SendWelcomeEmail
LastSeen
AverageScore
LearningGain
OnboardProgress
TotalTimeSpent
TotalViews
TotalCompletions
IntercomHash
Organisations {
  nodes {
    ID
    Name
    LogoMedia {
      ID
      TusID
      URL
    }
    AccountID
    DisableLeaderboard
    VirtualHumanAPI
    Trial
    TrialExpiryDate
    Archived
    Medical
  }
}
`;

export const pseudoMemberFragmentWithOrganisationGroupBasicData = `
  ${pseudoMemberFragment}
  OrganisationGroups(filter: { Organisation: { ID: { eq: $organisationID } } }) {
    edges {
      node {
        ID
        Name
      }
    }
  }
`;

export const READ_MEMBERS = gql`
  query readMembers($filter: String, $organisationID: ID, $limit: Int){
    readMembers(
      limit: $limit
      filter: {
        Organisations: { ID: { eq: $organisationID } }
      }
      Name: $filter
    ) {
      edges {
        node {
          ${pseudoMemberFragmentWithOrganisationGroupBasicData}
        }
      }
    }
  }
`;

export const READ_MEMBERS_CX = gql`
  query readMembers($filter: String, $limit: Int){
    readMembers(
      limit: $limit
      Name: $filter
    ) {
      edges {
        node {
          ${pseudoMemberFragment}
        }
      }
    }
  }
`;

export const READ_MEMBERS_SELECT = gql`
  query readSelectMembers($filter: String, $organisationID: ID, $limit: Int) {
    readMembers(
      limit: $limit
      filter: { Organisations: { ID: { eq: $organisationID } } }
      Name: $filter
    ) {
      edges {
        node {
          ID
          Name
        }
      }
    }
  }
`;

export const READ_MEMBERS_FOR_EXPORT = gql`
  query readMembers($filter: String, $organisationID: ID, $limit: Int) {
    readMembers(
      filter: { Organisations: { ID: { eq: $organisationID } } }
      Name: $filter
      limit: $limit
    ) {
      edges {
        node {
          ID
          Name
          Email
          LastSeen
          Disabled
          LoginCode
          UserType
          OrganisationGroups(
            filter: { OrganisationID: { eq: $organisationID } }
          ) {
            edges {
              node {
                ID
                Name
              }
            }
          }
        }
      }
    }
  }
`;

export const READ_MEMBER = gql`
query readOneMemberLegacy($ID: ID!) {
  readOneMember(filter: { ID: { eq: $ID } }) {
    ${pseudoMemberFragment}
  }
}
`;

export const READ_MEMBER_BASIC = gql`
  query readOneMemberBasicLegacy($ID: ID!) {
    readOneMember(filter: { ID: { eq: $ID } }) {
      ID
      Email
      Name
      FirstName
      Surname
      UserType
      DeleteRequestID
      AvatarMedia {
        ID
        TusID
        URL
      }
      OnboardProgress
      IntercomHash
      SignupMethod
      Organisations(limit: 1000, sort: { Name: ASC, ID: ASC }) {
        nodes {
          ID
          Name
          Trial
          TrialExpiryDate
          LogoMedia {
            ID
            TusID
          }
          AccountID
          DisableLeaderboard
          VirtualHumanAPI
          CustomerStage
          Partner
          Archived
          Medical
        }
      }
    }
  }
`;

export const READ_MEMBER_WITH_EDITABLE_SIMS = gql`
query readMemberWithEditableSims($ID: ID!, $limit: Int!, $offset: Int!) {
  readOneMember(filter: { ID: { eq: $ID } }) {
    ${pseudoMemberFragment}
    EditableSimulations(limit: $limit, offset: $offset){
      pageInfo {
        totalCount
        hasNextPage
        hasPreviousPage
      }
      edges{
        node{
          ${pseudoSimulationFragmentBase}
        }
      }
    }
  }
}
`;

export const READ_MEMBER_WITH_BASIC_GROUP_DATA = gql`
query readOneMemberWithGroupData($ID: ID!, $organisationID: ID) {
  readOneMember(filter: { ID: { eq: $ID } }) {
    ${pseudoMemberFragmentWithOrganisationGroupBasicData}
  }
}
`;

export const READ_MEMBER_WITH_FULL_DATA = gql`
  query readOneMemberWithFullData($ID: ID!, $organisationID: ID) {
    readOneMember(filter: { ID: { eq: $ID } }) {
      ${pseudoMemberFragment}
      OrganisationGroups(filter: {Organisation: {ID: {eq: $organisationID}}} ) {
        edges {
          node {
            ${pseudoGroupFragment}
          }
        }
      }
    }
  }
`;

export const READ_ONBOARD_PROGRESS = gql`
  query readOnboardProgress($ID: ID!) {
    readOneMember(filter: { ID: { eq: $ID } }) {
      ID
      OnboardProgress
    }
  }
`;

export const EDIT_ONBOARD_PROGRESS = gql`
  mutation updateMember($Input: UpdateMemberInput!) {
    updateMember(input: $Input) {
      ID
      OnboardProgress
    }
  }
`;

export const EDIT_MEMBER = gql`
  mutation updateMember($Input: UpdateMemberInput!) {
    updateMember(input: $Input) {
      ${pseudoMemberFragment}
    }
  }
`;

export const EDIT_PROFILE = gql`
  mutation updateMember($Input: UpdateMemberInput!) {
    updateMember(input: $Input) {
      ID
      Email
      Name
      UserType
      FirstName
      Surname
      LoginCode
      Created
      Active
      Disabled
      DeleteRequestID
      AvatarMediaID
      AvatarMedia {
        ID
        TusID
        URL
      }
      SendWelcomeEmail
      LastSeen
      AverageScore
      LearningGain
      OnboardProgress
      TotalTimeSpent
      TotalViews
      TotalCompletions
      IntercomHash
      Organisations(limit: 1000, sort: { Name: ASC, ID: ASC }) {
        nodes {
          ID
          Name
          LogoMedia {
            ID
            TusID
            URL
          }
          AccountID
          DisableLeaderboard
          VirtualHumanAPI
          Trial
          TrialExpiryDate
          CustomerStage
          Partner
          Archived
          Medical
        }
      }
    }
  }
`;

export const EDIT_MEMBER_GROUPS = gql`
  mutation updateMember($Input: UpdateMemberInput!, $organisationID: ID!) {
    updateMember(input: $Input) {
      ${pseudoMemberFragment}
      OrganisationGroups(filter: { Organisation: { ID: { eq: $organisationID } } }) {
        edges {
          node {
            ID
            Name
          }
        }
      }
    }
  }
`;

export const ADD_MEMBER = gql`
  mutation createMember($Input: CreateMemberInput!) {
    createMember(input: $Input) {
      ${pseudoMemberFragment}
    }
  }
`;

export const SIGN_UP_USER = gql`
  mutation signupUser(
    $Email: String!
    $Password: String!
    $InviteCode: String!
    $FirstName: String!
    $Surname: String!
  ) {
    signupUser(
      Email: $Email
      Password: $Password
      InviteCode: $InviteCode
      FirstName: $FirstName
      Surname: $Surname
    ) {
      ID
      Email
      FirstName
      Surname
      Name
      UserType
      DeleteRequestID
      AvatarMedia {
        URL
      }
      OnboardProgress
      IntercomHash
      SignupMethod
      Organisations(limit: 1000, sort: { Name: ASC, ID: ASC }) {
        nodes {
          ID
          Name
          Trial
          TrialExpiryDate
          LogoMedia {
            ID
            TusID
          }
          AccountID
          DisableLeaderboard
          VirtualHumanAPI
          CustomerStage
          Partner
          Archived
          Medical
        }
      }
    }
  }
`;

export const DELETE_MEMBER = gql`
  mutation deleteMember($ID: ID!) {
    updateMember(input: { ID: $ID, Disabled: true }) {
      ID
      Disabled
    }
  }
`;

export const ADD_MEMBER_TO_GROUP = gql`
  mutation addMemberToGroup($GroupID: ID!, $MemberID: ID!) {
    addMemberToGroup(GroupID: $GroupID, MemberID: $MemberID) {
      ${pseudoMemberFragment}
    }
  }
`;

export const ADD_MEMBER_TO_COURSE = gql`
  mutation addMemberToCourse($CourseID: ID!, $MemberID: ID!) {
    addMemberToCourse(CourseID: $CourseID, MemberID: $MemberID) {
      ${pseudoMemberFragment}
    }
  }
`;

export const REMOVE_MEMBER_FROM_GROUP = gql`
  mutation removeMemberFromGroup($GroupID: ID!, $MemberID: ID!) {
    removeMemberFromGroup(GroupID: $GroupID, MemberID: $MemberID) {
      ID
    }
  }
`;

export const RESEND_LOGIN_CODES = gql`
  mutation resendLoginCodes($UserIDs: [ID]!, $OrganisationID: ID!) {
    resendLoginCodes(UserIDs: $UserIDs, OrganisationID: $OrganisationID)
  }
`;

export const MEMBER_ANALYTICS = gql`
  query userAnalytics($UserID: ID!) {
    userAnalytics(UserID: $UserID) {
      TotalCompletions
      TotalTimeWatched
      AverageReactionTime
      AverageScoreFirst
      AverageScoreLast
      AverageScore
      DailyTotalViewsDays
      DailyTotalViewsViews
      DailyTotalViewsScores
      MostWatchedTitles
      MostWatchedViews
      SimulationScores {
        ID
        Created
        FirstScore
        LatestScore
        BestScore
        Simulation {
          ID
          Title
          Quiz
        }
        AnalyticsSubmissions(
          filter: { Finished: { eq: true } }
          sort: { Created: ASC }
        ) {
          nodes {
            ID
            Score
          }
        }
      }
      CourseScores {
        ID
        Created
        Progress
        AverageScore
        Course {
          ID
          Title
          StartDate
          DueDate
        }
      }
      StrongestSimulations {
        ID
        Created
        FirstScore
        LatestScore
        BestScore
        Simulation {
          ID
          Title
        }
      }
      WeakestSimulations {
        ID
        Created
        FirstScore
        LatestScore
        BestScore
        Simulation {
          ID
          Title
        }
      }
    }
  }
`;

export const LOGIN = gql`
  mutation createVirtiToken($Email: String!, $Password: String!) {
    createVirtiToken(Email: $Email, Password: $Password) {
      Token
      PDToken
      Message
      Member {
        ID
      }
    }
  }
`;

export const LOGOUT_DEVICE = gql`
  mutation {
    logout
  }
`;

export const LOGOUT_ALL = gql`
  mutation {
    invalidateTokens
  }
`;

export const SAVE_AREAS_OF_INTEREST = gql`
mutation updateMemberInterests($UserID: ID!, $CategoryIDs: [ID]!) {
  updateMemberInterests(MemberID: $UserID, CategoryIDs: $CategoryIDs){
    ${pseudoMemberFragment}
  }
}
`;

export const ACTIVATE_ACCOUNT = gql`
  mutation activateAccount(
    $Email: String!
    $Password: String!
    $FirstName: String!
    $Surname: String!
  ) {
    setPassword(
      Email: $Email
      Password: $Password
      FirstName: $FirstName
      Surname: $Surname
    ) {
      ID
    }
  }
`;
