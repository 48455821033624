import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withUser from '../HOCs/WithUser';
import StyledPage, {
  StyledPageContentInner,
  StyledTopArea,
} from '../components/StyledPage';
import SimulationNewForm from '../forms/SimulationNewForm';
import '../../node_modules/react-dropzone-component/styles/filepicker.css';
import '../../node_modules/dropzone/dist/min/dropzone.min.css';
import RedirectWithPrevState from '../components/RedirectWithPrevState';
import StyledPageHeader from '../styled-components/StyledPageHeader';
import Button from '../components/buttons/Button';

class SimulationPage extends Component {
  render() {
    if (!this.props.userID) {
      return <RedirectWithPrevState to="/login" />;
    }
    return (
      <StyledPage
        pageKey="new-simulation"
        goBackToLinkText="all simulations"
        goBackToLink="/simulations"
      >
        <StyledPageContentInner>
          <StyledTopArea>
            <StyledPageHeader>Create New Simulation</StyledPageHeader>
          </StyledTopArea>
          <SimulationNewForm
            userOrganisationID={this.props.userOrganisationID}
          />
        </StyledPageContentInner>
      </StyledPage>
    );
  }
}

SimulationPage.propTypes = {
  userID: PropTypes.number,
  match: PropTypes.object.isRequired,
  userOrganisationID: PropTypes.number.isRequired,
};

export default withUser(SimulationPage);
