/**
 * @param {string} email
 * @returns {boolean}
 */
export const isBusinessEmail = (email) => {
  if (email && email.endsWith('@virti.com')) {
    return true;
  }
  var re =
    /^([\w.+-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|yahoo|icloud|outlook)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,4}|\d{1,3})(\]?)$/;
  return re.test(email);
};
