import React from 'react';
import styled, { css } from 'styled-components';

const StyledContent360Indicator = styled.div`
  ${({ theme, borderRadius, content360 }) => css`
    position: absolute;
    left: 0;
    bottom: 0;
    background: ${content360 ? theme.color.primary : theme.color.secondary};
    font-weight: 500;
    width: 50px;
    height: 26px;
    z-index: 1;
    border-radius: ${borderRadius ? borderRadius : '0 6px 0 6px'};
    color: ${theme.color.contrast.primary};
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

const Content360Indicator = ({ borderRadius, content360 }) => (
  <StyledContent360Indicator
    borderRadius={borderRadius}
    content360={content360}
  >
    {content360 ? '360' : '2D'}
  </StyledContent360Indicator>
);

export default Content360Indicator;
