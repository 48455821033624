import { gql } from '@apollo/client';

export const GET_VH_TOKEN = gql`
  query getVHToken($id: ID) {
    readOneVirtualHuman(filter: { ID: { eq: $id } }) {
      ID
      ShareToken
    }
  }
`;
