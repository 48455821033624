import { gql } from '@apollo/client';

export const TOTARA_REGISTRATION = gql`
  mutation signupTotaraUser(
    $FirstName: String!
    $Surname: String!
    $Email: String!
    $ExternalID: String!
    $Password: String!
  ) {
    signupTotaraUser(
      FirstName: $FirstName
      Surname: $Surname
      Email: $Email
      ExternalID: $ExternalID
      Password: $Password
    ) {
      ID
      Email
      Name
      UserType
      DeleteRequestID
      AvatarMedia {
        TusID
        URL
      }
      OnboardProgress
      IntercomHash
      Organisations(limit: 1000, sort: { Name: ASC, ID: ASC }) {
        ID
        Name
        LogoURL
        AccountID
        DisableLeaderboard
        VirtualHumanAPI
        CustomerStage
        Partner
        Medical
      }
    }
  }
`;

export const CHECK_EMAIL = gql`
  query checkEmail($Email: String!) {
    checkEmail(Email: $Email)
  }
`;
