import React, { useCallback, useMemo, useState } from 'react';
import ScoreOverview from '@core/components/Analytics/VirtualHuman/ScoreOverview/ScoreOverview';
import { Loading, Modal } from '@virtidev/toolbox';
import useGetVHData from '@base/utility/VHHooks/useGetVHData';
import { useQuery } from '@apollo/client';
import { READ_VIRTUAL_HUMAN_FOR_SCORE_OVERVIEW } from '@core/components/Analytics/VirtualHuman/ScoreOverviewAnalyticsAreaModal/ScoreOverviewAnalyticsAreaModal.query';

const ScoreOverviewAnalyticsAreaModal = ({
  analyticsSubmission,
  SSID,
  vhId,
  onHideModal,
}) => {
  const { data: assessment, loading: loadingAssessment } = useGetVHData(
    `assessments/${analyticsSubmission.PDAssessmentID}`
  );
  const vhDBVH = useMemo(() => {
    return assessment?.data?.assessment?.patient ?? null;
  }, [assessment]);
  const { data: SSVH } = useQuery(READ_VIRTUAL_HUMAN_FOR_SCORE_OVERVIEW, {
    variables: {
      ID: SSID,
    },
  });
  const {
    data: objectiveGroupsData,
    loading: objectiveGroupsLoading,
    error: objectiveGroupsError,
  } = useGetVHData(`objective-groups?vhId=${vhId}`);
  const {
    data: coachingSessionReport,
    loading: coachingSessionReportLoading,
    error: coachingSessionReportError,
  } = useGetVHData(
    `assessments/${analyticsSubmission.PDAssessmentID}/coaching-session-report`
  );

  const shownHintObjectiveIds = useMemo(() => {
    return (
      assessment?.assessmentLogs?.reduce((carry, log) => {
        if (log.type === 'hint' && log.objectiveId) {
          carry.push(log.objectiveId);
        }
        return carry;
      }, []) ?? []
    );
  }, [assessment]);

  const {
    data: objectivesData,
    loading: objectivesLoading,
    error: objectivesError,
  } = useGetVHData(`objectives?vhId=${vhId}`);

  return (
    <Modal show={true} onHide={onHideModal} maxWidth="800px">
      {(loadingAssessment ||
        objectiveGroupsLoading ||
        objectivesLoading ||
        coachingSessionReportLoading) && <Loading />}
      {objectiveGroupsData?.data?.data?.objectiveGroups &&
      objectivesData?.data?.data?.objectives &&
      assessment?.data?.assessment &&
      vhDBVH &&
      SSVH?.readOneVirtualHuman ? (
        <ScoreOverview
          objectiveGroups={objectiveGroupsData?.data?.data?.objectiveGroups}
          allObjectives={objectivesData?.data?.data?.objectives}
          timeSpent={analyticsSubmission.TimeSpent}
          diagnosisSuccess={analyticsSubmission.PDDiagnosisScore === 100}
          vhDBVH={vhDBVH}
          ssVH={SSVH?.readOneVirtualHuman}
          hitObjectivesData={assessment?.data.assessment?.hitObjectivesData}
          pointsScored={assessment?.data.assessment?.objectivePointsAchieved}
          pointsPossible={assessment?.data.assessment?.objectivePointsPossible}
          assessmentId={analyticsSubmission.PDAssessmentID}
          hideScores={SSVH?.readOneVirtualHuman?.HideScoresFromUsers}
          shownHintObjectiveIds={shownHintObjectiveIds}
          sessionReport={coachingSessionReport?.data?.data}
        />
      ) : (
        <div>Couldn't retrieve required data</div>
      )}
    </Modal>
  );
};

export default ScoreOverviewAnalyticsAreaModal;
