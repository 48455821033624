import { Datetime, Tooltip } from '@virtidev/toolbox';
import usePreviewableFeature from '../../../LaunchDarkly/hooks/usePreviewableFeature';
import { Alert, Chevron, Pill, PreviewPill } from './HeaderPill.styled';

export const HeaderPill = ({ lastEdited, author, showPreview }) => {
  const previewable =
    usePreviewableFeature('video-branching-iteration-2') && showPreview;

  return (
    <Tooltip
      type="menu"
      icon={false}
      placement="bottom-end"
      iconContent={
        <Pill>
          <div>
            Last Edited: <Datetime datetime={lastEdited} long noTimeDisplay />
          </div>
          <Chevron />
          {previewable && <Alert />}
        </Pill>
      }
    >
      <div>
        Last Edited:{' '}
        <Datetime datetime={lastEdited} long noTimeDisplay emptyDisplay="-" />
      </div>
      <div>Created By: {author}</div>
      {previewable && (
        <PreviewPill feature="video-branching-iteration-2">
          Preview new Simulation editor
        </PreviewPill>
      )}
    </Tooltip>
  );
};

export default HeaderPill;
