import getStartVideo from '../pages/SimEditorPage/helpers/getStartVideo';

/**
 * @typedef {import("../apps/core/src/models/simulation.types").Simulation} Simulation
 * @typedef {import("../apps/core/src/models/simulation.types").Video} Video
 */

/**
 *
 * @param {Simulation} simulation
 * @param {string} [videoID]
 * @returns {Video | null}
 */
export const GetCurrentVideoFromSimulation = (simulation, videoID) => {
  if (!simulation) {
    return null;
  }
  if (videoID) {
    const video = simulation.Videos.nodes.find((video) => video.ID === videoID);

    if (video) {
      return video;
    }
  }
  // fallback to start video
  const startVideo = getStartVideo(simulation);

  if (startVideo) {
    return startVideo;
  }

  return simulation.Videos.nodes[0] || null;
};

export const GetTranscodingText = function (transcoding) {
  if (!transcoding)
    return "We're converting your video file so that you can add interactions";
  return {
    NoUpload: 'No upload',
    Transcoding:
      "We're converting your video file so that you can add interactions",
    Failed:
      'Oops, something went wrong. Please try uploading your video again.',
    Error: 'Oops, something went wrong. Please try uploading your video again.',
    Cancelled: 'Transcoding was cancelled',
  }[transcoding];
};

export const GetUnitCountFromCourse = function (course) {
  const units = course.Modules.nodes.map((mod) => mod.Units.edges);
  return units.length;
};

export const GetPageType = function () {
  if (window.location.pathname.includes('analytics')) return 'analytics';
  if (window.location.pathname.includes('overview-pages'))
    return 'overview-pages';
  if (window.location.pathname.includes('interactions')) return 'interactions';
  if (window.location.pathname.includes('preview')) return 'preview';
  if (window.location.pathname.includes('users')) return 'users';
  if (window.location.pathname.includes('sessions')) return 'sessions';

  const currentURLArr = window.location.pathname.split('/');
  if (currentURLArr[currentURLArr.length - 1] === 'videos') return 'videos';
  return 'details';
};
