import styled, { css } from 'styled-components';

export const StyledSuccessIcon = styled.div`
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border-radius: 100px;
  width: 30px;
  height: 30px;
  border-radius: 60px;
  background-color: var(--pd-positive-color);
  ${(props) =>
    props.disabled &&
    css`
      background-color: var(--pd-disabled-button-bg-color);
    `}
  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 16px;
    height: 13px;
  }
`;
