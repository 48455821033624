import React, { FC, useCallback, useEffect, useState } from 'react';
import * as Styled from './ScoreOverview.styled';
import CoachingSessionReportGenerator from '@core/components/Analytics/VirtualHuman/ScoreOverview/components/CoachingSessionReportGenerator/CoachingSessionReportGenerator';
import { ToDecimalPlace } from '@base/utility/NumberFormatting';
import { GetFormattedTime } from '@base/utility/TimeFormatting';
import PillData from '@core/components/Analytics/VirtualHuman/ScoreOverview/components/PillData/PillData';
import SuccessIcon from '@core/components/Analytics/VirtualHuman/ScoreOverview/components/SuccessIcon/SuccessIcon';
import ObjectiveGroupResult from '@core/components/Analytics/VirtualHuman/ScoreOverview/components/ObjectiveGroupResult/ObjectiveGroupResult';
import CoachingSessionReport from '@core/components/Analytics/VirtualHuman/ScoreOverview/components/CoachingSessionReport/CoachingSessionReport';
import FailIcon from '@core/components/Analytics/VirtualHuman/ScoreOverview/components/FailIcon/FailIcon';

/**
 * @typedef {import('../../../../models/vh-server/vh-server-objective.types').VHServerObjectiveGroup} VHServerObjectiveGroup
 * @typedef {import('../../../../models/vh-server/vh-server-objective.types').VHServerObjective} VHServerObjective
 * @typedef {import('../../../../models/vh-server/vh-server-virtualhuman.types').VHServerVirtualHuman} VHServerVirtualHuman
 * @typedef {import('../../../../models/vh-server/vh-server-coaching-session-report.types').CoachingSessionReport} CoachingSessionReport
 * @typedef {import('../../../../models/vh-server/vh-server-coaching-session-report.types').CoachingSessionReportObjective} CoachingSessionReportObjective
 * @typedef {import('@core/models/virtualhuman.types').VirtualHuman} VirtualHuman
 * @typedef {import('@core/models/vh-server/vh-server-assessment.types').HitObjectives} HitObjectives
 *
 */

const TimeIcon = ({ success }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30.291"
    height="30.293"
    viewBox="0 0 30.291 30.293"
  >
    <path
      fill={success ? 'var(--pd-positive-color)' : 'var(--pd-red)'}
      d="M111.521,524.864a15.147,15.147,0,1,0,15.144,15.147A15.141,15.141,0,0,0,111.521,524.864Zm1.722,17.789a.756.756,0,0,1-.3.6l-5.029,3.654a.751.751,0,0,1-1.041-.162l-.509-.7a.743.743,0,0,1,.162-1.041l4.365-3.177v-9.059a.744.744,0,0,1,.744-.741h.867a.744.744,0,0,1,.744.741Z"
      transform="translate(-96.374 -524.864)"
    />
  </svg>
);

/**
 * @type { FC<{
 *    objectiveGroups: VHServerObjectiveGroup[];
 *    allObjectives: VHServerObjective[];
 *    timeSpent: number;
 *    diagnosisSuccess: boolean;
 *    vhDBVH:VHServerVirtualHuman;
 *    ssVH: VirtualHuman;
 *    hitObjectivesData: HitObjectives;
 *    pointsScored: number;
 *    pointsPossible: number;
 *    assessmentId: number;
 *    hideScores: boolean;
 *    easyMode: boolean;
 *    shownHintObjectiveIds: number[];
 *    suffix?: React.ReactNode;
 *    sessionReport?: CoachingSessionReport & {objectiveReports: CoachingSessionReportObjective[];}
 * }>}
 */
const ScoreOverview = ({
  objectiveGroups,
  allObjectives,
  timeSpent,
  diagnosisSuccess,
  vhDBVH,
  ssVH,
  hitObjectivesData,
  pointsScored,
  pointsPossible,
  assessmentId,
  hideScores,
  easyMode,
  shownHintObjectiveIds,
  suffix,
  sessionReport,
}) => {
  const [report, setReport] = useState(
    /** @type {CoachingSessionReport & {objectiveReports: CoachingSessionReportObjective[]} | null} */ (
      sessionReport ?? null
    )
  );

  useEffect(() => {
    setReport(sessionReport ?? null);
  }, [sessionReport]);

  const handleReportGenerated = useCallback((newReport) => {
    setReport(newReport);
  }, []);
  const timeSuccess =
    timeSpent < ssVH.TimeLimit * 60 * 1000 || ssVH.TimeLimit === 0;

  return (
    <Styled.ScoreOverviewWrapper>
      {/* time and diagnosis results */}
      <Styled.SummaryText>Performance Report</Styled.SummaryText>
      <Styled.MainSummary
        $vhType={ssVH.Type}
        $pointsPossible={pointsPossible}
        $disableDiagnosis={vhDBVH.disableDiagnosis}
      >
        <Styled.BoxResult $success={timeSuccess}>
          <Styled.BoxResultIconText>
            <TimeIcon success={timeSuccess} />
            <div>{GetFormattedTime(timeSpent)}</div>
          </Styled.BoxResultIconText>
        </Styled.BoxResult>
        {ssVH.Type === 'medical' && !vhDBVH.disableDiagnosis && (
          <Styled.BoxResult $success={diagnosisSuccess}>
            <Styled.BoxResultIconText>
              {diagnosisSuccess && <SuccessIcon />}
              {!diagnosisSuccess && <FailIcon />}
              <div>
                {diagnosisSuccess ? 'Diagnosed' : 'Incorrect Diagnosis'}
              </div>
            </Styled.BoxResultIconText>
          </Styled.BoxResult>
        )}
        {pointsPossible > 0 && (
          <Styled.BoxResult $success={true}>
            <Styled.PointsText>Points:</Styled.PointsText>
            <Styled.PillDataSection>
              <PillData>
                {/* only use convo points in non-medical */}
                {pointsScored}/{pointsPossible}
              </PillData>
              {!hideScores && (
                <PillData>
                  {ToDecimalPlace((pointsScored / pointsPossible) * 100, 2)}%
                </PillData>
              )}
            </Styled.PillDataSection>
          </Styled.BoxResult>
        )}
      </Styled.MainSummary>
      {!report && (
        <CoachingSessionReportGenerator
          onReportGenerated={handleReportGenerated}
          assessmentId={assessmentId}
        />
      )}
      {report && <CoachingSessionReport report={report} />}
      {/* conversation objectives results */}
      {objectiveGroups.map((objectiveGroup) => (
        <ObjectiveGroupResult
          key={objectiveGroup.id}
          heading={objectiveGroup.name}
          allObjectives={allObjectives}
          hitObjectives={hitObjectivesData?.objectives ?? []}
          feedbackText={objectiveGroup.feedbackText ?? ''}
          easyMode={easyMode}
          shownHintObjectiveIds={shownHintObjectiveIds}
          groupId={objectiveGroup.id}
        />
      ))}
      {suffix}
    </Styled.ScoreOverviewWrapper>
  );
};

ScoreOverview.propTypes = {};

export default ScoreOverview;
