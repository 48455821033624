import { useMutation } from '@apollo/client';
import { useEffectOnlyOnce } from '@base/utility/CustomHooks';
import { CREATE_PUBLIC_TOKEN } from '@core/helpers/usePublicToken.query';
import { useState } from 'react';

export const usePublicToken = ({ skip } = {}) => {
  const [login, { data, loading, error, called }] = useMutation(
    CREATE_PUBLIC_TOKEN,
    {}
  );
  const [loaded, setLoaded] = useState(false);

  useEffectOnlyOnce(() => {
    (async () => {
      if (skip) {
        return;
      }
      const { data: loginData } = await login();
      let tokens = 0;

      if (loginData?.createPublicToken?.Token) {
        tokens = tokens + 1;
        localStorage.setItem(
          'public_token',
          JSON.stringify(loginData.createPublicToken.Token)
        );
      }
      if (loginData?.createPublicToken?.PDToken) {
        tokens = tokens + 1;
        localStorage.setItem(
          'public_ai_token',
          JSON.stringify(loginData.createPublicToken.PDToken)
        );
      }

      if (tokens === 2) {
        setLoaded(true);
      }
    })();
  });

  return { data, loading: loading || !loaded, error, called };
};

export default usePublicToken;
