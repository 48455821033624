import { gql } from '@apollo/client';

export const UPDATE_ORGANISATION = gql`
  mutation updateOrganisation($Input: UpdateOrganisationInput!) {
    updateOrganisation(input: $Input) {
      ID
      TUSEndpoint
      Partner
      VirtualHumanAPI
      Licenses
      VirtualHumanFreeformLimit
      VirtualHumanBranchingLimit
    }
  }
`;

export const READ_ORGANISATION = gql`
  query readOrganisation($ID: ID!) {
    readOneOrganisation(filter: { ID: { eq: $ID } }) {
      ID
      TUSEndpoint
      Partner
      VirtualHumanAPI
      Licenses
      VirtualHumanFreeformLimit
      VirtualHumanBranchingLimit
    }
  }
`;
