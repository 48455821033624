import React from 'react';
import PropTypes from 'prop-types';
import { GetPageType } from '../../../../../../utility/SimulationHelpers';
import { TabNav } from './SimulationTabNav.styled';
import Tab from './Tab/Tab';
import { Icon } from '@virtidev/toolbox';

const SimulationTabNavCopy = (props) => {
  const pageType = GetPageType();

  return (
    <TabNav>
      <Tab
        data-testid="sim-details-tab"
        isActive={pageType === 'details'}
        to={`/simulations/${props.simulationID}${
          props.selectedVideoID ? '/videos/' + props.selectedVideoID : ''
        }`}
      >
        Sim Details
      </Tab>
      <Tab
        className="edit-simulation-overview-pages-tab"
        data-testid="sim-interactions-tab"
        data-intercom-target="sim-interactions-tab"
        disabled={props.transcodingStatus !== 'Success'}
        isActive={pageType === 'overview-pages'}
        to={`/simulations/${props.simulationID}${
          props.selectedVideoID ? '/videos/' + props.selectedVideoID : ''
        }/overview-pages`}
      >
        Overview pages
      </Tab>
      <Tab
        className="edit-simulation-interactions-tab"
        data-testid="sim-interactions-tab"
        data-intercom-target="sim-interactions-tab"
        disabled={props.transcodingStatus !== 'Success'}
        isActive={pageType === 'interactions'}
        to={{
          pathname: `/simulations/${props.simulationID}${
            props.selectedVideoID ? '/videos/' + props.selectedVideoID : ''
          }/interactions`,
          search: `simID=${props.simulationID}`,
        }}
      >
        Editor
      </Tab>
      <Tab
        data-testid="sim-analytics-tab"
        disabled={props.transcodingStatus !== 'Success'}
        to={`/analytics-data/simulations/${props.simulationID}`}
      >
        Analytics <Icon icon="expand" />
      </Tab>
    </TabNav>
  );
};

SimulationTabNavCopy.propTypes = {
  transcodingStatus: PropTypes.string.isRequired,
  simulationID: PropTypes.string.isRequired,
  selectedVideoID: PropTypes.string,
};

export default SimulationTabNavCopy;
