import { Button, Icon } from '@virtidev/toolbox';
import styled from 'styled-components';

export const StyledResource = styled.div`
  height: 60px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  text-align: left;
  background: var(--secondary-color);
  border: 2px solid;
  border-radius: 11px;
  margin: 0.5rem 0rem;
  color: #fff;
  font-size: 0.8rem;
  overflow: hidden;
  width: 20rem;
  max-width: 100%;
  min-width: 5rem;
`;

export const MockResourceContainer = styled.div``;

export const MockResource = styled.div`
  width: 100%;
  padding: 0px;
  display: flex;
  flex-direction: row;
  gap: 3rem;
`;

export const StyledDownloadTag = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;
  padding: 16px;

  &:hover {
    background: var(--secondary-color-light, #66d9ca);
    opacity: 0.7;
  }
`;

export const ResourceTag = styled(StyledDownloadTag)`
  flex: 1;
  width: 50px;
`;

export const ResourceTitle = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const DeleteButton = styled(Button)`
  &:hover {
    background: var(--secondary-color-light, #66d9ca);
    opacity: 0.7;
  }
`;
