import React from 'react';
import * as Styled from './SuccessIcon.styled';

export const CheckMark = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.568"
      height="13.109"
      viewBox="0 0 15.568 13.109"
      className={props.className}
    >
      <path
        fill="#FFF"
        d="M298.5,86.067l-8.829,11.294a.924.924,0,0,1-.711.348.872.872,0,0,1-.559-.2l-.136-.136-.015-.015a.209.209,0,0,1-.06-.045L283.4,92.6a.908.908,0,0,1,0-1.285.89.89,0,0,1,1.27,0l4.2,4.127,8.21-10.493a.9.9,0,0,1,1.421,1.119Z"
        transform="translate(-283.134 -84.599)"
      />
    </svg>
  );
};

const SuccessIcon = (props) => {
  return (
    <Styled.StyledSuccessIcon disabled={props.disabled}>
      <CheckMark />
    </Styled.StyledSuccessIcon>
  );
};

export default SuccessIcon;
