import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { GetFormattedTime } from '../utility/TimeFormatting';
import SimLogEventBox from './SimLogEventBox';
import PropTypes from 'prop-types';
import { getCircle, getGreyCircle } from '../utility/SimLogCircles';
import {
  HelpContent,
  HelpLabel,
  HelpTooltip,
} from '../apps/core/src/components/Tooltip/HelpTooltip';
import {
  Datetime,
  PaginationControl,
  usePaginationUrl,
} from '@virtidev/toolbox';
import { usePageScroll } from '../apps/core/src/components/Page';

const PAGE_SIZE = 100;

const getEventName = (type, finished) => {
  switch (type) {
    case 'END_SIM':
      return finished ? 'Finished Simulation' : 'Exited Simulation';
    default:
      return type
        .replace('_', ' ')
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
        .join(' ');
  }
};

const HelpLegend = styled(HelpContent)`
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 1rem;
`;

const DeletedUserText = styled.div`
  display: inline-block;
  color: #aaa;
`;

const StyledSimLog = styled.div`
  padding: 0 1.8rem;
  text-align: center;
  margin-left: 350px;
  @media (max-width: 1100px) {
    margin-left: 0;
  }
`;

const StyledInfoColumn = styled.div`
  border-right: 1px solid var(--primary-border-color);

  text-align: left;
  padding: 2rem 0rem;
  position: fixed;
  width: 350px;
  margin-left: -350px;
  @media (max-width: 1100px) {
    margin-left: 0;
    width: auto;
    position: static;
    border-right: 0px;
    border-bottom: 1px solid var(--primary-border-color);
  }
`;

const StyledInfoDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding-top: 2rem;
  h2 {
    font-size: 0.8rem;
    color: var(--primary-font-color-light);
    margin: 0.8rem 0rem;
  }
  h3 {
    font-size: 0.8rem;
    color: var(--primary-font-color-light);
    height: 2rem;
  }
`;

const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2.5rem;
  padding-right: 2rem;

  @media (max-width: 1100px) {
    padding-right: 0;
  }
`;

const StyledUserName = styled.h1`
  font-size: 1.5rem;
  color: var(--primary-font-color);
`;
const StyledLogColumn = styled.div`
  display: grid;
  place-items: start center;
  padding-top: 2rem;
`;

const StyledLogEventAndTime = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  place-items: center;
`;
const StyledTimeStamp = styled.div`
  width: 5rem;
  color: var(--primary-font-color-light);
`;

const StyledScore = styled.div`
  ${({ theme, alignAsAnswer }) => css`
    width: 20rem;
    color: #fff;
    font-size: 1.2rem;
    display: grid;
    place-items: center;
    margin: 1rem 5rem 1rem 0rem;
    padding: 1.5rem 0rem 1.5rem 0rem;
    text-align: left;
    background: ${theme.color.primary};
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
    opacity: 1;
    margin: ${alignAsAnswer ? '1rem 12rem 1rem 0rem' : '1rem 2rem 1rem 10rem'};

    @media (max-width: 1250px) {
      width: 15rem;
      margin: ${alignAsAnswer ? '1rem 6rem 1rem 0rem' : '1rem 2rem 1rem 5rem'};
    }
  `}
`;

export default function SimulationLog(props) {
  const { scrollTop } = usePageScroll();
  const { controlProps } = usePaginationUrl({
    key: 'logPage',
    onChange: scrollTop,
    pageSize: PAGE_SIZE,
  });
  const answer = (props.data?.AnalyticsEvents?.nodes || []).find(
    (event) => event.Type === 'ANSWER'
  );

  // this parses events mainly to split ANSWER into individual events: 'Question' and 'Answer',
  // and adds things like media data only when necessary so event boxes can be conditionally rendered based on props
  const parsedAnalyticsEvents = (
    props.data?.AnalyticsEvents?.nodes || []
  ).reduce((carry, event) => {
    if (event.Type === 'ANSWER') {
      const questionEvent = {
        type: event.Event.Type,
        title: event.Event.Description || event.Event.Title,
        timeSpent: event.Event.TimeSpent,
        ID: parseInt(event.Event.ID) + event.TimeSpent,
      };
      const answerEvent = {
        type: 'Answer',
        quiz: event.Event.QuestionType !== 'no_scoring',
        multipleChoice: event.Event.QuestionType === 'multiple_choice',
        answers:
          event.Event.QuestionType === 'multiple_choice'
            ? event.Options.nodes
            : [event.Option],
        options: event.Event.Options.nodes,
        timeSpent: event.TimeSpent,
        ID: parseInt(event.ID),
      };
      carry.push(questionEvent);
      carry.push(answerEvent);
    } else {
      const interactionEvent = {
        type: event.Event.Type || getEventName(event.Type, event.Finished),
        title: event.Event.Description || event.Event.Title,
        timeSpent: event.Event.TimeSpent || event.TimeSpent,
        imageURL: event.Event.ThumbnailURL,
        videoURL: event.Event.VideoDownloadLowURL,
        audioURL: event.Event.AudioURL,
        action: event.Event.Action,
        ID: parseInt(event.ID),
        videoTitle: event.Video.Title,
      };
      carry.push(interactionEvent);
    }
    return carry;
  }, []);

  const lastEdited = useMemo(() => {
    const end = props.data?.AnalyticsEvents?.nodes.find(
      (event) => event.Type === 'END_SIM' && event.Finished === true
    );
    if (!end) {
      return null;
    }
    return end.Created;
  }, [props.data]);

  return (
    <StyledSimLog>
      {props.data && (
        <StyledInfoColumn>
          <UserContainer>
            <StyledUserName>
              {props.userMode === 'admin' ? (
                <>
                  User:{' '}
                  {props.data.Member.Name || (
                    <DeletedUserText>(Deleted)</DeletedUserText>
                  )}
                </>
              ) : (
                <>{props.simTitle}</>
              )}
            </StyledUserName>

            <HelpTooltip placement="bottom-start">
              <HelpContent>
                In {props.userMode === 'admin' ? `a Learner's` : 'this'}{' '}
                Interactive Video Log, you can see every interaction and event
                that has occurred{' '}
                {props.userMode === 'admin' ? `for the Learner` : ''} during{' '}
                {props.userMode === 'admin' ? `their` : 'your'} session.
              </HelpContent>
              <HelpLabel>Legend for Answers:</HelpLabel>
              <HelpLegend>
                <div>Answer selected:</div>
                <div>{getGreyCircle(true, true)}</div>
                <div>Answer not selected:</div>
                <div>{getGreyCircle(false, true)}</div>
                <div>Correct answer:</div>
                <div>{getCircle(true, true)}</div>
                <div>Incorrect answer:</div>
                <div>{getCircle(true, false)}</div>
              </HelpLegend>
            </HelpTooltip>
          </UserContainer>
          <StyledInfoDetails>
            <div>
              <h2>Started:</h2>
              <h2>Finished:</h2>
              {!!answer && <br></br>}
              {props.isQuiz && (
                <>
                  <h2>Completed:</h2>
                  <h2>Score:</h2>
                </>
              )}
              {!!answer && <h2>Points:</h2>}
            </div>
            <div>
              <h2>
                <Datetime datetime={props.data.Created} long />
              </h2>
              <h2>
                <Datetime datetime={lastEdited} long emptyDisplay="N/A" />
              </h2>
              {!!answer && <br></br>}
              {props.isQuiz && (
                <>
                  <h2>{props.data.Finished ? 'Yes' : 'No'}</h2>
                  <h2>{props.data.Score}%</h2>
                </>
              )}
              {!!answer && <h2>{props.data.Points}</h2>}
            </div>
          </StyledInfoDetails>
        </StyledInfoColumn>
      )}

      <StyledLogColumn>
        {parsedAnalyticsEvents &&
          parsedAnalyticsEvents.map((event, index) => {
            if (event.type) {
              if (event.type === 'Answer' && event.options.length === 0) {
                return null; // incomplete data is returned from unity when sim is exited early, this is a quick fix
              }
              return (
                <StyledLogEventAndTime key={event.ID}>
                  <SimLogEventBox event={event} />
                  <StyledTimeStamp>
                    {(event.timeSpent > 0 &&
                      GetFormattedTime(event.timeSpent)) ||
                      ''}
                  </StyledTimeStamp>
                </StyledLogEventAndTime>
              );
            } else {
              return null;
            }
          })}
        {props.data && props.isQuiz && props.data.Score > 0 && (
          <StyledScore>
            <label>Final Score: {props.data.Score}%</label>
          </StyledScore>
        )}
        <PaginationControl
          {...controlProps}
          total={props.data?.AnalyticsEvents?.pageInfo?.totalCount}
        />
      </StyledLogColumn>
    </StyledSimLog>
  );
}

SimulationLog.propTypes = {
  simTitle: PropTypes.string,
  isQuiz: PropTypes.bool.isRequired,
  userMode: PropTypes.oneOf(['user', 'admin']).isRequired,
  data: PropTypes.shape({
    AnalyticsEvents: PropTypes.shape({
      nodes: PropTypes.array,
    }),
  }),
};
