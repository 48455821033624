import { useCallback, useEffect, useMemo, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { useRouterQuery } from '../../../../helpers/useRouterQuery';
import {
  StyledVHIframe,
  StyledVHIframeWrapper,
} from './VirtualHumanEmbed.styled';
import { useApolloClient } from '@apollo/client';
import { useFeature } from '../../../LaunchDarkly';

export const VirtualHumanEmbed = ({
  onComplete,
  virtualHumanId,
  isPreviewPlayer,
  sharetoken,
  publicPlayer,
  ...props
}) => {
  const query = useRouterQuery();
  const [complete, setComplete] = useState(false);
  const [started, setStarted] = useState(false);

  const playerQuery = useMemo(() => {
    const clone = new URLSearchParams(query.toString());

    // don't pass in the stage param
    clone.delete('stage');
    clone.set('isPreviewPlayer', isPreviewPlayer ? 'true' : 'false');

    if (sharetoken) {
      clone.set('sharetoken', sharetoken);
    }
    if (publicPlayer) {
      clone.set('publicPlayer', publicPlayer ? 'true' : 'false');
    }

    return clone;
  }, [query, isPreviewPlayer, sharetoken, publicPlayer]);

  // NOTE: THIS HANDLING OF THE PROMPT WHEN NAVIGATING AWAY NEEDS TO BE MOVED TO SOMEWHERE LIKE PDWebPlayerRoot WHEN MOVED OUT OF THE IFRAME
  // SO THAT LEAVING THE SESSION FUNCTIONALITY IS RETAINED
  const handleComplete = useCallback(
    (nextUnitPath) => {
      setComplete(true);
      onComplete(nextUnitPath?.detail?.nextUnitPath ?? undefined);
    },
    [onComplete]
  );

  const client = useApolloClient();
  const clearSubmissionsCache = useCallback(() => {
    if (!client) {
      return;
    }
    // so it refetches on the sessions page
    client.cache.evict({
      fieldName: 'readAnalyticsSubmissions',
    });
  }, [client]);

  useEffect(() => {
    window.document.addEventListener(
      'onVHSessionComplete',
      handleComplete,
      false
    );
    return () => {
      window.document.removeEventListener(
        'onVHSessionComplete',
        handleComplete,
        false
      );
    };
  }, [handleComplete]);

  const handleStart = useCallback(() => {
    setStarted(true);
  }, []);

  useEffect(() => {
    window.document.addEventListener('onVHSessionStart', handleStart, false);
    clearSubmissionsCache();
    return () => {
      setComplete(false);
      window.document.removeEventListener(
        'onVHSessionStart',
        handleStart,
        false
      );
    };
  }, [handleStart, clearSubmissionsCache]);

  const vhBigUiFeature = useFeature('vh-big-ui');

  const vhIframe = useMemo(() => {
    return (
      <StyledVHIframe
        {...props}
        id="vh-web-player-iframe"
        title="VH Web Player"
        src={`/embed/virtual-human-player/${virtualHumanId}?${playerQuery.toString()}`}
      />
    );
  }, [props, playerQuery, virtualHumanId]);

  return (
    <>
      <Prompt
        when={!complete && started && !isPreviewPlayer}
        message="Virtual Human session is in progress, are you sure you want to leave?"
      />
      {vhBigUiFeature && !publicPlayer ? (
        <StyledVHIframeWrapper>{vhIframe}</StyledVHIframeWrapper>
      ) : (
        vhIframe
      )}
    </>
  );
};

export default VirtualHumanEmbed;
