import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import LoadingPageIndicator from '../components/LoadingPageIndicator';
import StyledPage, {
  BackBar,
  StyledPageContentInner,
} from '../components/StyledPage';

import { READ_ANALYTICS_SUBMISSION } from '../queries/SimulationQueries';

import withUser from '../HOCs/WithUser';

import SimulationLog from './SimulationLog';
import { usePaginationUrl } from '@virtidev/toolbox';
import { withLoggedInAccess } from '../apps/core/src/helpers/permissions';
import _ from 'lodash';

const PAGE_SIZE = 100;

const MySimulationSessionDetailPage = (props) => {
  const { pageQuery, resetPage } = usePaginationUrl({
    key: 'logPage',
    pageSize: PAGE_SIZE,
  });
  const { data, loading } = useQuery(READ_ANALYTICS_SUBMISSION, {
    variables: {
      ID: props.match.params.sessionID,
      ...pageQuery,
    },
  });

  useEffect(() => () => resetPage(), [resetPage]);

  return (
    <div>
      {loading && <LoadingPageIndicator />}

      {!loading && data && (
        <StyledPage hasBackbar={true}>
          <BackBar
            fallbackText="Simulation Logs"
            fallbackLink="/my-profile/simulations"
          />
          <StyledPageContentInner>
            <SimulationLog
              data={data.readOneAnalyticsSubmission}
              isQuiz={!!data.readOneAnalyticsSubmission?.Simulation?.Quiz}
              userMode="user"
              simTitle={data.readOneAnalyticsSubmission?.Simulation?.Title}
            />
          </StyledPageContentInner>
        </StyledPage>
      )}
    </div>
  );
};

MySimulationSessionDetailPage.propTypes = {
  userID: PropTypes.number,
  match: PropTypes.object.isRequired,
  userOrganisationID: PropTypes.number.isRequired,
};

export default _.flowRight(
  withLoggedInAccess,
  withUser
)(MySimulationSessionDetailPage);
