// we use this check to e.g. not show intercom and to switch which token is being used

import { matchPath } from 'react-router-dom';

// since (as of now) we can rely on no navigation back to the main app from here
export const isPublicSimPlayerPage = (location) => {
  // embed root section
  if (!!matchPath(location.pathname, '/embed/my-simulations')) {
    return true;
  }

  const query = new URLSearchParams(location.search);
  if (query.get('publicPlayer') === 'true') {
    return true;
  }

  return false;
};
